export const GLOBAL_FIELD_KEY = "global";
export const OLD_FETCH_ARRIVED_HACK_CONST = 0x7887;

export const ASYNC_STORAGE_KEYS = Object.freeze({
  SELECTED_LANG_ID_STORAGE_KEY: "SELECTED_LANG_ID_STORAGE_KEY_x1_",
  AUTH_DATA_STORAGE_KEY: "AUTH_DATA_STORAGE_KEY_x1_",
  SETTINGS_STORAGE_KEY: "SETTINGS_STORAGE_KEY_x1_",
});

export const DEFAULT_COUNTRY_CODE = "SRB";
export const DEFAULT_LANGUAGE_CODE = "sr-RS";
export const DUMMY_LANGUAGE_DEFINITION = {
  version: 0,
  code: DEFAULT_LANGUAGE_CODE,
  value: {},
};

export const COUNTRIES = Object.freeze({
  OTHER: "OTHER",
  BOSNIA: "BOSNIA",
  NORTH_MACEDONIA: "NORTH_MACEDONIA",
  MONTENEGRO: "MONTENEGRO",
  CROATIA: "CROATIA",
  SERBIA: "SERBIA",
  SLOVENIA: "SLOVENIA",
});

export const USER_TYPES = Object.freeze({
  DRIVER: "Driver",
  GUEST: "Guest",
});

export const GENDERS = Object.freeze({
  MALE: "GENDER_MALE",
  FEMALE: "GENDER_FEMALE",
});

export const LOGIN_TYPES = Object.freeze({
  UNKNOWN: 0,
  MOBILE_NUMBER: 1,
  EMAIL: 2,
  FACEBOOK: 3,
  GOOGLE: 4,
});

export const APPLICATION_STATES = Object.freeze({
  LOGGED_OUT: "LOGGED_OUT",
  DRIVER_LOGGED_IN_NO_PROFILE_DATA: "DRIVER_LOGGED_IN_NO_PROFILE_DATA",
  DRIVER_LOGGED_IN_NO_SELECTED_VEHICLE: "DRIVER_LOGGED_IN_NO_SELECTED_VEHICLE",
  DRIVER_LOGGED_IN_FILLED_OUT_EVERYTHING:
    "DRIVER_LOGGED_IN_FILLED_OUT_EVERYTHING",
});

export const TYPICAL_VIN_LENGTH = 17;
export const DEFAULT_IMAGE_ICON_SIZE = 22;
export const FILE_TYPES = Object.freeze({
  IMAGE: 0,
  OTHER: 2,
});

export const DAYS = Object.freeze({
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
});

export const DAY_LABELS = Object.freeze({
  [DAYS.MONDAY]: "DAYS_MONDAY",
  [DAYS.TUESDAY]: "DAYS_TUESDAY",
  [DAYS.WEDNESDAY]: "DAYS_WEDNESDAY",
  [DAYS.THURSDAY]: "DAYS_THURSDAY",
  [DAYS.FRIDAY]: "DAYS_FRIDAY",
  [DAYS.SATURDAY]: "DAYS_SATURDAY",
  [DAYS.SUNDAY]: "DAYS_SUNDAY",
});

export const MONTHS = Object.freeze({
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
});

export const MONTHS_SHORT_LABELS = Object.freeze({
  [MONTHS.JANUARY]: "MONTHS_SHORT_JANUARY",
  [MONTHS.FEBRUARY]: "MONTHS_SHORT_FEBRUARY",
  [MONTHS.MARCH]: "MONTHS_SHORT_MARCH",
  [MONTHS.APRIL]: "MONTHS_SHORT_APRIL",
  [MONTHS.MAY]: "MONTHS_SHORT_MAY",
  [MONTHS.JUNE]: "MONTHS_SHORT_JUNE",
  [MONTHS.JULY]: "MONTHS_SHORT_JULY",
  [MONTHS.AUGUST]: "MONTHS_SHORT_AUGUST",
  [MONTHS.SEPTEMBER]: "MONTHS_SHORT_SEPTEMBER",
  [MONTHS.OCTOBER]: "MONTHS_SHORT_OCTOBER",
  [MONTHS.NOVEMBER]: "MONTHS_SHORT_NOVEMBER",
  [MONTHS.DECEMBER]: "MONTHS_SHORT_DECEMBER",
});

export const DEFAULT_PARKING = "Novi Sad";

export const ACCENTS_MAP = {
  a: "à|â|ä|À|Â|Ä",
  e: "é|è|ê|ë|É|È|Ê|Ë",
  i: "ï|î|Ï|Î",
  o: "ô|ö|Ô|Ö",
  u: "ù|û|ü|Ù|Û|Ü",
  c: "ç|Ç|č|ć|Č|Ć",
  z: "ž|Ž",
  s: "š|Š",
  dj: "đ|Đ",
  y: "ÿ|Ÿ",
  ae: "æ|Æ",
  oe: "œ|Œ",
  ss: "ß|ẞ",
};
