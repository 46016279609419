import { IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC } from "react";
import { ServiceTypesArray } from "reducers/serviceReducer";
import { BService } from "data/types";

const BusinessPricelist: FC<{
  bServiceTypes: ServiceTypesArray[];
  serviceTypesFromRedux: ServiceTypesArray[];
  toggleSectionProp: (sectionId: number) => void;
  toggleBody: () => void;
  open: boolean;
  expandedSectionsProp: any;
  bServiceArray: BService[];
  cenovnikSectionRef: any;
}> = ({
  bServiceTypes,
  toggleSectionProp,
  expandedSectionsProp,
  bServiceArray,
  cenovnikSectionRef,
  toggleBody,
  open,
}) => {
  function organizeLevel3Objects(
    userObjects: ServiceTypesArray[]
  ): { header: ServiceTypesArray; items: ServiceTypesArray[] }[] {
    const sections: {
      header: ServiceTypesArray;
      items: ServiceTypesArray[];
    }[] = [];

    const level1Objects = userObjects.filter((obj) => obj.level === 1);
    level1Objects.forEach((level1) => {
      const level2Objects = userObjects.filter(
        (obj) => obj.service_ID === level1.id && obj.level === 2
      );

      const level3Objects: any[] = [];
      level2Objects.forEach((level2) => {
        const vrednosti = userObjects.filter(
          (obj) => obj.service_ID === level2.id && obj.level === 3
        );
        level3Objects.push(...vrednosti);
      });

      sections.push({
        header: level1,
        items: level3Objects,
      });
    });

    return sections;
  }

  function getBServiceObjectsFromServiceTypes(id: number) {
    return bServiceArray.find((x) => x.service_ID === id);
  }

  const sections = organizeLevel3Objects(bServiceTypes);
  return (
    <div className="listingSection__wrap_column">
      <div>
        <h2
          ref={cenovnikSectionRef}
          className="text-2xl font-semibold"
          onClick={() => toggleBody()}
        >
          <span className=" block font-bold p-2 pl-3 rounded-lg   mt-2 flex items-center justify-between ">
            Cenovnik
            <IconButton>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </span>
        </h2>
        {open && (
          <div className="w-20 mt-5 ml-3 border-b border-neutral-300 "></div>
        )}
      </div>
      {open && (
        <div className="block flex-row gap-4 text-md text-neutral-700  ">
          {sections.map((section) => {
            // Filter the items to include only those with price, priceFrom, or priceTo
            const validItems = section.items.filter((item) => {
              const service = getBServiceObjectsFromServiceTypes(item.id);
              return service?.price || service?.priceFrom || service?.priceTo;
            });

            if (validItems.length === 0) {
              return null;
            }

            return (
              <div key={section.header.id} className="my-6">
                <h4
                  className="font-semibold"
                  onClick={() => toggleSectionProp(section.header.id)}
                >
                  <span className="block font-bold p-2 pl-3 rounded-lg mt-2 flex items-center">
                    {section.header.name}{" "}
                    <IconButton>
                      {expandedSectionsProp[section.header.id] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </span>
                </h4>
                {expandedSectionsProp[section.header.id] && (
                  <ul>
                    <div className="text-sm text-neutral-6000 rounded-lg  -mb-4">
                      {validItems.map((item, index) => (
                        <li key={item.id}>
                          <div
                            className={`p-4 ${
                              index % 2 === 0 ? "bg-neutral-100" : ""
                            } flex justify-between items-center space-x-4 rounded-lg`}
                          >
                            <span>{item.name}</span>
                            <span>
                              {getBServiceObjectsFromServiceTypes(item.id)
                                ?.priceFrom &&
                              getBServiceObjectsFromServiceTypes(item.id)
                                ?.priceTo
                                ? `${
                                    getBServiceObjectsFromServiceTypes(item.id)
                                      ?.priceFrom
                                  },00 din - ${
                                    getBServiceObjectsFromServiceTypes(item.id)
                                      ?.priceTo
                                  },00 din`
                                : getBServiceObjectsFromServiceTypes(item.id)
                                    ?.price
                                ? `${
                                    getBServiceObjectsFromServiceTypes(item.id)
                                      ?.price
                                  },00 din.`
                                : "0,00 din."}
                            </span>
                          </div>
                        </li>
                      ))}
                    </div>
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BusinessPricelist;
