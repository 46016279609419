import { useEffect, useState } from "react";
import { Location } from "react-router-dom";

interface ScrollPositions {
  [key: string]: number;
}
interface UseScrollPositionProps {
  location: Location; // Expecting 'location' property in props
}

const useScrollPosition = ({ location }: UseScrollPositionProps) => {
  const [scrollPositions, setScrollPositions] = useState<ScrollPositions>({});

  const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };
  useEffect(() => {
    const savedScrollPosition = parseInt(
      sessionStorage.getItem(location.pathname) || "0"
    );
    const handleScroll = debounce(() => {
      const currentScroll = window.scrollY;
      setScrollPositions((prev) => ({
        ...prev,
        [location.pathname]: currentScroll,
      }));
      if (currentScroll !== 0) {
        sessionStorage.setItem(location.pathname, currentScroll.toString());
      }
    }, 50);
    window.scrollTo(0, savedScrollPosition);
    window.addEventListener("scroll", handleScroll,{passive: true});
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);
  return scrollPositions;
};

export default useScrollPosition;
