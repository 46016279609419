import { IconButton } from "@mui/material";
import CommentListing from "components/CommentListing";
import { Rating } from "data/types";
import { FC } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BusinessComments: FC<{
  ratingData: any;
  recenzijeRef: any;
  toggleBodyRecenzije: () => void;
  openRecenzije: boolean;
}> = ({ ratingData, recenzijeRef, toggleBodyRecenzije, openRecenzije }) => {
  return (
    <div className="listingSection__wrap_column">
      <h2
        ref={recenzijeRef}
        className="text-2xl font-semibold"
        onClick={() => toggleBodyRecenzije()}
      >
        <span className=" block font-bold p-2 pl-3 rounded-lg   mt-2 flex items-center justify-between ">
          Recenzije
          <IconButton>
            {openRecenzije ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </span>
      </h2>
      {openRecenzije && (
        <div className="w-20 ml-3 border-b border-neutral-300 "></div>
      )}
      {openRecenzije && (
        <div className="divide-y ml-3 divide-neutral-100 ">
          {ratingData.map((item: Rating) => (
            <CommentListing key={item.id} rating={item} className="py-6" />
          ))}
        </div>
      )}
    </div>
  );
};

export default BusinessComments;
