import { Business } from "data/types";
import { FC } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const BusinessContactInformation: FC<{
  data: Business;
  handleAddressRefClick: () => void;
}> = ({ data, handleAddressRefClick }) => {
  const websiteUrl = data?.website?.startsWith("http")
    ? data.website
    : `https://${data?.website}`;
  return (
    <div className="listingSection__wrap_column">
      <div>
        <h2 className="text-2xl font-semibold">Kontakt podaci</h2>
      </div>
      <div className="w-20 ml-3 border-b border-neutral-300 "></div>

      <div className="grid text-sm   my-0 grid-cols-1  px-1 space-x-3">
        <div className="grid flex-row space-y-4 cols-span-1">
          {data?.contactPhone && (
            <div className="flex  items-end">
              <LocalPhoneIcon className="mr-2" />
              <span className="">
                <a href={`tel:${data?.contactPhone}`}>{data?.contactPhone}</a>
              </span>
            </div>
          )}
          {data?.contactEmail && (
            <div className="flex  items-end">
              <EmailIcon className="mr-2" />
              <a
                href={`mailto:${data?.contactEmail}`}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                <span className="">{data?.contactEmail}</span>
              </a>
            </div>
          )}
          {data?.website && (
            <div className="flex  items-end">
              <LanguageIcon className="mr-2" />
              <a
                href={websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                {" "}
                <span className="block mt-2">
                  <span className="">{websiteUrl}</span>
                </span>
              </a>
            </div>
          )}
          {data?.address && (
            <div
              onClick={handleAddressRefClick}
              className="flex  items-end cursor-pointer"
            >
              <LocationOnIcon className="mr-2" />
              <span className="text-wrap">{`${data?.address}, ${data?.city}`}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessContactInformation;
