import { downloadAppClickedAction } from "actions/SmsLinkActions";
import { openURL } from "./GeneralUtils";
import AppConfig from "config/AppConfig";
const ONE_DAY = 24 * 60 * 60 * 1000;

export const downloadAppClick = async (googlePlayClicked: boolean) => {
  let smsLinkParams;
  let timeSpent;
  let requestData = {};
  const livingTime = new Date();
  const localStorageObject = localStorage.getItem("smsLinkParams");
  if (localStorageObject) {
    smsLinkParams = JSON.parse(localStorageObject);
    timeSpent = livingTime.getTime() - new Date(smsLinkParams?.time).getTime();
    if (timeSpent && timeSpent <= ONE_DAY) {
      requestData = {
        ObjectId: smsLinkParams.objectId,
        SmsType: smsLinkParams.smsType,
        IsGoogle: googlePlayClicked ? true : false,
      };
      await downloadAppClickedAction(requestData);
    }
  }
  openURL(AppConfig.googlePlayUrl);
};
