import { DAYS } from "./AppConstants";

export const BUSINESS_SCHEDULE_STEP_COUNT = 4;
export const DEFAULT_BUSINESS_TIME_SCALE = 30;

export const getBusinessWorkingDaysAndHours = ({
  workHoursMonStart,
  workHoursMonEnd,
  workHoursTueStart,
  workHoursTueEnd,
  workHoursWedStart,
  workHoursWedEnd,
  workHoursThuStart,
  workHoursThuEnd,
  workHoursFriStart,
  workHoursFriEnd,
  workHoursSatStart,
  workHoursSatEnd,
  workHoursSunStart,
  workHoursSunEnd,
}) => {
  return {
    [DAYS.MONDAY]: { startTime: workHoursMonStart, endTime: workHoursMonEnd },
    [DAYS.TUESDAY]: { startTime: workHoursTueStart, endTime: workHoursTueEnd },
    [DAYS.WEDNESDAY]: {
      startTime: workHoursWedStart,
      endTime: workHoursWedEnd,
    },
    [DAYS.THURSDAY]: { startTime: workHoursThuStart, endTime: workHoursThuEnd },
    [DAYS.FRIDAY]: { startTime: workHoursFriStart, endTime: workHoursFriEnd },
    [DAYS.SATURDAY]: { startTime: workHoursSatStart, endTime: workHoursSatEnd },
    [DAYS.SUNDAY]: { startTime: workHoursSunStart, endTime: workHoursSunEnd },
  };
};

export const hasWorkDayTimesData = (workDaysMap) => {
  const mondayWorkHours = workDaysMap[DAYS.MONDAY];
  return !!mondayWorkHours.startTime;
};

export const isBusinessOpenOnDay = (workHours) =>
  !!workHours.startTime && !!workHours.endTime;

export const isBusinessOpenNow = (workHours) => {
  const now = new Date();
  const startTimeSplit = workHours.startTime.split(":");
  const endTimeSplit = workHours.endTime.split(":");
  const startTime = new Date().setHours(
    Number(startTimeSplit[0]),
    Number(startTimeSplit[1]),
    0
  );
  const endTime = new Date().setHours(
    Number(endTimeSplit[0]),
    Number(endTimeSplit[1]),
    0
  );
  const startTimeDate = new Date(startTime);
  const endTimeDate = new Date(endTime);
  return startTimeDate <= now && now <= endTimeDate;
};
