import { FC } from "react";
import GallerySlider from "components/GallerySlider";
import { Business } from "data/types";
import StartRating from "components/StartRating";
import { Link } from "react-router-dom";
import EServiceBookFetchService from "../services/Endpoints/EserviceBook/EServiceBookFetchService";
import defaultProfile from "../../src/shared/Logo/default-profile.png";
interface BusinessProfileItemProps {
  data: Business;
  serviceTypes?: string[];
}
const BusinessProfileItem: FC<BusinessProfileItemProps> = ({ data, serviceTypes }) => {
  const renderSliderGallery = () => {
    const profilePictures = data?.profileImages ? [...data.profileImages] : [];

    const index = profilePictures.findIndex((item) => item.isLogo);
    if (index !== -1) {
      const [logoImage] = profilePictures.splice(index, 1);
      profilePictures.unshift(logoImage);
    }

    const imageURLS =
      profilePictures &&
      profilePictures.map((x) => EServiceBookFetchService.imageUrl(x.url));

    return (
      <div className="relative flex-shrink-0 w-full md:w-52 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={
            !!imageURLS.length
              ? imageURLS.map((x: any) => x.uri)
              : [defaultProfile]
          }
          uniqueID={`StayCardH_${data.id}`}
          href={`/profil-servisa/${data.id}/${data.slug}`}
        />
      </div>
    );
  };
  const renderContent = () => {
    return (
      <div className="flex-grow p-3  sm:p-5 flex flex-col">
        <div className="flex items-center space-x-2">
          <h2 className="text-lg mb-2 font-medium capitalize">
            <span className="line-clamp-1">{data.name}</span>
          </h2>
        </div>
        <div className="flex justify-between  items-end mb-4">
          <span className="text-sm ">{`${data.address}, ${data.city} `}</span>
          <span className="text-sm ">{`${(
            Math.ceil(data.distance) / 1000
          ).toFixed(1)} km`}</span>
        </div>
        {!!data.ratingCount && (
          <StartRating
            blueBackground={true}
            reviewCount={data.ratingCount}
            point={data.ratingAvg}
          />
        )}
        <div className="grid grid-cols-3 mt-2 gap-2">
          {serviceTypes &&
            serviceTypes.map((item) => (
              <div key={item} className="flex items-center ">
                <span className="text-xs text-neutral-500">{item}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white border border-neutral-100 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform `}
      data-nc-id="StayCardH"
    >
      <Link
        to={`/profil-servisa/${data.id}/${data.slug}`}
        className="absolute inset-0 "
      ></Link>
      <div className="grid grid-cols-1  md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default BusinessProfileItem;
