import { StarIcon } from "@heroicons/react/24/solid";
import { FC, useEffect, useState } from "react";

export interface FiveStartIconForRateProps {
  className?: string;
  iconClass?: string;
  ratingAvg?: number;
}

const FiveStartIconForRate: FC<FiveStartIconForRateProps> = ({
  className = "",
  iconClass = "w-4 h-4",
  ratingAvg = 5,
}) => {
  const [point, setPoint] = useState(Number(ratingAvg.toFixed()));

  useEffect(() => {
    setPoint(Number(ratingAvg.toFixed()));
  }, [ratingAvg]);
  return (
    <div
      className={`nc-FiveStartIconForRate flex items-center text-neutral-300 ${className}`}
      data-nc-id="FiveStartIconForRate"
    >
      {[1, 2, 3, 4, 5].map((item) => {
        return (
          <StarIcon
            key={item}
            className={`${point >= item ? "text-yellow-500" : ""} ${iconClass}`}
            style={{ pointerEvents: "none" }} // Disable pointer events to prevent hover
          />
        );
      })}
    </div>
  );
};

export default FiveStartIconForRate;
