import EServiceBookFetchService from "../EserviceBook/EServiceBookFetchService";
import { AUTHORIZATION_HEADER_TYPE } from "../../FetchService";

const FetchService = EServiceBookFetchService;
const endpointPrefix = FetchService.apiVersion
  ? `/api/web/v${FetchService.apiVersion}/vehicle`
  : "/api/vehicle";


export const getVehicleWithAccessInviteGet = (accessToken) => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/GetVehicleWithAccessInvite`,
    params: { accessToken },
    headerOptions: {
      authorizationHeaderType: AUTHORIZATION_HEADER_TYPE.BEARER,
    },
  });
};