import ServiceHistoryHome from "components/ServiceHistory/ServiceHistoryHome";
import Subscribe from "components/Subscribe";
import HowItWorksHome from "components/HowItWorks/HowItWorksHome";
import DowloadAppHome from "./DowloadAppHome";
import OurFuturesHome from "components/OurFutures/OurFuturesHome";

function HomePage() {
  return (
    <div className="nc-PageHome  relative overflow-hidden">
      <div className="container relative space-y-10 mb-14 lg:space-y-2">
        <ServiceHistoryHome className="pt-3" />
        <div className="relative pb-8">
          <HowItWorksHome />
        </div>
        <DowloadAppHome />
        <OurFuturesHome />
        <Subscribe />
      </div>
    </div>
  );
}

export default HomePage;
