import { useEffect } from "react";
import HowItWorksBenefits from "components/HowItWorks/HowItWorksBenefits";

function BenefitsPage() {
  // CUSTOM THEME STYLE
  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-cyan-blueGrey");
    return () => {
      $body.classList.remove("theme-cyan-blueGrey");
    };
  }, []);

  function isMobileView() {
    return window.innerWidth <= 768;
  }
  const isMobile = isMobileView();

  return (
    <div className="nc-PageHome2  relative overflow-hidden">
      <div className="container relative space-y-10">
        <h2
          className={`mt-8  ${
            isMobile ? "ml-8" : ""
          }  flex items-center  leading-[115%] text-4xl md:leading-[115%] font-semibold text-neutral-900 justify-center`}
        >
          Kako funkcioniše?
        </h2>
        <HowItWorksBenefits />
      </div>
    </div>
  );
}

export default BenefitsPage;
