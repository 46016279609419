import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { DRIVER_NAVIGATION, SERVICE_NAVIGATION } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import LogoEK from "shared/Logo/E_logo_blue_1024x150.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleService } from "actions/serviceActions";
import { RootState } from "reducers";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({ data, onClickClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isService = useSelector((state: RootState) => state.service.isService);

  const handleClick = () => {
    dispatch(toggleService());
    if (!isService) {
      navigate("/servis");
    } else {
      navigate("/");
    }
  };

  data = isService ? SERVICE_NAVIGATION : DRIVER_NAVIGATION;
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul key={item.id} className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex px-4 text-neutral-900  text-sm font-medium rounded-lg hover:bg-neutral-100 mt-0.5 ${
                  isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex-1 flex"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="py-2.5 flex justify-end flex-1"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  return (
    <div className="overflow-y-auto w-full h-screen py-2 md:pb-2 lg:pb-2 pb-20 transition transform shadow-lg ring-1 bg-white divide-y-2 divide-neutral-100">
      <div className="py-6 px-5">
        <Logo className="max-w-[50%]" img={LogoEK} />
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderMenuChild)}
      </ul>
      {/* <div className="flex items-center justify-between py-6 px-5">
        <ButtonPrimary onClick={handleClick}>
          {isService ? "Za Vozace" : "Za Servise"}
        </ButtonPrimary>
      </div> */}
    </div>
  );
};

export default NavMobile;
