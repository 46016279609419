import React from "react";
import I18nContext from "./I18nContext";

class I18nProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLangId: undefined,
      allLanguages: [],
    };
  }
  componentWillUnmount() {
    this.isUnmounted = true;
  }
  getLangData = () => {
    const { selectedLangId, allLanguages } = this.state;
    return selectedLangId && allLanguages.find((x) => x.id === selectedLangId);
  };
  getAllLanguages = () => this.state.allLanguages;
  setAllLanguages = (allLanguages) =>
    !this.isUnmounted && this.setState({ allLanguages });
  setSelectedLangId = (selectedLangId) =>
    !this.isUnmounted && this.setState({ selectedLangId });
  render() {
    return (
      <I18nContext.Provider value={this.state}>
        {this.props.children}
      </I18nContext.Provider>
    );
  }
}

export default I18nProvider;
