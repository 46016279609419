import * as Sentry from '@sentry/react'
import AppConfig from 'config/AppConfig'

function init() {
    if (!AppConfig.sentryUrl) {
        return
    }
    Sentry.init({
        dsn: AppConfig.sentryUrl,
        environment: AppConfig.environment,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.captureConsoleIntegration({
                levels: ['error']
            })
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    })
    Sentry.getClient().getOptions().enabled = AppConfig.enableSentry
    Sentry.setContext(AppConfig.environment, {
        react: true
    })
}

function dispose() {
}

function sendMessage(message, severityLevel) {
    if (!AppConfig.sentryUrl) {
        return
    }
    Sentry.captureMessage(message, { level: severityLevel })
}

function sendException(exception) {
    if (!AppConfig.sentryUrl) {
        return
    }
    Sentry.captureException(exception)
}

function sendBreadcrumb(message, category, data, level) {
    if (!AppConfig.sentryUrl) {
        return
    }
    Sentry.addBreadcrumb({ message, category, data, level })
}

function simulateCrash() {
}

export default {
    init,
    dispose,
    sendException,
    sendBreadcrumb,
    simulateCrash,
    sendMessage,
}
