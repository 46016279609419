import { IconButton } from "@mui/material";
import { FC } from "react";
import { ServiceTypesArray } from "reducers/serviceReducer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EServiceBookFetchService from "services/Endpoints/EserviceBook/EServiceBookFetchService";

const BusinessLevel2Servicing: FC<{
  openlevel2Usluge: boolean;
  toggleBodyLevel2Usluge: () => void;
  bServices: ServiceTypesArray[];
}> = ({ openlevel2Usluge, toggleBodyLevel2Usluge, bServices }) => {
  return (
    <div className="listingSection__wrap_column">
      <h2
        className="text-2xl font-semibold"
        onClick={() => toggleBodyLevel2Usluge()}
      >
        <span className=" block font-bold p-2 pl-3 rounded-lg   mt-2 flex items-center justify-between ">
          Usluge
          <IconButton>
            {openlevel2Usluge ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </span>
      </h2>
      {openlevel2Usluge && (
        <div className="w-20 mt-5 ml-3 border-b border-neutral-300 "></div>
      )}
      {openlevel2Usluge && (
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700  ">
          {bServices &&
            bServices
              .filter((x) => x.level === 2)
              .map((item) => (
                <div key={item.id} className="flex  items-center space-x-4">
                  {EServiceBookFetchService.imageUrl(item.logourl) && (
                    <img
                      className="inset-0 max-w-[30px] max-h-[30px]"
                      src={
                        //@ts-ignore
                        EServiceBookFetchService.imageUrl(item.logourl).uri ||
                        ""
                      }
                      sizes="30"
                    />
                  )}
                  <span>{item.name}</span>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default BusinessLevel2Servicing;
