import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";

const Page404 = () => (
    <div className="nc-Page404">
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={I404Png} />
          <span className="block text-sm text-neutral-800 sm:text-base tracking-wider font-medium">
            Stranica koju tražite ne postoji.{" "}
          </span>
          <div className="pt-8">
          <ButtonPrimary href="/">Vratite se na početnu stranicu</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );

export default Page404;
