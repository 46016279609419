import { StringConstants } from './StringConstants'
import I18nContext from './I18nContext'
import I18nProviderService from './I18nProviderService'
import { getFromDict } from 'utils/HelperFunctions'

const getText = langDataKey => {
    const langData = I18nProviderService.getLangData()
    const text = getTextFromLangData(langData, langDataKey)
    return text
}
const getTextFromLangData = (langData, langDataKey) => {
    return (langData && getFromDict(langData.definitions?.value, langDataKey)) || StringConstants[langDataKey] || langDataKey
}
const I18nConsumer = componentCallback => (
    <I18nContext.Consumer>
        {({ selectedLangId, allLanguages }) => componentCallback(
            selectedLangId && allLanguages.find(x => x.id === selectedLangId),
            allLanguages,
        )}
    </I18nContext.Consumer>
)
export default {
    getText,
    getTextFromLangData,
    I18nConsumer,
}
