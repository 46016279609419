import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getBusinessGet,
  getBusinessRatingsGet,
  getServiceTypesGet,
} from "services/Endpoints/EserviceBook/BusinessesEndpoints";
import { BService, Business, Rating, ServiceTypesArray } from "data/types";
import EServiceBookFetchService from "services/Endpoints/EserviceBook/EServiceBookFetchService";
import {
  getBusinessWorkingDaysAndHours,
  hasWorkDayTimesData,
} from "utils/BusinessUtils";
import FullScreenLoading from "routers/FullScreenLoading";
import useWindowSize from "hooks/useWindowResize";

import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { setServiceTypesArrayRedux } from "actions/serviceActions";
import { useDispatch } from "react-redux";
import { BusinessProfileHeader } from "./BusinessProfileHeader";
import BusinessName from "./BusinessComponent/BusinessName";
import BussinessMap from "./BusinessComponent/BussinessMap";
import BusinessProfileAmenities from "./BusinessComponent/BusinessProfileAmenities";
import BusinessContactInformation from "./BusinessComponent/BusinessContactInformation";
import BusinessWorkingHours from "./BusinessComponent/BusinessWorkingHours";
import BusinessAboutUs from "./BusinessComponent/BusinessAboutUs";
import BusinessPricelist from "./BusinessComponent/BusinessPricelist";
import BusinessAverageRating from "./BusinessComponent/BusinessAverageRating";
import BusinessComments from "./BusinessComponent/BusinessComments";
import BusinessAllServicing from "./BusinessComponent/BusinessAllServicing";
import BusinessLevel2Servicing from "./BusinessComponent/BusinessLevel2Servicing";
import Page404 from "containers/Page404/Page404";
import { Helmet } from "react-helmet";
import AppConfig from "config/AppConfig";


interface ExpandedSections {
  [key: number]: boolean; // The key is a number and the value is a boolean
}
const BusinessProfilePage: FC<{}> = () => {
  const params = useParams();
  const { id } = params;
  const [data, setData] = useState<Business>();
  const [serviceTypes, setServiceTypes] = useState<ServiceTypesArray[]>(
    useSelector((state: RootState) => state.service.serviceTypes)
  );
  const [ratingData, setRatingData] = useState<Rating[]>([]);
  const [open, setOpen] = useState<boolean>(true);
  const [openCenovnik, setOpenCenovnik] = useState<boolean>(true);
  const [openOcena, setOpenOcena] = useState<boolean>(true);
  const [openRecenzije, setOpenRecenzije] = useState<boolean>(true);
  const [openAmenities, setOpenAmenities] = useState<boolean>(true);
  const [openlevel2Usluge, setOpenLevel2Usluge] = useState<boolean>(true);
  const [openRadnoVreme, setOpenRadnoVreme] = useState<boolean>(true);
  const [logo, setLogo] = useState<any>();
  const [selectedMarker, setSelectedMarker] = useState<Business | null>(null);
  const [expandedSections, setExpandedSections] = useState<ExpandedSections>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(true);
  const serviceTypesRedux = useSelector(
    (state: RootState) => state.service.serviceTypes
  );
  const dispatch = useDispatch();
  const toggleBody = () => {
    setOpen(!open);
  };

  const toggleBodyCenovnik = () => {
    setOpenCenovnik(!openCenovnik);
  };

  const toggleBodyOcena = () => {
    setOpenOcena(!openOcena);
  };

  const toggleBodyRecenzije = () => {
    setOpenRecenzije(!openRecenzije);
  };

  const toggleBodyAmenities = () => {
    setOpenAmenities(!openAmenities);
  };

  const toggleBodyLevel2Usluge = () => {
    setOpenLevel2Usluge(!openlevel2Usluge);
  };

  const toggleBodyRadnoVreme = () => {
    setOpenRadnoVreme(!openRadnoVreme);
  };

  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  const profilePictures = data?.profileImages || [];
  const index =
    profilePictures && profilePictures.findIndex((item) => item.isLogo);
  if (index !== -1) {
    const [logoImage] = profilePictures.splice(index, 1);
    setLogo(logoImage);
  }

  const imageURLS =
    profilePictures &&
    profilePictures.map((x) => EServiceBookFetchService.imageUrl(x.url));
  const images = imageURLS?.map((x: any) => x.uri);

  useEffect(() => {
    const fetchServiceTypes = async () => {
      try {
        const response = await getServiceTypesGet();
        if (!response.ok) {
          throw new Error("response not ok");
        }
        const data = await response.json();
        setServiceTypes(data.items);
        dispatch(setServiceTypesArrayRedux(data.items));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchData = async () => {
      try {
        const response = await getBusinessGet(id);
        if (!response.ok) {
          throw new Error("response not ok");
        }

        const data = await response.json();
        setData(data.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRatingsData = async () => {
      try {
        const response = await getBusinessRatingsGet(id);
        if (!response.ok) {
          throw new Error("response not ok");
        }

        const data = await response.json();
        setRatingData(data.items);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchRatingsData();
    fetchData();
    if (!serviceTypesRedux.length) fetchServiceTypes();
  }, []);

  useEffect(() => {
    if (!loading) {
      const savedScrollPosition = parseInt(
        sessionStorage.getItem(location.pathname) || "0"
      );
      window.scrollTo(0, savedScrollPosition);
    }
  }, [loading, location.pathname]);

  const mapSectionRef = useRef<HTMLDivElement | null>(null);
  const uslugaSectionRef = useRef<HTMLDivElement | null>(null);
  const oNamaSectionRef = useRef<HTMLDivElement | null>(null);
  const cenovnikSectionRef = useRef<HTMLDivElement | null>(null);
  const ocenaSectionRef = useRef<HTMLDivElement | null>(null);
  const recenzijeSectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapSectionRef.current) {
      mapSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (uslugaSectionRef.current) {
      uslugaSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (oNamaSectionRef.current) {
      oNamaSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (cenovnikSectionRef.current) {
      cenovnikSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (ocenaSectionRef.current) {
      ocenaSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleAddressRefClick = () => {
    if (mapSectionRef.current) {
      mapSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
      setTimeout(() => {
        //@ts-ignore
        const rect = mapSectionRef.current.getBoundingClientRect();
        const offset = -300;
        window.scrollBy({
          top: rect.top + window.scrollY + offset - window.scrollY,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  const handleUslugaRefClick = () => {
    if (uslugaSectionRef.current) {
      uslugaSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
      setTimeout(() => {
        //@ts-ignore
        const rect = uslugaSectionRef.current.getBoundingClientRect();
        const offset = -160;
        window.scrollBy({
          top: rect.top + window.scrollY + offset - window.scrollY,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  const handleONamaRefClick = () => {
    if (oNamaSectionRef.current) {
      oNamaSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
      setTimeout(() => {
        //@ts-ignore
        const rect = oNamaSectionRef.current.getBoundingClientRect();
        const offset = -150;

        window.scrollBy({
          top: rect.top + window.scrollY + offset - window.scrollY,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  const handleCenovnikRefClick = () => {
    if (cenovnikSectionRef.current) {
      cenovnikSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
      setTimeout(() => {
        const offset = -150;
        window.scrollBy({
          top: offset,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  const handleOcenaRefClick = () => {
    if (ocenaSectionRef.current) {
      ocenaSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
      setTimeout(() => {
        const offset = -150;
        window.scrollBy({
          top: offset,
          behavior: "smooth",
        });
      }, 700);
    }
  };

  const propertyName = ["grade1", "grade2", "grade3", "grade4"];
  const ocene = ["Kvalitet usluge", "Cena", "Komunikacija", "Vremenski rokovi"];

  const getServiceTypeObjectsFromIds = (numbers: number[]) => {
    const matchedServiceTypes = serviceTypes.filter(
      (type: ServiceTypesArray) => {
        return type && numbers.includes(type.id);
      }
    );

    return matchedServiceTypes;
  };

  const brojevi = getServiceTypeObjectsFromIds(
    data ? data?.bServices.map((x: BService) => x.service_ID) : []
  );
  const naziviUsluga = brojevi
    .filter((x) => {
      return x.name && x.level === 1;
    })
    .map((x) => x.name);
  function organizeLevel3Objects(
    userObjects: ServiceTypesArray[]
  ): { header: ServiceTypesArray; items: ServiceTypesArray[] }[] {
    const sections: {
      header: ServiceTypesArray;
      items: ServiceTypesArray[];
    }[] = [];

    const level1Objects = userObjects.filter((obj) => obj.level === 1);
    level1Objects.forEach((level1) => {
      const level2Objects = userObjects.filter(
        (obj) => obj.service_ID === level1.id && obj.level === 2
      );

      const level3Objects: any[] = [];
      level2Objects.forEach((level2) => {
        const vrednosti = userObjects.filter(
          (obj) => obj.service_ID === level2.id && obj.level === 3
        );
        level3Objects.push(...vrednosti);
      });

      sections.push({
        header: level1,
        items: level3Objects,
      });
    });
    return sections;
  }

  useEffect(() => {
    const initialExpandedSections: ExpandedSections = {};
    const sections = organizeLevel3Objects(
      getServiceTypeObjectsFromIds(
        data ? data.bServices.map((x) => x.service_ID) : []
      )
    );
    sections.forEach((section) => {
      if (section.items.length) {
        initialExpandedSections[section.header.id] = true;
      }
    });
    setExpandedSections(initialExpandedSections);
  }, [data?.bServices]);

  const toggleSection = (sectionId: number) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const getReviewSnippet = () => {
    const reviewSnippet = { 
        "@context": "http://schema.org",
        "@type": "Product",
        "name": data?.name,
        //@ts-ignore
        "image" : logo?.url ? EServiceBookFetchService.imageUrl(logo.url).uri : '',
        "aggregateRating":
        {
            "@type": "AggregateRating",
            "ratingValue": data?.ratingAvg.toFixed(1),
            "reviewCount": data?.ratingCount
        }
    }
    return JSON.stringify(reviewSnippet)
  };

  const workDaysAndHours = getBusinessWorkingDaysAndHours(
    data ? data : ({} as Business)
  );
  const hasWorkingDays = hasWorkDayTimesData(workDaysAndHours);

  function organizeLevel2Objects(
    userObjects: BService[]
  ): { header: ServiceTypesArray; items: ServiceTypesArray[] }[] {
    const sections: {
      header: ServiceTypesArray;
      items: ServiceTypesArray[];
    }[] = [];

    const filteredObject2Array = serviceTypes.filter((obj2) =>
      userObjects.some((obj1) => obj1.service_ID === obj2.id)
    );

    if (!filteredObject2Array.length) {
      return [];
    }
    const level2Objects = filteredObject2Array.filter((obj) => obj.level === 2);
    level2Objects.forEach((level2) => {
      const level3Objects = filteredObject2Array.filter(
        (obj) => obj.service_ID === level2.id && obj.level === 3
      );
      sections.push({
        header: level2,
        items: level3Objects,
      });
    });
    return sections;
  }

  const sekcije2 = organizeLevel2Objects(data?.bServices ? data.bServices : []);
  if (loading) {
    return <FullScreenLoading />;
  }
  return !data ? (
    <Page404 />
  ) : (
    <div className="nc-ListingStayDetailPage container">
        <Helmet>
            <title>{data.name}</title>
            <meta property="og:title" content={data.name}></meta>
        </Helmet>
        {
            !!AppConfig.features.reviewSnippetEnabled && !!data.ratingAvg &&
            <Helmet>
                <script type="application/ld+json">{getReviewSnippet()}</script>
            </Helmet>
        }
        {
            !!AppConfig.appUrl &&
            <Helmet>
                <link rel="canonical" href={`${AppConfig.appUrl}/profil-servisa/${data?.id}/${data?.slug}`} />
            </Helmet>
        }
      <BusinessProfileHeader images={images} />
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10 pb-6 ">
          <BusinessName
            logo={logo}
            data={data}
            handleAddressRefClick={handleAddressRefClick}
            handleOcenaRefClick={handleOcenaRefClick}
            handleCenovnikRefClick={handleCenovnikRefClick}
            handleONamaRefClick={handleONamaRefClick}
            handleUslugaRefClick={handleUslugaRefClick}
          />

          {WIN_WIDTH <= 768 &&
            data?.autoServiceData?.concept && (
              <div className="listingSection__wrap_column mb-10 items-center">
                <img
                  className="inset-0 max-w-[100px] h-auto rounded-2xl"
                  src={
                    //@ts-ignore
                    EServiceBookFetchService.imageUrl(
                      data.autoServiceData.concept.logo
                      //@ts-ignore
                    ).uri || ""
                  }
                  alt=""
                  sizes="30"
                />
                <p className="text-center">{`${data.name} je deo ${data.autoServiceData.concept.name} servisnog koncepta`}</p>
              </div>
            )}

          {!!brojevi?.length && (
            <BusinessLevel2Servicing
              bServices={getServiceTypeObjectsFromIds(
                data.bServices.map((x) => x.service_ID)
              )}
              openlevel2Usluge={openlevel2Usluge}
              toggleBodyLevel2Usluge={toggleBodyLevel2Usluge}
            />
          )}

          <BussinessMap
            data={data}
            mapSectionRef={mapSectionRef}
            naziviUsluga={naziviUsluga}
          />
          {data.businessAmenities && !!data.businessAmenities.length && (
            <BusinessProfileAmenities
              amenities={data.businessAmenities}
              openAmenities={openAmenities}
              toggleBody={toggleBodyAmenities}
            />
          )}
          {WIN_WIDTH < 768 && (
            <BusinessContactInformation
              data={data}
              handleAddressRefClick={handleAddressRefClick}
            />
          )}
          {WIN_WIDTH < 768 && hasWorkingDays && (
            <BusinessWorkingHours
              data={data}
              openRadnoVreme={openRadnoVreme}
              toggleBodyRadnoVreme={toggleBodyRadnoVreme}
            />
          )}

          {data?.description && (
            <BusinessAboutUs
              data={data}
              oNamaSectionRef={oNamaSectionRef}
              open={open}
              toggleBody={toggleBody}
            />
          )}
          {!!data.bServices.length && data.pricelistEnabled && (
            <BusinessPricelist
              bServiceArray={data.bServices}
              expandedSectionsProp={expandedSections}
              serviceTypesFromRedux={serviceTypes}
              toggleSectionProp={toggleSection}
              toggleBody={toggleBodyCenovnik}
              open={openCenovnik}
              bServiceTypes={getServiceTypeObjectsFromIds(
                data.bServices.map((x) => x.service_ID)
              )}
              cenovnikSectionRef={cenovnikSectionRef}
            />
          )}
          {!!data?.ratingAvg && (
            <BusinessAverageRating
              ratingAvg={data.ratingAvg}
              ratingCount={data.ratingCount}
              ocenaSectionRef={ocenaSectionRef}
              toggleBody={toggleBody}
              ocene={ocene}
              open={open}
              gradeAvg={[
                data.grade1Avg,
                data.grade2Avg,
                data.grade3Avg,
                data.grade4Avg,
              ]}
            />
          )}
          {!!ratingData.length && (
            <BusinessComments
              openRecenzije={openRecenzije}
              ratingData={ratingData}
              recenzijeRef={recenzijeSectionRef}
              toggleBodyRecenzije={toggleBodyRecenzije}
            />
          )}
          {!!sekcije2.length && (
            <BusinessAllServicing
              expandedSections={expandedSections}
              uslugaSectionRef={uslugaSectionRef}
              open={open}
              sekcije2={sekcije2}
              toggleBody={toggleBody}
              toggleSection={toggleSection}
            />
          )}
        </div>
        {WIN_WIDTH > 768 && (
          <div className="space-y-8 lg:block flex-grow mt-14 lg:mt-0 lg:space-y-10 pb-6 ">
            <div className="sticky top-28">
              <div className="mb-10">
                <BusinessContactInformation
                  data={data}
                  handleAddressRefClick={handleAddressRefClick}
                />
              </div>
              {data.autoServiceData?.concept && (
                <div className="listingSection__wrap_column mb-10 items-center">
                  <img
                    className="inset-0 max-w-[100px] h-auto"
                    src={
                      //@ts-ignore
                      EServiceBookFetchService.imageUrl(
                        data.autoServiceData.concept.logo
                        //@ts-ignore
                      ).uri || ""
                    }
                    alt=""
                    sizes="30"
                  />
                  <p className="text-center">{`${data.name} je deo ${data.autoServiceData.concept.name} servisnog koncepta`}</p>
                </div>
              )}

              {hasWorkingDays && (
                <BusinessWorkingHours
                  data={data}
                  openRadnoVreme={openRadnoVreme}
                  toggleBodyRadnoVreme={toggleBodyRadnoVreme}
                />
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default BusinessProfilePage;
