//Nas kod *********************************************************
export interface CustomLink {
  label: string;
  href: string;
  targetBlank?: boolean;
}
export interface ProfilePicture {
  id: number;
  businessId: number;
  isLogo: boolean;
  ordinal: 0;
  url: string;
}

export interface Business {
  id: number;
  countryId: number;
  name: string;
  subtitle: string | null;
  description: string;
  countryCode: "SRB";
  city: string;
  address: string;
  contactPhone: string;
  autoServiceData: AutoServiceData;
  contactEmail: string;
  distance: number;
  website: string | null;
  latitude: number;
  longitude: number;
  ratingAvg: number;
  grade1Avg: number;
  grade2Avg: number;
  grade3Avg: number;
  grade4Avg: number;
  ratingCount: number;
  profileImages: ProfilePicture[];
  bServices: BService[];
  businessAmenities: BusinessAmenities[];
  workHoursMonStart: string;
  workHoursMonEnd: string;
  workHoursTueStart: string;
  workHoursTueEnd: string;
  workHoursWedStart: string;
  workHoursWedEnd: string;
  workHoursThuStart: string;
  workHoursThuEnd: string;
  workHoursFriStart: string;
  workHoursFriEnd: string;
  workHoursSatStart: string;
  workHoursSatEnd: string;
  workHoursSunStart: string;
  workHoursSunEnd: string;
  pricelistEnabled: boolean;
  slug: string;
}

export interface SearchBusinesses {
  allResultsCount: number;
  searchResults: Business[];
}

export interface ERecord {
  id: number;
  business: Business;
  businessAddress: string;
  businessWebsite: string;
  businessEmail: string;
  businessPhone: string;
  businessCity: string;
  dateCreated: string;
  dateServiced: string;
  vehicleId: number;
  title: string;
  description: string;
  createdBy: string;
  price: number;
  ratingGrade: number;
  odometer: number;
  type: 2;
  subtype: 1;
  registrationDueDate: string;
  accessToken: string;
  vehManufacturer: string;
  vehModel: string;
  vehLicPlates: string;
  vehVIN: string;
  vehManufacturerId: number;
  vehEngineCode: string[];
  vehPower: number;
  vehVariant: string;
  vehFuelTypePrimary: number;
  vehFuelTypeSecondary: number;
  vehLoad: number;
  vehYearOfProduction: number;
  vehCountryCode: string;
  vehType: number;
  business_ID: number;
  businessName: string;
  profileImages: ProfilePicture[];
  files: ERecordFileData[];
  items: ERecordItems[];
  reminders: Reminder[];
  smsLinkExpiryPeriodInDays: number;
}

export interface Vehicle {
  vehAccessInvite: VehAccessInvite;
  manufacturer: string;
  model: string;
  licPlates: string;
  vin: string;
  power: number;
  variant: string;
  yearOfProduction: number;
  smsLinkExpiryPeriodInDays: number;
}

export interface VehAccessInvite {
  id: number;
  inviteCreatorName: string;
  isCreatorCompany: boolean;
}

export interface ERecordFileData {
  id: number;
  ERecordId: number;
  fileType: number;
  url: string;
}

export interface ServiceTypesArray {
  id: number;
  name: string;
  level: number;
  service_ID: number;
  logourl: string;
}

export interface ListingGalleryImage {
  id: number;
  url: string;
}

export interface ERecordItems {
  brandName: string;
  catalogCode: string;
  eRecord: ERecord;
  eRecordId: number;
  name: string;
  measurementUnit: string;
  price: number;
  id: number;
  ordinal: string;
  internalCode: string;
  quantity: number;
}

export interface Reminder {
  businessID: number;
  businessName: string;
  createdBy: string;
  dateCreated: string;
  driverId: number;
  dueDate: string;
  id: number;
  isResolved: boolean;
  note: string;
  notificationDate: string;
  odometer: string;
  reminderDaysBefore: number;
  reminderType: number;
  title: string;
  vehicleId: number;
}

export interface Concept {
  logo: string;
  name: string;
}

export interface AutoServiceData {
  concept: Concept;
}

export interface BusinessAmenities {
  logo: string;
  name: string;
  amenity_ID: string;
}

export interface BService {
  service_ID: number;
  business_ID?: number;
  price?: number;
  priceFrom?: number;
  priceTo?: number;
}

export const contactInfo = [
  // {
  //   title: "🗺 Adresa",
  //   desc: "Miće Popovića 18, Veternik, 21203",
  // },
  {
    title: "💌 Email",
    desc: "office@eknjizica.rs",
  },
  // {
  //   title: "☎ Telefon",
  //   desc: "+381 (0)69 21-21-069",
  // },
];

export interface Rating {
  id: number;
  driverDisplayName: string;
  gradeAvg: number;
  driverPosComment: string;
  driverNegComment: string;
  title: string;
  vehicleDescription: string;
  dateCreated: string;
  serviceComment: string;
  serviceCommentDate: string;
  businessName: string;
  driverId: number;
  driverProfileImageUrl: string;
  businessId: number;
  businessLogoURL: string;
  grade1: number;
  grade2: number;
  grade3: number;
  grade4: number;
}
export type TwMainColor =
  | "pink"
  | "green"
  | "yellow"
  | "red"
  | "indigo"
  | "blue"
  | "purple"
  | "gray"
  | "white";
