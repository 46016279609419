import { DEFAULT_COUNTRY_CODE, DEFAULT_LANGUAGE_CODE } from "./AppConstants";

const DEFAULT_LANGUAGE = "sr";
const DEFAULT_COUNTRY = "RS"; // SRB

const LANGUAGE_CODE_MAPPING = Object.freeze({
  "bs-Latn-BA": "bs",
  "mk-MK": "mk",
  "sr-Latn-ME": DEFAULT_LANGUAGE, // montenegrin not supported by api
  "hr-HR": "hr",
  "sr-RS": DEFAULT_LANGUAGE,
  "sl-SL": "sl",
});

const COUNTRY_CODE_MAPPING = Object.freeze({
  BIH: "BA", // BIH
  MK: "MK", // MKD
  MNE: "ME", // MNE
  HR: "HR", // HRV
  SRB: DEFAULT_COUNTRY,
  SLO: "SI", // SVN
});

const getNameFromPrediction = (prediction) => {
  if (!prediction) {
    return;
  }

  const { terms, description } = prediction;
  let name;

  if (terms && Array.isArray(terms) && terms.length > 0) {
    name = terms[0].value;
  }

  if (!name && !!description) {
    name = description.includes(",")
      ? description.substring(0, description.indexOf(","))
      : description;
  }

  return name;
};

const getLocationObjFromCoordinatesApiResponse = (response) => {
  if (
    response &&
    response.results &&
    Array.isArray(response.results) &&
    response.results.length > 0
  ) {
    return response.results[0]?.geometry?.location;
  }
};

const getCountryQueryPart = (countryCode) =>
  `${COUNTRY_CODE_MAPPING[countryCode] || DEFAULT_COUNTRY}`;

export const getCityNameFromCoords = async (lat, lng) => {
  try {
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({ location: new google.maps.LatLng(lat, lng) })

    if (response.results?.length > 0) {
      const allAddressComponents = response.results.flatMap(
        (result) => result.address_components
      );

      // Search for various possible components to identify the city
      const priorityOrder = [
        "locality",
        "sublocality",
        "administrative_area_level_3",
        "administrative_area_level_2",
        "administrative_area_level_1",
      ];

      // Find the first non-null component in the priority order
      for (const type of priorityOrder) {
        const component = allAddressComponents.find((component) =>
          component.types.includes(type)
        );
        if (component) {
          return component.long_name;
        }
      }

      // If nothing is found, consider using broader components or the first result's formatted address as a fallback
      return response.results[0]?.formatted_address ?? "City not found";
    }
  } catch (error) {
    console.error("Error getting city name:", error);
  }
};

export const getGoogleApiAutocompletePlaces = async (
  searchString,
  languageCode = DEFAULT_LANGUAGE_CODE,
  countryCodes = [DEFAULT_COUNTRY_CODE]
) => {
  if (!searchString) {
    return [];
  }

  const languageParam = LANGUAGE_CODE_MAPPING[languageCode] || DEFAULT_LANGUAGE;
  if (!Array.isArray(countryCodes)) {
    countryCodes = countryCodes.filter((x) => !!x);
    if (countryCodes.length === 0) {
      countryCodes = [DEFAULT_COUNTRY_CODE];
    }
  }
  
  const service = new google.maps.places.AutocompleteService();
  const countriesQueryPart = countryCodes.map(getCountryQueryPart).join("|");

  var request = {
    language: languageParam,
    componentRestrictions: { country: countriesQueryPart },
    input: searchString,
    types: ["locality", "administrative_area_level_3"],
  };

  const response = await service.getPlacePredictions(request);

  return response.predictions
    .map((prediction) => ({
      placeId: prediction?.place_id,
      name: getNameFromPrediction(prediction),
    }))
    .filter((x) => !!x && !!x.placeId && !!x.name);
};

export const getGoogleApiCoordinates = async (placeId) => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode({ placeId })

  const location = getLocationObjFromCoordinatesApiResponse(response);
  if (!location) {
    return;
  }

  return {
    latitude: location?.lat(),
    longitude: location?.lng(),
  };
};
