import { OLD_FETCH_ARRIVED_HACK_CONST, GLOBAL_FIELD_KEY } from "./AppConstants";
import I18nHelper from "I18n/I18nHelper";
import RefreshTokenMechanismService from "services/RefreshTokenMechanismService";

const processResponse = async (
  response,
  showErrorMessage = false,
  showErrorForAccessTokenExpired
) => {
  let responseData;
  if (response.status === 401) {
    if (
      response.headers.has("www-authenticate") &&
      response.headers.has("x-accesstokenexpired")
    ) {
      showErrorMessage = showErrorMessage && showErrorForAccessTokenExpired;
      // console.log('Access token has expired!')
      // console.log(response)
      responseData = createErrorResponse(
        GLOBAL_FIELD_KEY,
        I18nHelper.getText("UNAUTHORIZED_ACCESS"),
        true
      );
    } else {
      // console.log('Unauthorized access!')
      responseData = createErrorResponse(
        GLOBAL_FIELD_KEY,
        I18nHelper.getText("UNAUTHORIZED_ACCESS")
      );
    }
  }
  // eslint-disable-next-line eqeqeq
  else if (response.status == 404) {
    responseData = createErrorResponse(
      GLOBAL_FIELD_KEY,
      I18nHelper.getText("NOT_FOUND_404_ERROR")
    );
  }
  // hack for "delayed" response @see FetchService.customFetch
  // eslint-disable-next-line eqeqeq
  else if (response.status == OLD_FETCH_ARRIVED_HACK_CONST) {
    showErrorMessage = false;
    responseData = createErrorResponse(
      GLOBAL_FIELD_KEY,
      I18nHelper.getText("UNKNOWN_ERROR_OCCURRED")
    );
  }
  // eslint-disable-next-line eqeqeq
  else if (
    response.status == 400 ||
    response.status < 300 ||
    response.status >= 500
  ) {
    const data = await response.json();
    responseData = translateAndFixResponse(data);
  } else {
    responseData = createErrorResponse(
      GLOBAL_FIELD_KEY,
      I18nHelper.getText("UNKNOWN_ERROR_OCCURRED")
    );
  }

  if (showErrorMessage && responseData.message) {
    // DropdownAlertHelper.showError(responseData.message);
  }

  return responseData;
};

export const tryCatch = async (
  fetchFunc,
  showErrorMessage = false,
  catchErrorMessage = "NETWORK_ERROR"
) => {
  try {
    return await fetchFunc();
  } catch (error) {
    console.log(error);
    const responseData = createErrorResponse(
      GLOBAL_FIELD_KEY,
      catchErrorMessage
    );
    if (showErrorMessage) {
    //   DropdownAlertHelper.showError(responseData.message);
    }
    return responseData;
  }
};

const onAccessTokenExpired = async (fetchFunc, showErrorMessage, success) => {
  if (success) {
    return await processResponse(await fetchFunc(), showErrorMessage, true);
  } else {
    return await createErrorResponse(
      GLOBAL_FIELD_KEY,
      I18nHelper.getText("UNAUTHORIZED_ACCESS")
    );
  }
};

export const tryCatchJson = async (
  fetchFunc,
  showErrorMessage = false,
  catchErrorMessage = "NETWORK_ERROR",
  skipAccessTokenExpiredAction = false
) => {
  try {
    let responseData = await processResponse(
      await fetchFunc(),
      showErrorMessage
    );
    if (responseData.accessTokenExpired && !skipAccessTokenExpiredAction) {
      // console.log('Refreshing Access Token...')
      responseData = await onAccessTokenExpired(
        fetchFunc,
        showErrorMessage,
        await RefreshTokenMechanismService.executeRefreshTokenAction()
      );

      // console.log('responseData after refresh token: ')
      // console.log(responseData)
    }
    return responseData;
  } catch (error) {
    console.log(error);

    if (showErrorMessage) {
    //   DropdownAlertHelper.showError(catchErrorMessage);
    }
    return createErrorResponse(GLOBAL_FIELD_KEY, catchErrorMessage);
  }
};

export const getStringStream = async (
  fetchFunc,
  errorMessage = "ERROR_RETRIEVING_LANG_DEF"
) => {
  let result = null;
  try {
    const response = await fetchFunc();
    result = response.status === 200 && (await response.text());
  } catch (error) {
    console.log(error);
    // errorMessage && DropdownAlertHelper.showError(errorMessage);
  }
  return result;
};

function translateAndFixResponse(responseData) {
  if (!responseData.errors || responseData.success) {
    return responseData;
  }

  const errorFieldNames = Object.keys(responseData.errors);
  // translate all error messages
  errorFieldNames.forEach((x) => {
    const error = responseData.errors[x];
    error.message = I18nHelper.getText(error.message);
  });

  // populate message and messageCode with first error
  const firstErrorField = responseData.errors[errorFieldNames[0]];
  responseData.message = firstErrorField.message;
  responseData.messageCode = firstErrorField.messageCode;
  return responseData;
}

function createErrorResponse(errorField, errorMessage, accessTokenExpired) {
  errorMessage = I18nHelper.getText(errorMessage);
  return {
    success: false,
    message: errorMessage,
    accessTokenExpired,
    errors: {
      [errorField]: {
        message: errorMessage,
      },
    },
  };
}

export const executeCustomGet = async (uri) => {
  const response = await fetch(uri);
  const data = await response.json();
  return data;
};
