import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "reducers";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  customClassName?: string;
}

const Logo: React.FC<LogoProps> = ({
  img,
  imgLight,
  className = "w-40 h-auto",
  customClassName,
}) => {
  const isService = useSelector((state: RootState) => state.service.isService);

  return (
    <Link
      to={isService ? "/servis" : "/"}
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 `}
    >
      {img ? (
        <img
          className={`block ${
            customClassName ? `${customClassName} ${className}` : className
          }`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img className="hidden max-h-12" src={imgLight} alt="Logo-Light" />
      )}
    </Link>
  );
};

export default Logo;
