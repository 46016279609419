import Heading from "components/Heading/Heading";
import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/open.png";
import HIW2img from "images/work.png";
import HIW3img from "images/rate.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


export interface HowItWorksBenefitsProps {
  className?: string;
  showBottom?: boolean;
}

const HowItWorksBenefits: FC<HowItWorksBenefitsProps> = ({ showBottom }) => {
  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  // Example usage:
  const isMobile = isMobileView();
  const navigate = useNavigate();
  return (
    <div className="p-3" data-nc-id="SectionHowItWork">
      <Helmet>
        <title>Kako funkcioniše</title>
        <meta property="og:title" content="Kako funkcioniše"></meta>
      </Helmet>
      <div className="mt-12 relative grid md:grid-cols-2 gap-20">
        <div className="relative flex flex-col items-center max-w-xs mx-auto">
          <NcImage
            containerClassName="mb-8 max-w-[200px] mx-auto"
            src={HIW1img}
          />
        </div>
        <div className="text-center mt-auto">
          <h3 className="text-xl font-semibold">
            Kako se otvara E-Knjižica auta?
          </h3>
          <span className="block mt-5 text-neutral-500 ">
            Elektronsku servisnu knjižicu možete otvoriti tako što ćete poneti
            saobraćajnu dozvolu do bilo kog od{" "}
            <a
              onClick={() => navigate("/adresar")}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              autorizovanih auto servisa
            </a>{" "}
            u Vašem mestu. Sve što je potrebno da zatražite otvaranje knjižice i
            za nekoliko sekundi očitavanjem saobraćajne dozvole Vaša E-Knjižica
            je kreirana. Dobićete SMS poruku sa aktivacionim linkom na kome
            možete preuzeti E-Knjižica mobilnu aplikaciju. Sa druge strane,
            ukoliko želite da knjižicu otvorite sami iz udobnosti vašeg doma,
            možete to uraditi instaliranjem E-Knjižica aplikacije iz koje možete
            poslati zahtev za upisom vašeg vozila u evidenciju.
          </span>
        </div>
      </div>
      <div className="mt-20 relative grid md:grid-cols-2 gap-20">
        {isMobile && (
          <div className="relative flex flex-col items-center max-w-xs mx-auto">
            <NcImage
              containerClassName="mb-8 max-w-[200px] mx-auto"
              src={HIW2img}
            />
          </div>
        )}

        <div className="text-center mt-auto">
          <h3 className="text-xl font-semibold">
            Šta je sadržaj i ko pristupa podacima vašeg vozila?
          </h3>
          <span className="block mt-5 text-neutral-500 ">
            U knjižicu se upisuju tehnički podaci vozila (broj šasije i motora,
            kubikaža, snaga, vrsta goriva, podaci o gumama, klimi itd), svi
            urađeni servisi i opravke, kao i ugrađeni delovi i fiskalni računi.
            E-knjižici može da pristupa samo vlasnik, tj. onaj ko poseduje
            saobraćajnu dozvolu, kao i servisi u kojima se održava vozilo ili
            vrši tehnički pregled. Auto servisi i tehnički pregledi sami upisuju
            svoje radove i pri tome prilažu svu potrebnu dokumentaciju i
            fiskalni račun. Tom prilikom, aplikacija vam prikazuje obaveštenje
            (notifikaciju popup primljenog SMS-a) o izvršenim radovima,
            primljenim računima i slično.
          </span>
        </div>

        {!isMobile && (
          <div className="relative flex flex-col items-center max-w-xs mx-auto">
            <NcImage
              containerClassName="mb-8 max-w-[200px] mx-auto"
              src={HIW2img}
            />
          </div>
        )}
      </div>
      <div className="mt-20 relative grid md:grid-cols-2 gap-20 mb-10">
        <div className="relative flex flex-col items-center max-w-xs mx-auto">
          <NcImage
            containerClassName="mb-8 max-w-[200px] mx-auto"
            src={HIW3img}
          />
        </div>
        <div className="text-center mt-auto">
          <h3 className="text-xl font-semibold">
            Šta ukoliko nisam/jesam zadovoljan servisom?
          </h3>
          <span className="block mt-5 text-neutral-500 ">
            Svaki servis i tehnički pregled možete oceniti u skladu sa vašim
            zadovoljstvom. Time pomažete ostalim vozačima da pronađu servis koji
            im najviše odgovara i izbegnu loša iskustva.
          </span>
        </div>
      </div>
      {showBottom && (
        <div className="grid my-3">
          <Heading isCenter desc="">
            Kako funkcioniše?
          </Heading>
          <span className="justify-self-center">
            Posetite{" "}
            <a
              onClick={() => navigate("/adresar")}
              style={{
                color: "blue",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              autorizovane auto servise
            </a>{" "}
            i otvorite E-Knjižicu besplatno!
          </span>
        </div>
      )}
    </div>
  );
};

export default HowItWorksBenefits;
