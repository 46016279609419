// eslint-disable-next-line no-bitwise
export function convertParamsToUrlSearchString(urlParameters) {
  const urlParametersKeys = Object.keys(urlParameters).filter(
    (key) => urlParameters[key] !== null && urlParameters[key] !== undefined
  );
  if (urlParametersKeys.length > 0) {
    return urlParametersKeys
      .map((key) => {
        const value = urlParameters[key];
        if (Array.isArray(value)) {
          return value
            .filter((x) => x !== null && x !== undefined)
            .map((arrValue) => `${key}=${encodeURIComponent(arrValue)}`)
            .join("&");
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join("&");
  }
}

export function openURL(url, onError) {
  return new Promise((resolve, reject) => {
    try {
      let win = window.open(url, "_blank");
      win.focus();
      resolve && resolve(true);
    } catch (e) {
      onError && onError("Error: " + e);
      reject && reject();
    }
  });
}
