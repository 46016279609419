import { XMarkIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

export interface ClearDataButtonProps {
  onClick: () => void;
  positionTop?: boolean;
}

const ClearDataButton: FC<ClearDataButtonProps> = ({
  onClick,
  positionTop,
}) => {
  return (
    <span
      onClick={() => onClick && onClick()}
      className={` absolute z-10 w-5 h-5 lg:w-6 lg:h-6 text-sm bg-neutral-200 rounded-full flex items-center justify-center ${
        positionTop ? " top-9 right-9 " : "right-3 lg:right-4 top-1/2"
      }   transform -translate-y-1/2`}
    >
      <XMarkIcon className="w-4 h-4 cursor-pointer" />
    </span>
  );
};

export default ClearDataButton;
