import Glide from "@glidejs/glide";
import { FC } from "react";
import { useEffect } from "react";
import useNcId from "hooks/useNcId";

export interface GlideImagesProps {
  className?: string;
  uniqueClassName?: string;
  glideImages: { id: number; svg: string }[];
}

const GlideImages: FC<GlideImagesProps> = ({
  className = "",
  uniqueClassName = "",
  glideImages,
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        }).mount();
      }, 10);
    }
  }, []);

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <div className="relative  max-w-2xl mx-auto">
        <div className={`mt-12 lg:mt-16 relative grid ${UNIQUE_CLASS}`}>
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {glideImages.map((item) => (
                <li
                  key={item.id}
                  className="glide__slide  md:flex md:flex-col items-center text-center"
                >
                  <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                    <img
                      className="mx-auto max-w-[200px] max-h-[200px]"
                      src={item.svg}
                      alt=""
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="mt-10 glide__bullets   md:flex md:flex-wrap md:items-center justify-self-center"
            data-glide-el="controls[nav]"
          >
            {glideImages.map((item, index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 justify-self-end  rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlideImages;
