const ActionTypes = Object.freeze({
    // Business
    SET_SERVICE_TYPES_ACTION: 'SET_SERVICE_TYPES_ACTION',
    SET_RECENT_SEARCHES_ACTION: 'SET_RECENT_SEARCHES_ACTION',
    SET_RECENT_PLACE_SEARCHES_ACTION: 'SET_RECENT_PLACE_SEARCHES_ACTION',
    SET_BUSINESS_RECENT_SEARCHES_ACTION: 'SET_BUSINESS_RECENT_SEARCHES_ACTION',

    // Business Search
    SET_BUSINESS_SEARCH_RESULTS_ACTION: 'SET_BUSINESS_SEARCH_RESULTS_ACTION',
    APPEND_BUSINESS_SEARCH_RESULTS_ACTION: 'APPEND_BUSINESS_SEARCH_RESULTS_ACTION',
    CLEAR_BUSINESS_SEARCH_RESULTS_ACTION: 'CLEAR_BUSINESS_SEARCH_RESULTS_ACTION',
    REPLACE_BUSINESS_SEARCH_RESULT_ACTION: 'REPLACE_BUSINESS_SEARCH_RESULT_ACTION',

    // Business Ratings
    SET_BUSINESS_RATINGS_ACTION: 'SET_BUSINESS_RATINGS_ACTION',
})

export default ActionTypes
