import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


export interface FAQPageProps {
  className?: string;
  showBottomPart?: boolean;
}

const FAQPage: FC<FAQPageProps> = ({
  className,
}) => {
  const navigate = useNavigate();

  const ids = ["1", "2", "3", "4", "5"];

  const initialState = Object.fromEntries(ids.map((id) => [id, 0]));
  const [open, setOpen] = useState<{ [key: string]: number }>(initialState);

  const toggleOpen = (id: string) => {
    setOpen((prevState) => ({
      ...prevState,
      [id]: prevState[id] ? 0 : 1,
    }));
  };

  const isDivToggled1 = open["1"] === 1;
  const isDivToggled2 = open["2"] === 1;
  const isDivToggled3 = open["3"] === 1;
  const isDivToggled4 = open["4"] === 1;
  const isDivToggled5 = open["5"] === 1;
  const isDivToggled6 = open["6"] === 1;
  const isDivToggled7 = open["7"] === 1;

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Često postavljana pitanja</title>
        <meta property="og:title" content="Često postavljana pitanja"></meta>
      </Helmet>
      <div className="container relative">
        <header className="text-center max-w-2xl mx-auto my-10">
          <h2 className="flex items-center text-4xl mb-4 leading-[115%] md:leading-[115%] font-semibold text-neutral-900 justify-center">
            Često postavljana pitanja
          </h2>
          <span className="block text-sm mt-8 text-neutral-700 sm:text-base">
            Ovde možete pronaći pitanja koje nam vozači najčešće postavljaju.
          </span>
        </header>
        <div
          className={`nc-SectionGridFilterCard mt-6`}
          data-nc-id="SectionGridFilterCard"
        >
          <div className="lg:p-10 lg:bg-neutral-50  grid grid-cols-1 gap-6  rounded-3xl">
            {/* Pitanje i odgovor br. 1 */}

            <div
              onClick={() => toggleOpen("1")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  {/* LOGO IMG */}

                  {/* FOR MOBILE RESPONSIVE */}
                  <div className="overflow-wrap w-4/5 mb-4 font-bold">
                    <span>Koliko košta korišćenje E-Knjižice?</span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center cursor-pointer ${
                        isDivToggled1 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled1 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"1"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Za vlasnike vozila je E-Knjižica <u>besplatna</u>. Naši
                      prihodi stižu od auto servisa koji prihoduju na različite
                      načine korišćenjem E-Knjižica platforme.
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/* Pitanje i odgovor br. 2 */}

            <div
              onClick={() => toggleOpen("2")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap mb-4 w-4/5 font-bold">
                    <span>Zašto bi trebalo da koristim E-Knjižicu?</span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled2 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled2 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"2"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Da biste imali jednostavan pristup servisnoj istoriji
                      vozila, podsetnicima i troškovima. Benefit ćete imati i
                      prilikom prodaje vozila, gde uredna servisna knjižica može
                      samo da podigne vrednost vozila.
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/*Pitanje i odgovor br. 3  */}

            <div
              onClick={() => toggleOpen("3")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap mb-4 w-4/5 font-bold">
                    <span>
                      Za koje vrste i brendove vozila se može koristiti
                      E-Knjižica?
                    </span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled3 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled3 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"3"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Može se koristiti za sve vrste (putnička, teretna,
                      motocikli, radne mašine, poljoprivredne mašine, specijalna
                      vozila...) i sve brendove vozila. Trenutno je E-Knjižica
                      najviše prilagođena putničkim i lakim teretnim vozilima.{" "}
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/*Pitanje i odgovor br. 4  */}

            <div
              onClick={() => toggleOpen("4")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap mb-4 w-4/5 font-bold">
                    <span>Kako da počnem sa korišćenjem E-Knjižice?</span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled4 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled4 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"4"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Preuzmite našu mobilnu aplikaciju, registrujte se kao
                      vozač, a zatim unesite podatke svog vozila i registrujte
                      ga na platformi. Ili posetite neki od autorizovanih servisa iz našeg adresara i zatražite otvaranje servisne knjižice.
                      Samo registrovani vozači mogu koristiti sve pogodnosti E-Knjižica platforme.
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/*Pitanje i odgovor br. 5  */}

            <div
              onClick={() => toggleOpen("5")}
              className={`nc-FlightCardgroup p-4 cursor-pointer sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div
                  className="flex 
                 flex-row sm:items-center space-y-6 sm:space-y-0"
                >
                  <div className="overflow-wrap w-4/5 mb-4 font-bold">
                    <span>Kako da pregledam servisnu istoriju mog vozila?</span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled5 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled5 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"5"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Nakon registracije vlasnika i vozila, imaćete uvid u sve
                      servisne zapise koje su kreirali auto servisi ili
                      prethodni vlasnici vozila, kao i zapise koje ste kreirali
                      samostalno. Možete kreirati i servisne zapise iz papirne
                      servisne knjižice tako što ćete uz pisane podatke
                      priložiti i fotografije papirnih dokumenata.
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/*Pitanje i odgovor br. 6  */}

            <div
              onClick={() => toggleOpen("6")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap w-4/5 mb-4 font-bold">
                    <span>
                      Moj auto ima popunjenu papirnu servisnu knjižicu, kako da
                      pređem na E-Knjižicu?
                    </span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled6 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled6 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"6"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Možete digitalizovati svoju servisnu knjižicu tako što
                      ćete u E-Knjižica mobilnoj aplikaciji kreirati servisne
                      zapise sa fotografijama papirne dokumentacije. Servisni
                      zapisi sadrže datum servisiranja, kilometražu, opis i
                      fotografije. Sve naredne zapise možete direktno unositi u
                      E-Knjižicu ili zatražiti od svog servisera da ih upiše u
                      E-Knjižicu.
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/*Pitanje i odgovor br. 7  */}

            <div
              onClick={() => toggleOpen("7")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap w-4/5 mb-4 font-bold">
                    <span>
                      Da li moj serviser može da popunjava E-Knjižicu umesto
                      papirne knjižice?
                    </span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled7 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled7 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"7"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Može, naravno. E-Knjižica je dostupna svim legalnim auto
                      servisima (pravnim licima), koji su registrovani i
                      verifikovani od strane platforme.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="my-10 ">
            <h3 className="text-xl font-semibold my-6 ">
              Da li smo odgovorili na vaša pitanja?
            </h3>
            <span>
              {" "}
              Ukoliko niste pronašli odgovore, rado ćemo pomoći. Pošaljite
              pitanje putem{" "}
              <a
                className="cursor-pointer"
                onClick={() => navigate("/kontakt")}
                style={{
                  color: "blue",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                kontakt
              </a>{" "}
              stranice
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
