import { IconButton } from "@mui/material";
import { Business } from "data/types";
import { FC } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BusinessAboutUs: FC<{
  data: Business;
  oNamaSectionRef: any;
  toggleBody: any;
  open: boolean;
}> = ({ data, oNamaSectionRef, toggleBody, open }) => {
  return (
    <div className="listingSection__wrap_column">
      <h2
        ref={oNamaSectionRef}
        className="text-2xl font-semibold"
        onClick={() => toggleBody()}
      >
        <span className=" block font-bold p-2 pl-3 rounded-lg   mt-2 flex items-center justify-between ">
          O nama
          <IconButton>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </span>
      </h2>
      {open && <div className="w-20 ml-3 border-b border-neutral-300 "></div>}
      {open && (
        <div className="text-neutral-6000 ml-3 ">{data?.description}</div>
      )}
    </div>
  );
};

export default BusinessAboutUs;
