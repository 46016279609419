let refreshTokenAction;

function setRefreshTokenAction(action) {
  refreshTokenAction = action;
}

async function executeRefreshTokenAction() {
  return refreshTokenAction && (await refreshTokenAction());
}

export default {
  setRefreshTokenAction,
  executeRefreshTokenAction,
};
