const initialState = {
  isService: false, // Initial value
  serviceTypes: [] as ServiceTypesArray[],
};

export interface ServiceState {
  isService: boolean;
  serviceTypes: ServiceTypesArray[];
}

export interface ServiceTypesArray {
  id: number;
  name: string;
  level: number;
  service_ID: number;
  logourl: string;
}

const serviceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "TOGGLE_SERVICE":
      return {
        ...state,
        isService: !state.isService,
      };
    case "SET_SERVICE_TYPES":
      return {
        ...state,
        serviceTypes: action.serviceTypes,
      };
    default:
      return state;
  }
};

export default serviceReducer;
