import { IconButton } from "@mui/material";
import { BusinessAmenities } from "data/types";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EServiceBookFetchService from "../../../services/Endpoints/EserviceBook/EServiceBookFetchService";
import { FC } from "react";

const BusinessProfileAmenities: FC<{
  amenities: BusinessAmenities[];
  openAmenities: boolean;
  toggleBody: () => void;
}> = ({ amenities, openAmenities, toggleBody }) => {
  return (
    <div className="listingSection__wrap_column">
      <h2 className="text-2xl font-semibold" onClick={() => toggleBody()}>
        <span className=" block font-bold p-2 pl-3 rounded-lg   mt-2 flex items-center justify-between ">
          Info auto servisa
          <IconButton>
            {openAmenities ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </span>
      </h2>
      {openAmenities && (
        <div className="w-20  border-b border-neutral-300 "></div>
      )}
      {openAmenities && (
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700  ">
          {amenities.map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              {item.logo && (
                <img
                  className="inset-0 max-w-[30px] max-h-[30px] object-cover rounded-md sm:rounded-xl "
                  //@ts-ignore
                  src={EServiceBookFetchService.imageUrl(item.logo).uri || ""}
                  alt=""
                  sizes="30px"
                />
              )}
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BusinessProfileAmenities;
