import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import GallerySlider from "components/GallerySlider";
import { Business } from "data/types";
import { FC, useEffect, useState } from "react";
import EServiceBookFetchService from "services/Endpoints/EserviceBook/EServiceBookFetchService";
import defaultProfile from "../../../src/shared/Logo/default-profile.png";
import StartRating from "components/StartRating";
import I18nHelper from "I18n/I18nHelper";

interface GoogleMapsProps {
  data: Business;
  handleMarkerClick: (marker: any) => void;
  selectedMarker: Business | null | undefined;
  handleInfoBoxClose: () => void;
  serviceTypeNames: string[];
}

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const mapOptions = {
  mapTypeControl: false, // Ensure "Map" and "Satellite" options are off
  streetViewControl: false, // Disable Street View controls
  zoomControl: true, // Ensure zoom controls are enabled
};

const renderSliderGallery = (data: Business) => {
  const imageURLS = data?.profileImages.map((x) =>
    EServiceBookFetchService.imageUrl(x.url)
  );

  return (
    <div className="relative  rounded-2xl overflow-hidden will-change-transform">
      <GallerySlider
        uniqueID={`ExperiencesCard_${data.id}`}
        ratioClass="aspect-w-16 aspect-h-9"
        galleryImgs={
          !!imageURLS.length
            ? imageURLS.map((x: any) => x.uri)
            : [defaultProfile]
        }
        href={`/profil-servisa/${data?.id}/${data.slug}`}
      />
    </div>
  );
};

export const GoogleMaps: FC<GoogleMapsProps> = ({
  data,
  handleMarkerClick,
  selectedMarker,
  serviceTypeNames,
  handleInfoBoxClose,
}) => {
  const [map, setMap] = useState<any>();
  const [googleMapsApiLoaded, setGoogleMapsApiLoaded] = useState(false);

  useEffect(() => {
    if (window.google && window.google.maps) {
      setGoogleMapsApiLoaded(true);
    } else {
      const interval = setInterval(() => {
        if (window.google && window.google.maps) {
          setGoogleMapsApiLoaded(true);
          clearInterval(interval);
        }
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (!map || !data) return;

    const bounds = new window.google.maps.LatLngBounds();
    if (data && data.latitude && data.longitude) {
      bounds.extend({ lat: data.latitude, lng: data.longitude });
    }
    //@ts-ignore
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
    google.maps.event.addListenerOnce(map, "bounds_changed", function () {
      map.setZoom(19);
    });
  }, [map, data]);

  return (
    <div className="rounded-xl overflow-hidden z-0">
      {googleMapsApiLoaded && data.latitude && data.longitude && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={(map) => setMap(map)}
          options={mapOptions}
        >
          <Marker
            onClick={() => handleMarkerClick(data)}
            position={{ lat: data.latitude, lng: data.longitude }}
          />
          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker.latitude,
                lng: selectedMarker.longitude,
              }}
              onCloseClick={handleInfoBoxClose}
            >
              <div className="space-y-2 w-[230px]">
                {renderSliderGallery(data)}
                <div className="text-lg font-bold ">
                  <span>{data?.name}</span>
                </div>
                <div className="flex justify-between items-end">
                  <StartRating
                    reviewCount={data?.ratingCount}
                    point={data?.ratingAvg}
                  />
                </div>
                <div className="flex flex-row flex-wrap space-x-3">
                  {serviceTypeNames &&
                    serviceTypeNames.map((item) => (
                      <div
                        key={item}
                        className="flex flex-row space-x-3 items-center"
                      >
                        <span>{I18nHelper.getText(item)}</span>
                      </div>
                    ))}
                </div>
                <div>
                  <span>{`${data?.address}, ${data?.city} `}</span>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
};
