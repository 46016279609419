import StartRating from "components/StartRating";
import { Business } from "data/types";
import { FC } from "react";
import EServiceBookFetchService from "../../../services/Endpoints/EserviceBook/EServiceBookFetchService";
//"services/Endpoints/EserviceBook/EServiceBookFetchService";
interface BusinessNameProps {
  data: Business;
  handleUslugaRefClick: any;
  handleONamaRefClick: any;
  handleCenovnikRefClick: any;
  handleOcenaRefClick: any;
  handleAddressRefClick: () => void;
  logo: any;
}

const BusinessName: FC<BusinessNameProps> = ({
  data,
  handleUslugaRefClick,
  handleONamaRefClick,
  handleCenovnikRefClick,
  handleOcenaRefClick,
  handleAddressRefClick,
  logo,
}) => {
  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }
  const isMobile = isMobileView();
  return (
    <div className="listingSection__wrap_column !space-y-6">
      <div className="flex justify-between ">
        <div className="self-end pb-2">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {data?.name}
          </h2>
          {data?.subtitle && (
            <p className="mb-4 mt-1  italic">{data?.subtitle}</p>
          )}
          <div className="flex items-center mt-2">
            {!!data?.ratingCount && (
              <StartRating
                blueBackground={true}
                point={data?.ratingAvg}
                reviewCount={data?.ratingCount}
              />
            )}
          </div>
        </div>
        {logo && (
          <div>
            <img
              className={
                isMobile
                  ? "object-contain rounded-md w-[150px] h-auto"
                  : "object-contain rounded-md w-[200px] h-auto"
              }
              //@ts-ignore
              src={EServiceBookFetchService.imageUrl(logo.url).uri}
              alt=""
            />
          </div>
        )}

        {/* <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={
               
                ) || ""
              }
              alt=""
              sizes="400px"
            />
          </div> */}
      </div>
      <div className="w-full border-b border-neutral-200"></div>
      <div className="flex items-center justify-between xl:justify-start space-x-2 xl:space-x-8 text-xs text-neutral-700 ">
        {data.description && (
          <div
            onClick={handleONamaRefClick}
            className="flex flex-col items-center sm:flex-row sm:space-x-2 space-y-1 sm:space-y-0 cursor-pointer"
          >
            <i className="las la-info-circle text-2xl"></i>
            <span className="sm:inline-block">O nama</span>
          </div>
        )}
        {!!data.address && (
          <div
            onClick={handleAddressRefClick}
            className="flex flex-col items-center sm:flex-row sm:space-x-2 space-y-1 sm:space-y-0 cursor-pointer"
          >
            <i className="las la-map-pin text-2xl"></i>
            <span className="sm:inline-block">Lokacija</span>
          </div>
        )}
        {!!data.bServices.length && data.pricelistEnabled && (
          <div
            onClick={handleCenovnikRefClick}
            className="flex flex-col items-center sm:flex-row sm:space-x-2 space-y-1 sm:space-y-0 cursor-pointer"
          >
            <i className="las la-file-invoice-dollar text-2xl"></i>
            <span className="sm:inline-block">Cenovnik</span>
          </div>
        )}
        {!!data.ratingCount && (
          <div
            onClick={handleOcenaRefClick}
            className="flex flex-col items-center sm:flex-row sm:space-x-2 space-y-1 sm:space-y-0 cursor-pointer"
          >
            <i className="las la-star-half-alt text-2xl"></i>
            <span className="sm:inline-block">Ocene</span>
          </div>
        )}
        {!!data.bServices.length && (
          <div
            onClick={handleUslugaRefClick}
            className="flex flex-col items-center sm:flex-row sm:space-x-2 space-y-1 sm:space-y-0 cursor-pointer"
          >
            <i className="las la-cogs text-2xl"></i>
            <span className="sm:inline-block">Sve usluge</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessName;
