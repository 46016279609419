import { ACCENTS_MAP } from "./AppConstants";

export function setDictionaryState(state, stateKey, dictKeyId, newItems) {
  const response = Object.assign({}, state, {
    [stateKey]: { ...state[stateKey], [dictKeyId]: newItems },
  });
  return response;
}

export function getFromDict(dict, keyId, defValue) {
  return dict && keyId in dict ? dict[keyId] : defValue;
}

export const removeAccents = (text) =>
  Object.keys(ACCENTS_MAP ? ACCENTS_MAP : []).reduce(
    (acc, cv) => acc.replace(new RegExp(ACCENTS_MAP[cv], "gi"), cv),
    text
  );
