import ActionTypes from 'actions/ActionTypes';

const initialState = {
  searchResults: [],
  allResultsCount: 0,
};

function businessSearchReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_BUSINESS_SEARCH_RESULTS_ACTION:
      return {
        ...state,
        searchResults: action.searchResults,
        allResultsCount: action.allResultsCount,
      };
    case ActionTypes.APPEND_BUSINESS_SEARCH_RESULTS_ACTION:
      return {
        ...state,
        searchResults: [...state.searchResults, ...action.searchResults],
        allResultsCount: action.allResultsCount,
      };
    case ActionTypes.CLEAR_BUSINESS_SEARCH_RESULTS_ACTION:
      return {
        ...state,
        searchResults: [],
        allResultsCount: 0,
      };
    case ActionTypes.REPLACE_BUSINESS_SEARCH_RESULT_ACTION: {
      const indexOfItemToReplace = state.searchResults.findIndex(
        (x) => x.id === action.searchResult.id
      );
      if (indexOfItemToReplace === -1) {
        return state;
      }

      return {
        ...state,
        searchResults: state.searchResults.map((item, index) =>
          index === indexOfItemToReplace ? action.searchResult : item
        ),
      };
    }
    default:
      return state;
  }
}

export default businessSearchReducer;
