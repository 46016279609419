import React, { useState } from "react";
import { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/newsletter.jpg";
import successImg from "images/newsletter_640.png";
import NcImage from "shared/NcImage/NcImage";
import Input from "shared/Input/Input";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import * as Yup from "yup";
import { subscribe } from "services/Endpoints/EserviceBook/SubscriberEndpoint";

export interface SubscribeProps {
  className?: string;
}

const formValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email je obavezan")
    .test(
      "valid-email",
      "Unesite validan email  (npr. validanMail@gmail.com)",
      function (value) {
        if (!value) return true;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(value);
      }
    ),
  personNewsletter: Yup.boolean(),
  companyNewsletter: Yup.boolean(),
  customNewsletterCheck: Yup.boolean().test(
    "at-least-one-checked",
    "Morate odabrati barem jednu opciju za newsletter",
    function (value) {
      const { personNewsletter, companyNewsletter } = this.parent;
      return personNewsletter || companyNewsletter;
    }
  ),
});

const Subscribe: FC<SubscribeProps> = ({ className = "" }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    personNewsletter: false,
    companyNewsletter: false,
    isAndroid: true,
  });
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    customNewsletterCheck: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (type === "checkbox") {
      setErrorMessage({ ...errorMessage, customNewsletterCheck: "" });
    }
    if (type === "email") {
      setErrorMessage({ ...errorMessage, email: "" });
    }
  };

  const subscribeToNewsLetter = async (formData: any) => {
    const requestData = {
      Email: formData.email,
      PersonNewsletter: formData.personNewsletter,
      companyNewsletter: formData.companyNewsletter,
      IsAndroid: formData.isAndroid ? 1 : 0,
    };
    try {
      const response = await subscribe({ ...requestData });
      if (!response.ok) {
        throw new Error("response not ok");
      }
      setIsSubmitted(true);
      const data = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const validateForm = async () => {
    try {
      await formValidationSchema.validate(formData, { abortEarly: false });
      setErrorMessage({
        email: "",
        customNewsletterCheck: "",
      });
      return true;
    } catch (validationErrors) {
      //@ts-ignore
      const newErrors = validationErrors.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      setErrorMessage(newErrors);
      return false;
    }
  };

  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  // Example usage:
  const isMobile = isMobileView();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const valid = await validateForm();
    if (valid) {
      subscribeToNewsLetter(formData);
      setFormData({
        email: "",
        personNewsletter: false,
        companyNewsletter: false,
        isAndroid: false,
      });
      setTimeout(() => {
        setIsSubmitted(false);
        setOpen(false);
      }, 3000);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      email: "",
      personNewsletter: false,
      companyNewsletter: false,
      isAndroid: false,
    });
    setErrorMessage({
      email: "",
      customNewsletterCheck: "",
    });
    setOpen(false);
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div
        onClick={handleOpen}
        className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5"
      >
        <h2 className="font-semibold text-4xl">Prijavite se na newsletter</h2>
        <span className="block mt-5 text-neutral-500">
          Budite u toku sa novostima na E-Knjižica platformi.
        </span>
        <form className="mt-10 relative max-w-sm">
          <Input
            readOnly
            aria-required
            placeholder="Kliknite da se prijavite"
            rounded="rounded-full"
            name="email"
          />
          <ButtonCircle
            type="button"
            className="absolute transform top-1/2 bg-sky-400 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage containerClassName={"h-full"} src={rightImg} />
      </div>

      <Dialog
        PaperProps={{
          sx: {
            width: { xs: "100vw", sm: "600px" }, // Full width on mobile, 600px on larger screens
            height: { xs: "100vh", sm: "auto" }, // Full height on mobile, auto on larger screens
            maxWidth: "100%", // Ensures the dialog doesn't exceed the screen width
            maxHeight: "100%", // Ensures the dialog doesn't exceed the screen height
            margin: isMobile ? "0" : "",
          },
        }}
        open={open}
        onClose={() => handleClose()}
        disablePortal
      >
        {!isSubmitted && (
          <DialogTitle className="text-center !mb-4 !text-2xl">
            Prijavite se na newsletter
          </DialogTitle>
        )}
        <DialogContent>
          {isSubmitted ? (
            <div className="grid">
              <div className="font-bold my-12 text-4xl text-center">
                Uspešno ste se prijavili
              </div>
              {successImg && (
                <div className={`flex-grow `}>
                  <NcImage
                    containerClassName={"h-full p-12"}
                    src={successImg}
                  />
                </div>
              )}
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <label className="!font-semibold text-sm">Email</label>
              <input
                className={`w-full rounded-none p-4 ${
                  errorMessage.email ? "border-red-500" : ""
                }`}
                id="email"
                name="email"
                type="email"
                value={formData.email}
                placeholder="Email"
                onChange={handleInputChange}
              />
              {errorMessage.email && (
                <p className="text-red-500">{errorMessage.email}</p>
              )}
              <Typography className="!font-semibold !mt-12">
                Kako biste koristili E-Knjižicu?
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: errorMessage.customNewsletterCheck
                        ? "red"
                        : "inherit",
                    }}
                    name="personNewsletter"
                    checked={formData.personNewsletter}
                    onChange={handleInputChange}
                  />
                }
                label="Za privatno vozilo"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: errorMessage.customNewsletterCheck
                        ? "red"
                        : "inherit",
                    }}
                    name="companyNewsletter"
                    checked={formData.companyNewsletter}
                    onChange={handleInputChange}
                  />
                }
                label="Za vozni park firme"
              />
              {errorMessage.customNewsletterCheck && (
                <p className="text-red-500">
                  {errorMessage.customNewsletterCheck}
                </p>
              )}
              <Typography className="!font-semibold !mt-12">
                Odaberite uređaj koji koristite.
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="isAndroid"
                  value={formData.isAndroid}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Android"
                    value={false}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="iPhone"
                    value={true}
                  />
                </RadioGroup>
              </FormControl>
            </form>
          )}
        </DialogContent>
        {!isSubmitted && (
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Otkaži
            </Button>
            <Button type="submit" onClick={handleSubmit} color="primary">
              Prijavi se
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default Subscribe;
