import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import StartRating from "components/StartRating";
import { FC, useEffect, useState } from "react";
import Badge from "shared/Badge/Badge";
import { Business } from "data/types";
import EServiceBookFetchService from "services/Endpoints/EserviceBook/EServiceBookFetchService";
import GallerySlider from "components/GallerySlider";
import defaultProfile from "../../shared/Logo/default-profile.png";
import { useNavigate } from "react-router-dom";
interface GoogleMapsProps {
  data: Business[];
  currentHoverID: string | number;
  handleMarkerClick: (marker: any) => void;
  selectedMarker: Business | null | undefined;
  handleInfoBoxClose: () => void;
  serviceTypeNames: string[];
  driversCoords: any;
}

function isMobileView() {
  return window.innerWidth <= 768; // Adjust the breakpoint as needed
}

// Example usage:
const isMobile = isMobileView();

const mapContainerStyle = {
  width: "100%",
  height: isMobile ? "100vh" : "70vh", // Use 100vh to make the map fill the entire viewport height
};

const mapOptions = {
  mapTypeControl: false, // Ensure "Map" and "Satellite" options are off
  streetViewControl: false, // Disable Street View controls
  zoomControl: true, // Ensure zoom controls are enabled
  clickableIcons: false, // Disable clickable icons
};
const renderSliderGallery = (data: Business) => {
  const profilePictures = data?.profileImages ? [...data.profileImages] : [];

  const index = profilePictures.findIndex((item) => item.isLogo);
  if (index !== -1) {
    const [logoImage] = profilePictures.splice(index, 1);
    profilePictures.unshift(logoImage);
  }

  const imageURLS =
    profilePictures &&
    profilePictures.map((x) => EServiceBookFetchService.imageUrl(x.url));

  return (
    <div className="relative  rounded-2xl overflow-hidden will-change-transform">
      <GallerySlider
        uniqueID={`ExperiencesCard_${data.id}`}
        ratioClass="aspect-w-16 aspect-h-9"
        galleryImgs={
          !!imageURLS.length
            ? imageURLS.map((x: any) => x.uri)
            : [defaultProfile]
        }
        href={`/profil-servisa/${data?.id}/${data.slug}`}
      />
    </div>
  );
};

export const AddressBookMap: FC<GoogleMapsProps> = ({
  data,
  currentHoverID,
  handleMarkerClick,
  selectedMarker,
  handleInfoBoxClose,
  serviceTypeNames,
  driversCoords,
}) => {
  const navigate = useNavigate();
  const [map, setMap] = useState<any>();

  useEffect(() => {
    if (!map || !data || data.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    if (data.length > 0) {
      data.forEach((item) => {
        bounds.extend({ lat: item.latitude, lng: item.longitude });
      });
    }
    //@ts-ignore
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  }, [map, data]);

  const maxItemsToShow = 5;
  const truncated = serviceTypeNames.length > maxItemsToShow;

  return (
    <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full rounded-md overflow-hidden">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        onLoad={(map) => setMap(map)}
        options={mapOptions}
      >
        {data &&
          data.map((item) => (
            <Marker
              animation={currentHoverID === item.id ? 1 : 0}
              key={item.id}
              onClick={() => handleMarkerClick(item)}
              position={{ lat: item.latitude, lng: item.longitude }}
            />
          ))}
        {driversCoords && (
          <Marker
            position={{
              lat: driversCoords.latitude,
              lng: driversCoords.longitude,
            }}
            label={{
              text: "Vaša lokacija",
              fontSize: "13px",
              fontWeight: "bold",
              className: "custom-marker-label",
            }}
            icon={{
              url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Use a different color icon
              scaledSize: new google.maps.Size(55, 55),
              labelOrigin: new google.maps.Point(32, -30), // Adjust to position the label above the marker
            }}
          />
        )}
        {selectedMarker && currentHoverID && (
          <InfoWindow
            position={{
              lat: selectedMarker.latitude,
              lng: selectedMarker.longitude,
            }}
            onCloseClick={handleInfoBoxClose}
          >
            <div className="space-y-1 w-[230px]">
              {renderSliderGallery(selectedMarker)}
              <div
                className=" font-bold cursor-pointer"
                onClick={() =>
                  navigate(`/profil-servisa/${selectedMarker?.id}/${selectedMarker?.slug}`)
                }
              >
                <span>{selectedMarker?.name}</span>
              </div>
              <div className="flex justify-between items-end">
                <StartRating
                  reviewCount={selectedMarker?.ratingCount}
                  point={selectedMarker?.ratingAvg}
                />
              </div>
              <div className="flex flex-row flex-wrap space-x-1">
                {serviceTypeNames &&
                  serviceTypeNames
                    .slice(0, maxItemsToShow)
                    .map((item, index) => (
                      <div
                        style={{ fontSize: "10px" }}
                        key={index}
                        className="flex flex-row pb-1 te  space-x-1 items-center"
                      >
                        <Badge color="white" name={item}></Badge>
                      </div>
                    ))}
                {truncated && (
                  <div className="flex flex-row pb-1 space-x-3 items-center">
                    {/* Render your truncate indicator here, e.g., "..." */}
                    <span></span>
                  </div>
                )}
              </div>
              <div className="">
                <span>{`${selectedMarker?.address}, ${selectedMarker?.city} `}</span>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};
