import { FC, LegacyRef, useState } from "react";
import { GoogleMaps } from "../GoogleMaps";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Business } from "data/types";

const BussinessMap: FC<{
  mapSectionRef: any;
  data: Business;
  naziviUsluga: string[];
}> = ({ mapSectionRef, data, naziviUsluga }) => {
  const [selectedMarker, setSelectedMarker] = useState<Business | null>(null);

  const handleMarkerClick = (marker: any) => {
    setSelectedMarker(marker);
  };

  const handleInfoBoxClose = () => {
    setSelectedMarker(null);
  };

  return !data.address && !data.longitude && !data.latitude ? (
    <></>
  ) : (
    <div className="listingSection__wrap_column ">
      <h2 className="text-2xl pl-3  font-semibold">Lokacija</h2>
      <div className="w-20 ml-3  border-b border-neutral-300 "></div>
      {data?.address && (
        <div className="cursor-pointer">
          <LocationOnIcon className="mr-2" />
          <span className="text-wrap">{`${data?.address}, ${data?.city}`}</span>
        </div>
      )}
      {data.longitude && data.latitude && (
        <div
          ref={mapSectionRef}
          className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 h-full ring-black/10 rounded-xl min-h-[600px] z-0"
        >
          <GoogleMaps
            data={data}
            handleMarkerClick={handleMarkerClick}
            selectedMarker={selectedMarker}
            handleInfoBoxClose={handleInfoBoxClose}
            serviceTypeNames={naziviUsluga ? naziviUsluga : []}
          />
        </div>
      )}
    </div>
  );
};

export default BussinessMap;
