import { IconButton } from "@mui/material";
import { FC } from "react";
import { ServiceTypesArray } from "reducers/serviceReducer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BusinessAllServicing: FC<{
  uslugaSectionRef: any;
  open: boolean;
  sekcije2: {
    header: ServiceTypesArray;
    items: ServiceTypesArray[];
  }[];
  toggleBody: () => void;
  toggleSection: (sectionId: number) => void;
  expandedSections: any;
}> = ({
  uslugaSectionRef,
  open,
  sekcije2,
  toggleBody,
  toggleSection,
  expandedSections,
}) => {
  return (
    <div className="listingSection__wrap_column">
      <div
        ref={uslugaSectionRef}
        className="block flex-row gap-4 text-md text-neutral-700  mx-2 my-4"
      >
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold ">Sve usluge servisa </h2>
          <IconButton onClick={() => toggleBody()}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        {open && (
          <div className="w-20 mt-5  border-b border-neutral-300 "></div>
        )}
        {open &&
          sekcije2.map((section) => {
            const isExpanded = expandedSections[section.header.id] || false;
            return (
              <div key={section.header.id} className="my-6 ">
                <h4
                  className="font-semibold"
                  onClick={() => toggleSection(section.header.id)}
                >
                  <span className=" block font-bold px-2 pl-0  rounded-lg flex items-center ">
                    {section.header.name}{" "}
                    <IconButton>
                      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </span>
                </h4>
                {isExpanded && (
                  <ul>
                    <div
                      className={`text-sm   text-neutral-6000 rounded-lg  -mb-4`}
                    >
                      {section.items.map((item, index) => (
                        <li key={item.id}>
                          <div
                            className={`p-4 ${
                              index % 2 === 0 ? "bg-neutral-100" : ""
                            } flex justify-between items-center space-x-4 rounded-lg`}
                          >
                            <span>{item.name}</span>
                          </div>
                        </li>
                      ))}
                    </div>
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BusinessAllServicing;
