import EServiceBookFetchService from "../EserviceBook/EServiceBookFetchService";
import { AUTHORIZATION_HEADER_TYPE } from "../../FetchService";

const FetchService = EServiceBookFetchService;
const endpointPrefix = FetchService.apiVersion
  ? `/api/web/v${FetchService.apiVersion}/subscriber`
  : "/api/subscriber";

export const subscribe = (data) => {
  return FetchService.executePost({
    endpoint: `${endpointPrefix}/SubscribeToNewsletter`,
    data,
    headerOptions: {
      authorizationHeaderType: AUTHORIZATION_HEADER_TYPE.BEARER,
    },
  });
};
