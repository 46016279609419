import { useSelector } from "react-redux";
import NavigationItem from "./NavigationItem";
import { driverMenu, serviceMenu } from "data/navigation";
import { RootState } from "reducers";

function Navigation() {
  
  function isMobileView() {
    return window.innerWidth <= 768;
  }

  const isMobile = isMobileView();
  const isService = useSelector((state: RootState) => state.service.isService);
  return (
    <ul
      className={`nc-Navigation ${
        isMobile ? "hidden" : ""
      } flex md:lg:flex-wrap sm:md:lg:items-center lg:space-x-1 relative `}
    >
      {isService
        ? serviceMenu.map((item) => (
            <NavigationItem key={item.id} menuItem={item} />
          ))
        : driverMenu.map((item) => (
            <NavigationItem key={item.id} menuItem={item} />
          ))}
    </ul>
  );
}

export default Navigation;
