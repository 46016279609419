import AppConfig from "config/AppConfig";
import { OLD_FETCH_ARRIVED_HACK_CONST } from "utils/AppConstants";
import { convertParamsToUrlSearchString } from "utils/GeneralUtils";

const SHOW_REQUEST_LOGS = false;

export const AUTHORIZATION_HEADER_TYPE = Object.freeze({
  BASIC: "Basic",
  BEARER: "Bearer",
});

const getAuthHeader = {
  [AUTHORIZATION_HEADER_TYPE.BASIC]: () =>
    `Basic ${AppConfig.basicAuthHeaderContent}`,
  [AUTHORIZATION_HEADER_TYPE.BEARER]: () => {
    const accessToken = undefined;
    return accessToken ? `Bearer ${accessToken}` : undefined;
  },
};

let getStateFunc;
const fetchTimeout = AppConfig.fetchTimeout;

function getBaseHeaders({
  authorizationHeaderType = AUTHORIZATION_HEADER_TYPE.BASIC,
  contentType = "application/json",
  ...rest
} = {}) {
  return {
    Accept: "application/json",
    "Content-Type": contentType,
    Authorization: getAuthHeader[authorizationHeaderType](),
    ...rest,
  };
}

function getFullUrl(baseEndPointUrl, endpoint, urlParameters) {
  const url = baseEndPointUrl + endpoint;
  if (!urlParameters || typeof urlParameters !== "object") {
    return url;
  }

  const searchString = convertParamsToUrlSearchString(urlParameters);
  if (!searchString) {
    return url;
  }
  return `${url}?${searchString}`;
}

/**
 * Initialize fetch service
 * @param {Function} getStateFuncParam store getState function
 */
function initialize(getStateFuncParam) {
  getStateFunc = getStateFuncParam;
}

function timeoutFetch(url, fetchOptions) {
  return fetchTimeout
    ? Promise.race([
        fetch(url, fetchOptions),
        new Promise((resolve) =>
          setTimeout(() => resolve(new Error("timeout")), fetchTimeout)
        ),
      ])
    : fetch(url, fetchOptions);
}

async function customFetch(url, fetchOptions) {
  // there is a possibility that between starting and finishing the fetch, user logs in with another account.
  // we should skip responses which were made by previous user
  //const state1 = getStateFunc();
  //const userId1 = (state1.user.auth.userData && state1.user.auth.userData.id) || 0
  const userId1 = 0;

  const response = await timeoutFetch(url, fetchOptions);
  // const state2 = getStateFunc()
  //const userId2 = (state2.user.auth.userData && state2.user.auth.userData.id) || 0
  const userId2 = 0;

  if (userId1 !== userId2) {
    console.log(
      `Fetch call sent by user ${userId1} but received for ${userId2}. url: ${url}`
    );
    return {
      status: OLD_FETCH_ARRIVED_HACK_CONST,
    };
  }
  SHOW_REQUEST_LOGS && console.log("response: ", response);
  return response;
}
/**
 * Absolute url to resource file like image etc
 * @param {string} resourcePath relative path to resource
 * @param {string} defaultResource default resource if resourcePath is null or not defined
 * @return {string} resourcePath url
 */
function resourceUrl(baseEndPointUrl, resourcePath, defaultResource = "") {
  if (resourcePath && resourcePath.length) {
    if (resourcePath.startsWith("http") || resourcePath.startsWith("data:")) {
      return resourcePath;
    }

    const path = resourcePath[0] !== "/" ? "/" + resourcePath : resourcePath;
    return getFullUrl(baseEndPointUrl, path);
  }
  return defaultResource;
}
/**
 * Creates object for source parameter of react native image component
 * @param {string} resourcePath relative path to resource
 * @param {string} defaultResource should be some asset from LocalImages
 * @return {object} resourcePath url
 */
function imageUrl(baseEndPointUrl, resourcePath, defaultAsset) {
  const pictureUrl = resourceUrl(baseEndPointUrl, resourcePath);
  return pictureUrl ? { uri: pictureUrl } : defaultAsset;
}
/**
 * Execute fetch post. Adds token if user is logged in
 * @param {string} endpoint
 * @param {Object} data what should we post in body. This object will be stringified
 * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
 * @return {Promise}
 */
function executePost(
  baseEndPointUrl,
  endpoint,
  data,
  urlParameters,
  headerOptions
) {
  const url = getFullUrl(baseEndPointUrl, endpoint, urlParameters);
  const body = data
    ? typeof data === "object"
      ? JSON.stringify(data)
      : data
    : null;
  SHOW_REQUEST_LOGS && console.log("post: " + url);
  const headers = getBaseHeaders(headerOptions);
  SHOW_REQUEST_LOGS && console.log("headers: ");
  SHOW_REQUEST_LOGS && console.log(headers);
  SHOW_REQUEST_LOGS && console.log("body: ", body);
  return customFetch(url, {
    method: "POST",
    headers,
    body,
  });
}
/**
 * Execute fetch get. Adds token if user is logged in
 * @param {string} endpoint
 * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
 * @return {Promise}
 */
function executeGet(baseEndPointUrl, endpoint, urlParameters, headerOptions) {
  const url = getFullUrl(baseEndPointUrl, endpoint, urlParameters);
  SHOW_REQUEST_LOGS && console.log("get: " + url);
  const headers = getBaseHeaders(headerOptions);
  SHOW_REQUEST_LOGS && console.log("headers: ");
  SHOW_REQUEST_LOGS && console.log(headers);
  return customFetch(url, {
    method: "GET",
    headers,
  });
}
/**
 * Execute fetch delete. Adds token if user is logged in
 * @param {string} endpoint
 * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
 * @return {Promise}
 */
function executeDelete(
  baseEndPointUrl,
  endpoint,
  data,
  urlParameters,
  headerOptions
) {
  const url = getFullUrl(baseEndPointUrl, endpoint, urlParameters);
  SHOW_REQUEST_LOGS && console.log("delete: " + url);
  const body = data
    ? typeof data === "object"
      ? JSON.stringify(data)
      : data
    : null;
  const headers = getBaseHeaders(headerOptions);
  SHOW_REQUEST_LOGS && console.log("headers: ");
  SHOW_REQUEST_LOGS && console.log(headers);
  SHOW_REQUEST_LOGS && console.log("body: ", body);
  return customFetch(url, {
    method: "DELETE",
    headers,
    body,
  });
}
/**
 * Execute fetch put. Adds token if user is logged in
 * @param {string} endpoint
 * @param {Object} data what should we put in body. This object will be stringified
 * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
 * @return {Promise}
 */
function executePut(
  baseEndPointUrl,
  endpoint,
  data,
  urlParameters,
  headerOptions
) {
  const url = getFullUrl(baseEndPointUrl, endpoint, urlParameters);
  const body = data
    ? typeof data === "object"
      ? JSON.stringify(data)
      : data
    : null;
  SHOW_REQUEST_LOGS && console.log("put: " + url);
  const headers = getBaseHeaders(headerOptions);
  SHOW_REQUEST_LOGS && console.log("headers: ");
  SHOW_REQUEST_LOGS && console.log(headers);
  SHOW_REQUEST_LOGS && console.log("body: ", body);
  return customFetch(url, {
    method: "PUT",
    headers,
    body,
  });
}

export function createFetchService({ urlBase, storageUrl, apiVersion }) {
  return {
    executeGet: ({ endpoint, params, headerOptions }) =>
      executeGet(urlBase, endpoint, params, headerOptions),
    executeDelete: ({ endpoint, data, params, headerOptions }) =>
      executeDelete(urlBase, endpoint, data, params, headerOptions),
    executePost: ({ endpoint, data, params, headerOptions }) =>
      executePost(urlBase, endpoint, data, params, headerOptions),
    executePut: ({ endpoint, data, params, headerOptions }) =>
      executePut(urlBase, endpoint, data, params, headerOptions),
    getFullUrl: (endpoint, params) => getFullUrl(urlBase, endpoint, params),
    imageUrl: (resourcePath, defaultAsset) =>
      imageUrl(storageUrl, resourcePath, defaultAsset),
    resourceUrl: (resourcePath, defaultResource) =>
      resourceUrl(storageUrl, resourcePath, defaultResource),
    apiVersion,
    urlBase,
  };
}

export default {
  initialize,
};
