import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/open.png";
import HIW2img from "images/work.png";
import HIW3img from "images/rate.png";
import VectorImg from "images/VectorHIW.svg";


export interface HowItWorksHomeProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: HowItWorksHomeProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Otvorite E-Knjižicu za svoje vozilo",
    desc: "koristeći mobilnu aplikaciju ili odlaskom u neki od autorizovanih auto servisa i tehničkih pregleda",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Evidentirajte radove ili prepustite drugima da to učine umesto Vas",
    desc: "auto servisi i tehnički pregledi će upisati svoje radove i fiskalne račune u E-Knjižicu umesto Vas.",
  },
  {
    id: 3,
    img: HIW3img,
      title: "Pregledajte radove, ocenite ih i budite savestan vozač",
      desc: "pomozite drugim vozačima da pronađu najbolji servis, ocenite radove u auto servisima i ostavite komentar.",
  },
];

const HowItWorksHome: FC<HowItWorksHomeProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork my-0  ${className}`}
      data-nc-id="SectionHowItWork"
      >
          <div className="text-center w-full max-w-2xl mx-auto">
              <h2 className="text-3xl md:text-4xl font-semibold">Kako funkcioniše?</h2>
              <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">Bez brige, vrlo je jednostavno!</span>
          </div>

      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName=" block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden  mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
              />
            )}
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorksHome;
