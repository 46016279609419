import { FC, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
  itemId?: string;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  // Example usage:
  const isMobile = isMobileView();

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === galleryImgs.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? galleryImgs.length - 1 : prevIndex - 1
    );
  };

  const renderDots = () => {
    return (
      <div className="flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5">
        {galleryImgs.map((_, i) => (
          <button
            key={i}
            className={`w-1.5 h-1.5 rounded-full ${
              currentImageIndex === i ? "bg-blue-500" : "bg-neutral-300"
            }`}
            onClick={() => setCurrentImageIndex(i)}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      <div className="relative group">
        <div
          className={`block aspect-w-5 ${
            isMobile ? "aspect-h-3" : "aspect-h-6"
          }`}
        >
          <NcImage
          
            src={galleryImgs[currentImageIndex]}
            containerClassName="max-h-[220px]"
          />
        </div>
        {galleryImgs.length !== 1 && (
          <div className="absolute opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 inset-x-2 flex justify-between">
            <NextPrev
              className="w-full justify-between"
              btnClassName="w-8 h-8"
              onClickPrev={goToPreviousImage}
              onClickNext={goToNextImage}
            />
          </div>
        )}
        {galleryImgs.length !== 1 && renderDots()}
      </div>
    </div>
  );
};

export default GallerySlider;
