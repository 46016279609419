export const MAX_NUMBER_OF_RECENT_SEARCHES = 5;

export const BUSINESS_PRICELIST_DEFAULT_GROUP =
  "BUSINESS_PRICELIST_DEFAULT_GROUP";

export const BUSINESS_SEARCH_TYPE = Object.freeze({
  BY_NAME: 0,
  BY_SERVICE_TYPE: 1,
  BY_MOT: 2,
  BY_LOCATION: 3,
});

export const DEFAULT_MOT_SEARCH_OBJ = {
  type: BUSINESS_SEARCH_TYPE.BY_MOT,
  value: -1,
};

export const MOT_VEHICLE_TYPES = Object.freeze({
  CAR: 1,
  MOPED: 2,
  MOTORCYCLE: 3,
  TRUCK_SMALL: 4,
  TRUCK_BIG: 5,
  BUS: 6,
  TOWING: 7,
  WORKING_MACHINE: 8,
  TRACTOR: 9,
  TRAILER: 10,
  TRACTOR_TRAILER: 11,
  SPECIAL: 12,
});

export const FUEL_TYPES = Object.freeze({
  UNKNOWN: 0,
  PETROL: 1, // benzin
  DIESEL: 2, // dizel
  LPG: 3, // tng
  CNG: 4, // kpg
  ELECTRIC: 5, // elektro
  HYBRID_PETROL_ELETRIC: 6, // hibrid benzin
  HYBRID_DIESEL_ELECTRIC: 7, // hibrid dizel
});

export const FUEL_TYPES_LABELS = Object.freeze({
  [FUEL_TYPES.UNKNOWN]: "FUEL_TYPES_UNKNOWN",
  [FUEL_TYPES.PETROL]: "FUEL_TYPES_PETROL",
  [FUEL_TYPES.DIESEL]: "FUEL_TYPES_DIESEL",
  [FUEL_TYPES.LPG]: "FUEL_TYPES_LPG",
  [FUEL_TYPES.CNG]: "FUEL_TYPES_CNG",
  [FUEL_TYPES.ELECTRIC]: "FUEL_TYPES_ELECTRIC",
  [FUEL_TYPES.HYBRID_PETROL_ELETRIC]: "FUEL_TYPES_HYBRID_PETROL_ELETRIC",
  [FUEL_TYPES.HYBRID_DIESEL_ELECTRIC]: "FUEL_TYPES_HYBRID_DIESEL_ELECTRIC",
});

export const MOT_TRUCK_SMALL_BIG_THRESHOLD = 3500;
export const VEHICLE_TYPES = Object.freeze({
  UNKNOWN: 0,
  CAR: 1,
  TRUCK_SMALL: 2,
  MOTORCYCLE: 3,
  TOWING: 4,
  TRAILER: 5,
  TRUCK_BIG: 6,
  MOPED: 7,
  WORKING_MACHINE: 8,
  BUS: 9,
  SPECIAL: 10,
  TRACTOR_TRAILER: 11,
  TRACTOR: 12,
});

export const VEHICLE_TYPE_LABELS = Object.freeze({
  [VEHICLE_TYPES.UNKNOWN]: "VEHICLE_TYPES_UNKNOWN",
  [VEHICLE_TYPES.CAR]: "VEHICLE_TYPES_CAR",
  [VEHICLE_TYPES.TRUCK_SMALL]: "VEHICLE_TYPES_TRUCK_SMALL",
  [VEHICLE_TYPES.MOTORCYCLE]: "VEHICLE_TYPES_MOTORCYCLE",
  [VEHICLE_TYPES.TOWING]: "VEHICLE_TYPES_TOWING",
  [VEHICLE_TYPES.TRAILER]: "VEHICLE_TYPES_TRAILER",
  [VEHICLE_TYPES.TRUCK_BIG]: "VEHICLE_TYPES_TRUCK_BIG",
  [VEHICLE_TYPES.MOPED]: "VEHICLE_TYPES_MOPED",
  [VEHICLE_TYPES.WORKING_MACHINE]: "VEHICLE_TYPES_WORKING_MACHINE",
  [VEHICLE_TYPES.BUS]: "VEHICLE_TYPES_BUS",
  [VEHICLE_TYPES.SPECIAL]: "VEHICLE_TYPES_SPECIAL",
  [VEHICLE_TYPES.TRACTOR_TRAILER]: "VEHICLE_TYPES_TRACTOR_TRAILER",
  [VEHICLE_TYPES.TRACTOR]: "VEHICLE_TYPES_TRACTOR",
});

export const MOT_VEHICLE_TYPE_LABELS = Object.freeze({
  [MOT_VEHICLE_TYPES.CAR]: "MOT_VEHICLE_TYPES_CAR",
  [MOT_VEHICLE_TYPES.BUS]: "MOT_VEHICLE_TYPES_BUS",
  [MOT_VEHICLE_TYPES.TRAILER]: "MOT_VEHICLE_TYPES_TRAILER",
  [MOT_VEHICLE_TYPES.MOTORCYCLE]: "MOT_VEHICLE_TYPES_MOTORCYCLE",
  [MOT_VEHICLE_TYPES.TRUCK_SMALL]: "MOT_VEHICLE_TYPES_TRUCK_SMALL",
  [MOT_VEHICLE_TYPES.TRUCK_BIG]: "MOT_VEHICLE_TYPES_TRUCK_BIG",
  [MOT_VEHICLE_TYPES.TOWING]: "MOT_VEHICLE_TYPES_TOWING",
  [MOT_VEHICLE_TYPES.MOPED]: "MOT_VEHICLE_TYPES_MOPED",
  [MOT_VEHICLE_TYPES.WORKING_MACHINE]: "MOT_VEHICLE_TYPES_WORKING_MACHINE",
  [MOT_VEHICLE_TYPES.SPECIAL]: "MOT_VEHICLE_TYPES_SPECIAL",
  [MOT_VEHICLE_TYPES.TRACTOR_TRAILER]: "MOT_VEHICLE_TYPES_TRACTOR_TRAILER",
  [MOT_VEHICLE_TYPES.TRACTOR]: "MOT_VEHICLE_TYPES_TRACTOR",
});

export const BUSINESS_SORT_BY = Object.freeze({
  DISTANCE: {
    i18n: "BUSINESS_SORT_DISTANCE",
    value: 2,
  },
  AVG_GRADE: {
    i18n: "BUSINESS_SORT_AVG_GRADE",
    value: 3,
  },
  MOST_REVIEWED: {
    i18n: "BUSINESS_SORT_MOST_REVIEWED",
    value: 4,
  },
});

export const DEFAULT_BUSINESS_PAYMENT_FILTER_BY_OBJ = {
  i18n: "CHOOSE_LABEL_PLACEHOLDER",
  value: -1,
};

export const BUSINESS_PAYMENT_FILTER_BY = Object.freeze({
  CASH: {
    i18n: "BUSINESS_FILTER_CASH",
    value: 1,
  },
  INVOICE: {
    i18n: "BUSINESS_FILTER_INVOICE",
    value: 2,
  },
  CARDS: {
    i18n: "BUSINESS_FILTER_CARDS",
    value: 3,
  },
  CHEQUES: {
    i18n: "BUSINESS_FILTER_CHEQUES",
    value: 4,
  },
  ADMINISTRATIVE_BAN: {
    i18n: "BUSINESS_FILTER_ADMINISTRATIVE_BAN",
    value: 5,
  },
});

export const BUSINESS_PRICE_RANGES = Object.freeze({
  PRICE_1: 1,
  PRICE_2: 2,
  PRICE_3: 3,
  PRICE_4: 4,
});

export const BUSINESS_PAYMENT_METHODS = Object.freeze({
  1: "BUSINESS_FILTER_CASH",
  2: "BUSINESS_FILTER_INVOICE",
  3: "BUSINESS_FILTER_CARDS",
  4: "BUSINESS_FILTER_CHEQUES",
  5: "BUSINESS_FILTER_ADMINISTRATIVE_BAN",
});

export const BUSINESS_RATING_FRACTION_DIGITS = 1;
