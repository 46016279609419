import { Helmet } from "react-helmet";


function PrivacyPolicyPage() {
  return (
    <div className="nc-PageHome  relative overflow-hidden">
      <Helmet>
        <title>Polisa privatnosti</title>
        <meta property="og:title" content="Polisa privatnosti"></meta>
      </Helmet>
      <div className="container relative space-y-24 mb-12 lg:space-y-2">
        <div className="space-y-6 sm:space-y-8 mb-10">
          {/* HEADING */}
          <h2 className="text-4xl font-semibold justify-self-center  my-6">
            Koje tipove ličnih podataka prikupljamo?
          </h2>
          <div className="">
            <h4 className="text-xl  block my-3">
              Podaci koje nam direktno ostavite
            </h4>
            <span className="text-neutral-500  block">
              Ovo su podaci koje nam ostavljate kada kreirate korisnički nalog
              na E-Knjižica i kada dodajete novo vozilo u Moja vozila. Možemo
              sačuvati i u posebnim slučajevima obraditi podatke poput: imena i
              prezimena, e-mail adrese i broja telefona, te tehničkih i
              identifikacionih podataka vozila koji želite da registrujete u
              E-Knjižicu.
            </span>
          </div>
          <div>
            <h4 className="text-xl  block my-3 mb-8">
              Podaci koje prikupljamo Vašim korišćenjem E-Knjižice
            </h4>
            <span className="font-semibold">
              Podaci o načinu na koji koristite E-Knjižice
            </span>
            <span className="text-neutral-500  block">
              Možemo sačuvati i u posebnim slučajevima obraditi podatke o tome
              koje ste sve firme pregledali u adresaru, koje ste druge korisnike
              E-Knjižice kontaktirali putem sistema za razmenu poruka unutar
              E-Knjižice. Možemo sačuvati i u posebnim slučajevima obraditi
              tekstualne konverzacije sa drugim korisnicima koje ste realizovali
              putem sistema za razmenu poruka unutar E-Knjižice, kao i
              tekstualne konverzacije sa administracijom E-Knjižice realizovane
              putem kontakt forme. Takođe, možemo sačuvati i u posebnim
              slučajevima obraditi podatke o ocenama koje ste dali drugim
              korisnicima E-Knjižice, kao i konkretnim vozilima i pravnim licima
              koje ste ocenjivali, ili pravnim licima koje ste dodali na listu
              omiljenih.
            </span>
          </div>
          <div className="mb-5">
            <span className="font-semibold">Podaci o Vašim uređajima</span>
            <span className="text-neutral-500  block">
              Tokom korišćenja E-Knjižice, možemo prikupiti i obraditi podatke o
              uređajima sa kojih pristupate E-Knjižici. Ovi podaci mogu
              uključiti marku i tip uređaja, operativni sistem i njegovu
              verziju, jedinstvene oznake uređaja, te podatke o internet mreži
              sa koje pristupate E-Knjižici.
            </span>
          </div>
          <div className="mb-5">
            <span className="font-semibold">Podaci o Vašoj lokaciji</span>
            <span className="text-neutral-500  block">
              Tokom korišćenja E-Knjižica, možemo prikupiti i obraditi podatke o
              Vašoj lokaciji, putem tehnologija kakve su IP adresa ili GPS
              senzor Vašeg mobilnog telefona.
            </span>
          </div>
          <div className="mb-5">
            <span className="font-semibold">
              Cookie-ji i druge slične tehnologije
            </span>
            <span className="text-neutral-500  block">
              Kada pristupate E-Knjižica, možemo iskoristiti tehnologiju
              Cookie-ja i druge slične tehnologije da bismo identifikovali Vaš
              internet pretraživač, odnosno da bismo Vam prikazali reklame.
              Korišćenje E-Knjižica može rezultovati automatskim čuvanjem
              određenih podataka na Vašem uređaju, poput Cookie-ja i
              aplikativnih cache podataka potrebnih za komfornije korišćenje
              E-Knjižica.
            </span>
          </div>
          <div className="mb-5">
            <span className="font-semibold">Pristup SMS porukama</span>
            <span className="text-neutral-500  block">
              E-Knjižica može zahtevati pristup SMS porukama na Vašem uređaju
              radi određenih funkcionalnosti ili usluga koje pružamo. Ovo može
              uključivati sledeće svrhe:
            </span>
            <ul className="list-disc ml-14 mt-8">
              <li>
                <span className="text-neutral-500  block">
                  Verifikacija broja telefona: Ako koristimo sistem verifikacije
                  broja telefona kao deo procesa registracije ili
                  autentifikacije, pristup SMS porukama može biti potreban radi
                  slanja verifikacionog koda ili linka.
                </span>
              </li>
              <li>
                <span className="text-neutral-500  block">
                  Slanje SMS poruke radi plaćanja usluge parking servisa
                  direktno iz aplikacije
                </span>
              </li>
            </ul>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-16">
          U koje svrhe prikupljamo Vaše lične podatke
        </h2>
        <div className="!mt-12">
          <span className="text-neutral-500  block">
            E-Knjižica podatke o ličnosti prikuplja i obrađuje isključivo u
            skladu sa važećim propisima Republike Srbije, a u cilju pružanja
            usluga informacionog društva, zaštite sopstvenih legitimnih
            (poslovnih) interesa, poboljšanja svojih usluga i korisničkog
            iskustva, zaštite interesa svojih korisnika, očuvanja bezbednosti
            korisnika, te očuvanja javnog interesa i javne bezbednosti.
          </span>
        </div>
        <div className="!mt-12">
          <span className="text-neutral-500  block">
            Ovde mislimo da sprečimo objavljivanje neprimerenog i eksplicitnog
            sadržaja, odnosno da sprečimo oglašavanje nezakonite robe i usluga.
            Podaci iz tekstualnih konverzacija sa administracijom E-Knjižica
            koje ste realizovali putem kontakt forme, pomažu nam da uvedemo nove
            i poboljšamo postojeće funkcionalnosti.
          </span>
        </div>
        <div className="!mt-10">
          <span className="text-neutral-500  block">
            Tekstualne konverzacije sa drugim korisnicima koje ste realizovali
            putem sistema za razmenu poruka unutar E-Knjižica nam pomažu da
            sprečimo izvršenje krivičnih dela korišćenjem E-Knjižice, da
            pomognemo državnim organima u identifikaciji počinioca krivičnih
            dela, odnosno mogu pomoći u rešavanju građanskih sporova. Vaše
            identifikacione podatke možemo dostaviti nadležnom državnom organu
            samo po ispravno formulisanom nalogu.
          </span>
        </div>
        <div className="!mt-10">
          <span className="text-neutral-500  block">
            U određenim slučajevima koji proističu iz Pravila i uslova
            korišćenja E-Knjižica, može doći do automatskog donošenja odluka na
            osnovu Vaših podataka, odnosno automatske obrade podataka. Primera
            radi, ukoliko korisnik postavlja veliki broj ocena odjednom,
            postavlja komentare neprimerene sadržine, odnosno porukama ili na
            drugi način uznemirava druge korisnike, bezbednosni softverski
            mehanizmi mogu automatski blokirati njegov korisnički nalog,
            premestiti njegov oglas u odgovarajuću kategoriju i grupu, ili
            preduzeti neku drugu meru.
          </span>
        </div>
        <div className=" !mt-10">
          <span className="text-neutral-500  block">
            Vaše lične podatke E-Knjižica čuva u bazama i na serverima
            zaštićenim savremenim bezbednostim tehnologijama, poput AES
            enkripcije podataka na serveru i SSL enkripcije komunikacije Vašeg
            internet pretraživača sa serverima. Pristup Vašim podacima i njhovu
            obradu poveravamo isključivo našim zaposlenima koji su prošli
            adekvatnu obuku. E-Knjižica kontinuirano sprovodi mere zaštite
            bezbednosti Vaših ličnih podataka, koje obuhvataju savremene radne
            procese, korišćenje savremenih bezbednosnih tehnologija, te mere
            fizičke bezbednosti servera i druge opreme.
          </span>
        </div>
        <div className=" !mt-10">
          <span className="text-neutral-500  block">
            Podatke čuvamo u trajanju koje je neophodno da obezbedi nesmetano
            pružanje usluga korisnicima, tj. izvršenje naše ugovorne obaveze iz
            Pravila i uslova korišćenja E-Knjižice. Konkretno, Vaše lične
            podatke čuvaćemo sve dok ste registrovani korisnik E-Knjižica,
            odnosno dok nam ne podnesete zahtev za brisanje Vašeg korisničkog
            naloga i ličnih podataka. Nakon podnošenja ovakvog zahteva, imamo
            zakonsku obavezu da određene podatke, na primer identifikaciju,
            čuvamo 30 dana pre konačnog brisanja. Za prikupljanje i obradu bilo
            kojih podataka u bilo koju svrhu koja nije obrađena ovom polisom,
            E-Knjižica će obavezno tražiti Vaš izričiti pristanak, odnosno
            postupati u skladu sa važećim propisima Republike Srbije.
          </span>
        </div>
        <h2 className="text-4xl font-semibold !mt-10 mb-16">
          Kako nam se možete obratiti sa pitanjima u vezi sa Vašim ličnim
          podacima
        </h2>
        <div className=" !mt-10">
          <span className="text-neutral-500  block">
            U slučaju da imate bilo kakvo pitanje o zaštiti Vaše privatnosti na
            E-Knjižica, kao i o načinu skupljanja i obrade Vaših ličnih
            podataka, molimo Vas da nam se obratite preko kontakt forme ili
            email adrese office@eknjizica.rs.
          </span>
        </div>
        <h2 className="text-4xl font-semibold !mt-10 mb-16">
          Šta se dešava u slučaju promene ove polise?
        </h2>
        <div className=" !mt-10">
          <span className="text-neutral-500  block">
            Ova polisa je podložna promenama. U slučaju značajnih izmena,
            E-Knjižica će sve korisnike obavestiti o izmenama na aktivan način,
            na primer putem e-maila ili jasno uočljivih obaveštenja. U slučaju
            da bilo koje buduće promene ove polise budu podrazumevale umanjenje
            Vaših prava, tražićemo od Vas izričiti pristanak za nastavak
            korišćenja E-Knjižica. U slučaju promena ove polise, sve ranije
            verzije biće javno dostupne u arhivi.
          </span>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
