import { CircularProgress } from "@mui/material";
const FullScreenLoading = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress color="primary" size={60} />
  </div>
);

export default FullScreenLoading;
