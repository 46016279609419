import { IconButton, Typography } from "@mui/material";
import { Business } from "data/types";
import I18nHelper from "I18n/I18nHelper";
import { FC } from "react";
import {
  getBusinessWorkingDaysAndHours,
  isBusinessOpenNow,
  isBusinessOpenOnDay,
} from "utils/BusinessUtils";
import { getCurrentDay } from "utils/TimeUtils";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DAY_LABELS } from "utils/AppConstants";

const BusinessWorkingHours: FC<{
  data: Business;
  openRadnoVreme: boolean;
  toggleBodyRadnoVreme: () => void;
}> = ({ data, openRadnoVreme, toggleBodyRadnoVreme }) => {
  const day = getCurrentDay();
  const workDaysAndHours = getBusinessWorkingDaysAndHours(
    data ? data : ({} as Business)
  );
  // @ts-ignore
  const currentDayWorkHours = workDaysAndHours[day];
  const currentDayHasWorkHours = isBusinessOpenOnDay(currentDayWorkHours);

  const ExpandedView = () => {
    const workDaysMapKeys = Object.keys(workDaysAndHours);
    const orderedKeys = workDaysMapKeys.slice(1);
    orderedKeys.push(workDaysMapKeys[0]);
    return orderedKeys.map((key: any, index) => {
      //@ts-ignore
      const workDay = workDaysAndHours[key];
      const isOpen = isBusinessOpenOnDay(workDay);
      return (
        <div key={index} className="grid grid-cols-2 ">
          <div className="justify-self-start ">
            <span className="font-light">
              {/* @ts-ignore */}
              {I18nHelper.getText(DAY_LABELS[key])}
            </span>
          </div>
          <div className="justify-self-end">
            <span className="text-red-400">
              {isOpen
                ? `${workDay.startTime} - ${workDay.endTime}`
                : I18nHelper.getText("BUSINESS_PROFILE_OFFICE_CLOSED")}
            </span>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="listingSection__wrap_column ">
      <div>
        <h2 className="text-2xl font-semibold">Radno vreme</h2>
      </div>
      <div className="grid my-0 grid-cols-4 items-center">
        <div className="flex flex-row min-w-36 col-span-2 ">
          <AccessTimeIcon className="mr-3 " />
          <span className="font-bold">
            {currentDayWorkHours &&
            currentDayWorkHours &&
            currentDayWorkHours.startTime &&
            currentDayWorkHours.endTime
              ? `${currentDayWorkHours.startTime} - ${currentDayWorkHours.endTime}`
              : "Zatvoreno"}
          </span>
        </div>
        {currentDayHasWorkHours ? (
          <div className="justify-self-start ">
            <Typography
              className={
                isBusinessOpenNow(currentDayWorkHours)
                  ? "text-green-400"
                  : "text-red-400"
              }
            >
              {I18nHelper.getText(
                isBusinessOpenNow(currentDayWorkHours)
                  ? "BUSINESS_LABEL_OPENED_NOW"
                  : "BUSINESS_LABEL_CLOSED_NOW"
              )}
            </Typography>
          </div>
        ) : (
          <div>
            <Typography className="text-red-400">
              {I18nHelper.getText("BUSINESS_PROFILE_OFFICE_CLOSED")}
            </Typography>
          </div>
        )}

        <div className="justify-self-end max-w-6 sm:md:lg:mr-0 mr-[25px]">
          <IconButton onClick={() => toggleBodyRadnoVreme()}>
            {openRadnoVreme ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      {openRadnoVreme && ExpandedView()}
    </div>
  );
};

export default BusinessWorkingHours;
