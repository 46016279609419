import EServiceBookFetchService from "../EserviceBook/EServiceBookFetchService";
import { AUTHORIZATION_HEADER_TYPE } from "../../FetchService";

const FetchService = EServiceBookFetchService;
const endpointPrefix = FetchService.apiVersion
  ? `/api/web/v${FetchService.apiVersion}/business`
  : "/api/business";

export const getServiceTypesGet = () => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/GetServiceTypes`,
  });
};

export const getSearchBusinessesGet = (searchParams) => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/SearchBusinesses`,
    params: searchParams,
    headerOptions: {
      authorizationHeaderType: AUTHORIZATION_HEADER_TYPE.BEARER,
    },
  });
};

export const getBusinessGet = (id, latitude, longitude) => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/GetBusiness`,
    params: { id, latitude, longitude },
    headerOptions: {
      authorizationHeaderType: AUTHORIZATION_HEADER_TYPE.BEARER,
    },
  });
};

export const getBusinessMOTDataGet = (businessId) => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/GetBusinessMOTData/${businessId}`,
  });
};

export const getBusinessRatingsGet = (businessId) => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/GetBusinessRatings/${businessId}`,
  });
};

export const getSearchBusinessesForAutocompleteGet = (searchParams) => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/SearchBusinessesForAutocomplete`,
    params: searchParams,
    headerOptions: {
      authorizationHeaderType: AUTHORIZATION_HEADER_TYPE.BEARER,
    },
  });
};
