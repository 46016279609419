export const StringConstants = Object.freeze({
  SERBIAN: "Srpski",
  MACEDONIAN: "Makedonski",
  MONTENEGRIN: "Crnogorski",
  CROATIAN: "Hrvatski",
  BOSNIAN: "Bosanski",
  SLOVENIAN: "Slovenski",
  ENGLISH: "Engleski",

  BOSNIA_AND_HERZEGOVINA: "Bosna i Hercegovina",
  NORTH_MACEDONIA: "Severna Makedonija",
  MONTENEGRO: "Crna Gora",
  CROATIA: "Hrvatska",
  SERBIA: "Srbija",
  SLOVENIA: "Slovenija",
  OTHER: "Drugo",

  SHARE_APP:
    "Preporučujem ti da instaliraš ovu aplikaciju, sadrži puno korisnih opcija za vozače! www.eknjizica.rs",
  APP_WEBSITE_LINK_LABEL: "www.eknjizica.rs",
  APP_WEBSITE_LINK: "http://www.eknjizica.rs/",
  TERMS_OF_SERVICE_LINK: "http://www.regos.rs/",

  DATE_TIME_FORMAT: "DD.MM.YY, h:mm a",
  DATE_FORMAT: "DD.MM.YYYY",
  TIME_FORMAT: "HH:mm",
  SHORT_DATE_FORMAT: "DD.MM.YY",

  HTTP_FORBIDDEN: "Zabranjeno",
  HTTP_OBJECT_NOT_FOUND: "Nije pronađeno",
  HTTP_WRONG_INPUT_DATA: "Pogrešni podaci",
  HTTP_INTERNAL_SERVER_ERROR: "Greška na serveru",
  HTTP_UNAUTHORIZED: "Nemate pravo pristupa ovom resursu",
  HTTP_CREDENTIALS_INCORRECT: "Kredencijali neispravni",
  HTTP_CREDENTIALS_COULD_NOT_BE_RETRIEVED: "Nije moguće dobaviti kredencijale",
  HTTP_CLIENT_INACTIVE: "Klijent neaktivan",
  HTTP_USERNAME_OR_PASSWORD_INCORRECT: "Korisničko ime ili šifra neispravni",

  MOT: "Tehnički pregled",
  COMPANY_SERVICE_TYPES_ENUM_MECHANICS: "Mehaničar",
  COMPANY_SERVICE_TYPES_ENUM_TYRES: "Vulkanizer",
  COMPANY_SERVICE_TYPES_ENUM_OPTICS: "Reglaža trapa",
  COMPANY_SERVICE_TYPES_ENUM_ELECTRICS: "Elektrika",
  COMPANY_SERVICE_TYPES_ENUM_ELECTRONICS: "Elektronika",
  COMPANY_SERVICE_TYPES_ENUM_EXHAUSTS: "Auspuh servis",
  COMPANY_SERVICE_TYPES_ENUM_DPF: "Čišćenje DPF",
  COMPANY_SERVICE_TYPES_ENUM_AIR_CONDITIONING: "Klima servis",
  COMPANY_SERVICE_TYPES_ENUM_GLASS: "Stakla",
  COMPANY_SERVICE_TYPES_ENUM_AUTO_GAS: "Auto Gas",
  COMPANY_SERVICE_TYPES_ENUM_DIAGNOSTICS: "Dijagnostika",
  COMPANY_SERVICE_TYPES_ENUM_BODYWORK_PAINT: "Limar-lakirer",
  COMPANY_SERVICE_TYPES_ENUM_TURBO_SPECIALIST: "Turbo servis",
  COMPANY_SERVICE_TYPES_ENUM_DIESEL_SPECIALIST: "Dizel servis",
  COMPANY_SERVICE_TYPES_ENUM_GEARBOX_SPECIALIST: "Servis menjača",
  COMPANY_SERVICE_TYPES_ENUM_CAR_WASH: "Perionica",
  COMPANY_SERVICE_TYPES_ENUM_DETAILING: "Detailing",
  COMPANY_SERVICE_TYPES_ENUM_UPHOLSTERY: "Tapetar",
  COMPANY_SERVICE_TYPES_ENUM_TUNING: "Tuning",
  COMPANY_SERVICE_TYPES_ENUM_PARTS_SHOP: "Prodavnica delova",
  COMPANY_SERVICE_TYPES_ENUM_TYRES_SHOP: "Prodaja guma",
  COMPANY_SERVICE_TYPES_ENUM_ACCESSORIES_SHOP: "Auto oprema",
  COMPANY_SERVICE_TYPES_ENUM_MOTO_SPECIALIST: "Servis motocikala",
  COMPANY_SERVICE_TYPES_ENUM_TRUCK_SPECIALIST: "Servis kamiona",
  COMPANY_SERVICE_TYPES_ENUM_TACHOGRAPH_SERVICES: "Tahograf servis",
  COMPANY_SERVICE_TYPES_ENUM_CAR_TOW: "Šlep služba",
  BUSINESS_FILTER_CASH: "Gotovina",
  BUSINESS_FILTER_INVOICE: "Fakturom",
  BUSINESS_FILTER_CARDS: "Karticama",
  BUSINESS_FILTER_CHEQUES: "Čekovima",
  BUSINESS_FILTER_ADMINISTRATIVE_BAN: "Administrativnom zabranom",
  BUSINESS_LABEL_OPENED_NOW: "Otvoreno",
  BUSINESS_LABEL_CLOSED_NOW: "Zatvoreno",
  BUSINESS_PROFILE_OFFICE_CLOSED: "Zatvoreno",
  DAYS_MONDAY: "Ponedeljak",
  DAYS_TUESDAY: "Utorak",
  DAYS_WEDNESDAY: "Sreda",
  DAYS_THURSDAY: "Četvrtak",
  DAYS_FRIDAY: "Petak",
  DAYS_SATURDAY: "Subota",
  DAYS_SUNDAY: "Nedelja",
  BUSINESS_SORT_DISTANCE: "Sortiraj po udaljenosti",
  BUSINESS_SORT_AVG_GRADE: "Sortiraj po prosečnoj oceni",
  BUSINESS_SORT_MOST_REVIEWED: "Sortiraj po najčešće ocenjivano",
  FUEL_TYPES_PETROL: "Benzin",
  FUEL_TYPES_DIESEL: "Dizel",
  FUEL_TYPES_LPG: "TNG",
  FUEL_TYPES_CNG: "PNG",
  FUEL_TYPES_ELECTRIC: "Elektro",
  FUEL_TYPES_HYBRID_PETROL_ELETRIC: "Hibrid benzin",
  FUEL_TYPES_HYBRID_DIESEL_ELECTRIC: "Hibrid dizel",
  FUEL_TYPES_UNKNOWN: "Nepoznato",
  VEHICLE_TYPES_UNKNOWN: "Nepoznatno",
  VEHICLE_TYPES_CAR: "Putničko",
  VEHICLE_TYPES_TRUCK_SMALL: "Teretno",
  VEHICLE_TYPES_MOTORCYCLE: "Motocikl",
  VEHICLE_TYPES_TOWING: "Vučno",
  VEHICLE_TYPES_TRAILER: "Prikljucno",
  VEHICLE_TYPES_TRUCK_BIG: "Tegljač",
  VEHICLE_TYPES_MOPED: "Moped",
  VEHICLE_TYPES_WORKING_MACHINE: "Radna mašina",
  VEHICLE_TYPES_BUS: "Autobus",
  VEHICLE_TYPES_SPECIAL: "Specijalno",
  VEHICLE_TYPES_TRACTOR_TRAILER: "Priključno za traktor",
  VEHICLE_TYPES_TRACTOR: "Traktor",
});
