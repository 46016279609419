import Heading from "components/Heading/Heading";
import { FC } from "react";
import GlideImages from "components/GlideImages/GlideImages";
import { useNavigate } from "react-router-dom";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
export interface HowItWorksHomeServiceProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}
const DEMO_DATA_SERVICE = [
  {
    id: 1,
    svg: HIW1img,
  },
  {
    id: 2,
    svg: HIW2img,
  },
];

const HowItWorksHomeService: FC<HowItWorksHomeServiceProps> = ({}) => {
  const navigate = useNavigate();

  return (
    <div className="p-3" data-nc-id="SectionHowItWork">
      <div className="space-y-6 sm:space-y-8">
        {/* HEADING */}
        <h2 className="text-3xl font-semibold text-center my-3">
          Elektronska servisna knjižica za auto servise
        </h2>
        <div className="max-w-2xl">
          <span className="text-xl font-semibold block my-3">
            Šta je E-Knjižica?
          </span>
          <span className="text-neutral-700  block">
            Elektronska servisna knjižica (E-Knjižica) predstavlja digitalni
            način vođenja evidencije radova na vozilu Vaših stranaka. Bez
            potrebe za papirnom knjižicom, Vaše stranke mogu u svakom momentu i
            sa svakog mesta da imaju uvid u radove na svojim vozilima. Pravo
            pristupa E-Knjižici vozila imaju vlasnik vozila i autorizovani auto
            servisi u kojima se vozilo održava.
          </span>
        </div>
        <div className="max-w-2xl">
          <span className="text-xl font-semibold block my-3">
            Kako da postanete autorizovan servis za vođenje E-knjižice?
          </span>
          <span className="text-neutral-700  block">
            E-Knjižica je jedna od usluga AutoProfil portala za auto servise. Da
            biste postali autorizovan servis za vođenje E-Knjižice, registrujte
            se na{" "}
            <a
              onClick={() => navigate("/adresar")}
              href=""
              style={{
                color: "blue",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              AutoProfilu.
            </a>{" "}
          </span>
        </div>
      </div>
      <h2 className="text-3xl font-semibold text-center my-20">
        Kako se otvara i ažurira E-Knjižica vozila?
      </h2>
      <div className="mt-20 relative flex flex-wrap md:grid md:grid-cols-2 gap-20">
        <div className="text-center mt-auto">
          <h3 className="text-xl font-semibold">
            Kako se otvara E-Knjižica Vašeg auta?
          </h3>
          <span className="block mt-5 text-neutral-500 ">
            Elektronsku servisnu knjižicu možete otvoriti tako što ćete poneti
            saobraćajnu dozvolu do bilo kog od autorizovanih auto servisa{" "}
            <a
              onClick={() => navigate("/adresar")}
              href=""
              style={{
                color: "blue",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              (E-AUTO SERVISI)
            </a>{" "}
            u Vašem mestu. Sve što je potrebno da zatražite otvaranje knjižice,
            i za nekoliko sekundi očitavanjem saobraćajne dozvole Vaša
            E-Knjižica je kreirana. Prilikom održavanja vozila u E-AUTO
            SERVISIMA svakako će biti ažurirana Vaša E-Knjižica i podatke za
            pristup dobićete putem sms-a.
          </span>
        </div>
        <GlideImages glideImages={DEMO_DATA_SERVICE} className="self-center" />
      </div>
      <div className="mt-20 relative flex flex-wrap md:grid md:grid-cols-2 gap-20">
        <div className="text-center mt-auto">
          <h3 className="text-xl font-semibold">
            Šta je sadržaj i ko može da čita iz E-Knjižice?
          </h3>
          <span className="block mt-5 text-neutral-500 ">
            U knjižicu se upisuju tehnički podaci vozila (broj šasije i motora,
            kubikaža, snaga, vrsta goriva, podaci o gumama, klimi itd), svi
            urađeni servisi i opravke, kao i ugrađeni delovi. E-Knjižici može da
            pristupa samo vlasnik, tj. onaj ko poseduje saobraćajnu dozvolu, kao
            i oni kojima vlasnik to dozvoli davanjem saobraćajne dozvole (auto
            servisu prilikom održavanja vozila).Oba podatka neophodna za pristup
            pišu na samoj saobraćajnoj dozvoli. Na prednjoj strani piše broj
            dokumenta, a na zadnjoj broj šasije. Podatke ćete dobiti i putem
            sms-a.
          </span>
        </div>
        <GlideImages glideImages={DEMO_DATA_SERVICE} className="self-center" />
      </div>
      <div className="mt-20 relative flex flex-wrap md:grid md:grid-cols-2 gap-20 mb-10">
        <div className="text-center mb-2 mt-auto">
          <h3 className="text-xl font-semibold">
            Ko može da upisuje u E-Knjižicu?
          </h3>
          <span className="block mt-5 text-neutral-500 ">
            Podatke koji se učitavaju sa saobraćajne dozvole upisuju
            autorizovani auto servisi . Dodatne podatke o vozilu, kao i podatke
            o servisiranju vozila upisuju auto servisi, kojima pristup
            E-Knjižici daje vlasnik predajom saobraćajne dozvole. Posle svakog
            upisa u E-Knjižicu Vašeg vozila, bićete obavešteni putem sms-a da je
            ažuriranje izvršeno.
          </span>
        </div>
        <GlideImages glideImages={DEMO_DATA_SERVICE} className="self-center" />
      </div>
      <div className="grid my-3">
        <Heading isCenter desc="">
          Kako funkcioniše?
        </Heading>
        <span className="justify-self-center">
          Ponudite Vašim strankama savremenu uslugu. Vodite elektronske servisne
          knjižice.
        </span>
        <button>
          <a
            href="http://service.autoprofil.rs/Account/Register"
            target="_blank"
            style={{
              color: "blue",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            OTVORITE NALOG ODMAH!
          </a>
        </button>
        <button>
          <a
            href="http://service.autoprofil.rs/EKnjizica/StaJeElektronskaServisnaKnjizica"
            target="_blank"
            style={{
              color: "blue",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Saznaj više
          </a>
        </button>
      </div>
    </div>
  );
};

export default HowItWorksHomeService;
