import AddressBook from "./AddressBook";
import { Helmet } from "react-helmet";


const AddressBookPage = ({}) => {
  return (
    <div className={`nc-ListingStayMapPage `} data-nc-id="ListingStayMapPage">
      <Helmet>
        <title>Adresar auto servisa</title>
        <meta property="og:title" content="Adresar auto servisa"></meta>
      </Helmet>
      <div className="pb-10">
        <AddressBook />
      </div>
    </div>
  );
};

export default AddressBookPage;
