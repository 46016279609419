import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const driverMenu: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/mobilna-aplikacija",
    name: "Mob app",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/kako-funkcionise",
    name: "Kako funkcioniše",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/adresar",
    name: "Pronađi servis",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/pitanja",
    name: "Česta pitanja",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/kontakt",
    name: "Kontakt",
    isNew: true,
  },
];

export const serviceMenu: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/servis/mobilna-aplikacija",
    name: "Opcije",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/kako-funkcionise",
    name: "Kako funkcioniše",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/adresar",
    name: "Adresar",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/pitanja",
    name: "Česta pitanja",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/kontakt",
    name: "Kontakt",
    isNew: true,
  },
];

export const driverMenuMob: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/mobilna-aplikacija",
    name: "Mob app",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/kako-funkcionise",
    name: "Kako funkcioniše?",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/adresar",
    name: "Adresar",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/pitanja",
    name: "Pitanja",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/kontakt",
    name: "Kontakt",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/politika-privatnosti",
    name: "Politika privatnosti",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/uslovi-koriscenja",
    name: "Uslovi korišćenja",
    isNew: true,
  },
  // {
  //   id: ncNanoId(),
  //   href: "/usluge",
  //   name: "Sve usluge",
  //   isNew: true,
  // },
];

export const serviceMenuMob: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/servis/mobilna-aplikacija",
    name: "Opcije",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/kako-funkcionise",
    name: "Kako funkcioniše",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/adresar",
    name: "Adresar",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/pitanja",
    name: "Pitanja",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/kontakt",
    name: "Kontakt",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/politika-privatnosti",
    name: "Politika privatnosti",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/servis/uslovi-koriscenja",
    name: "Uslovi korišćenja",
    isNew: true,
  },
];

export const SERVICE_NAVIGATION: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Menu",
    type: "dropdown",
    children: serviceMenuMob,
    isNew: true,
  },
]

export const DRIVER_NAVIGATION: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Menu",
    type: "dropdown",
    children: driverMenuMob,
    isNew: true,
  },
];
