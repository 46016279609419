const avatarColors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A6',
  '#33FFF5',
  '#FFC133',
  '#9B33FF',
  '#33FFBD',
  '#FF3333',
  '#33FF88',
]

export { avatarColors };
