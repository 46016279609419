import { StarIcon } from "@heroicons/react/24/solid";
import { FC } from "react";

export interface StartRatingProps {
  className?: string;
  point?: number;
  reviewCount?: number;
  blueBackground?: boolean;
}

const StartRating: FC<StartRatingProps> = ({
  point = 4.5,
  reviewCount,
  blueBackground,
}) => {
  return (
    <div
      className={"flex items-center justify-items-end  space-x-1 text-sm"}
      data-nc-id="StartRating"
    >
      <span
        className={
          blueBackground
            ? "font-medium border border-sky-400 bg-sky-400 text-white rounded-lg p-2 py-0.5 "
            : "font-medium"
        }
      >
        {point.toFixed(1)}
      </span>
      <div className={blueBackground ? "pb-[2px]" : "pb-[2px] self-end"}>
        <StarIcon className="w-[18px] h-[18px]  text-yellow-500" />
      </div>
      {reviewCount && <span className="text-neutral-700">({reviewCount})</span>}
    </div>
  );
};

export default StartRating;
