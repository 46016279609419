import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import HomePage from "containers/PageHome/HomePage";
import AddressBookPage from "containers/AddressBook/AddressBookPage";
import ContactPage from "containers/ContactPage/ContactPage";
import BenefitsPage from "containers/PageHome/BenefitsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import StranicaServisa from "containers/BusinessProfile/BusinessProfilePage";
import HomeServicePage from "containers/PageHome/HomeServicePage";
import MobAppFeaturesPage from "containers/PageHome/MobAppFeaturesPage";
import FAQPage from "containers/PageHome/FAQPage";
import PolicyPage from "containers/PageHome/PrivacyPolicyPage";
import TermsOfUsePage from "containers/PageHome/TermsOfUsePage";
import FAQServicePage from "containers/PageHome/FAQServicePage";
import ContactServicePage from "containers/ContactPage/ContactServicePage";
import LandingPage from "containers/PageHome/LandingPage";
import useScrollPosition from "./useScrollPosition";
import Page404 from "containers/Page404/Page404";
import ServicingPage from "components/ServicingPage";
import Header from "components/Header/Header";

export const pages: Page[] = [
  //Drivers
  { path: "/", exact: true, component: HomePage },
  { path: "/:id", component: LandingPage },
  //
  { path: "/adresar", component: AddressBookPage },
  { path: "/profil-servisa/:id/*", component: StranicaServisa },
  { path: "/kako-funkcionise", component: BenefitsPage },
  { path: "/mobilna-aplikacija", component: MobAppFeaturesPage },
  { path: "/pitanja", component: FAQPage },
  { path: "/politika-privatnosti", component: PolicyPage },
  { path: "/uslovi-koriscenja", component: TermsOfUsePage },
  { path: "/usluge", component: ServicingPage },

  //
  //Services
  { path: "/servis/kako-funkcionise", component: BenefitsPage },
  { path: "/servis", component: HomeServicePage },
  { path: "/servis/mobilna-aplikacija", component: MobAppFeaturesPage },
  {
    path: "/servis/pitanja",
    component: FAQServicePage,
  },
  { path: "/servis/politika-privatnosti", component: PolicyPage },
  { path: "/servis/uslovi-koriscenja", component: TermsOfUsePage },
  { path: "/servis/kontakt", component: ContactServicePage },
  { path: "/kontakt", component: ContactPage },

  //
];

const RouteComponents = () => {
  const location = useLocation(); // Get the current location within Router context
  const scrollPositions = useScrollPosition({ location }); // Pass as an object with 'location'
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;

  return (
    <>
      <ScrollToTop location={location} scrollPositions={scrollPositions} />
      <Header className="shadow-sm" navType="MainNav1" />
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </>
  );
};

const MyRoutes = () => (
  <BrowserRouter>
    <RouteComponents /> {/* Ensure the Router context is established */}
  </BrowserRouter>
);

export default MyRoutes;
