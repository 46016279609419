import EServiceBookFetchService from "../EserviceBook/EServiceBookFetchService";
import { AUTHORIZATION_HEADER_TYPE } from "../../FetchService";

const FetchService = EServiceBookFetchService;
const endpointPrefix = FetchService.apiVersion
  ? `/api/web/v${FetchService.apiVersion}/erecord`
  : "/api/erecord";

export const getERecordWithToken = (token) => {
  return FetchService.executeGet({
    endpoint: `${endpointPrefix}/GetERecordWithToken`,
    params: { token },
    headerOptions: {
      authorizationHeaderType: AUTHORIZATION_HEADER_TYPE.BEARER,
    },
  });
};
