import React, { FC } from "react";
import twFocusClass from "utils/twFocusClass";

export interface NextPrevProps {
  className?: string;
  currentPage: number;
  totalPage: number;
  btnClassName?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
}

const NextPrevPagination: FC<NextPrevProps> = ({
  className = "",
  onClickNext = () => {},
  onClickPrev = () => {},
  btnClassName = "w-10 h-10",
  currentPage,
  totalPage,
}) => {
  return (
    <div
      className={`nc-NextPrev relative flex items-center text-neutral-900 ${className}`}
      data-nc-id="NextPrev"
      data-glide-el="controls"
    >
      <button
        className={`${btnClassName}  border border-neutral-200 rounded-full flex items-center justify-center ${
          currentPage - 1 === 0
            ? "bg-gray-200"
            : "bg-white hover:border-neutral-300"
        } ${twFocusClass()}`}
        onClick={onClickPrev}
        title="Prev"
        data-glide-dir="<"
        disabled={currentPage - 1 === 0}
      >
        <i className="las la-angle-left"></i>
      </button>
      <span>{currentPage}</span>
      <span>od</span>
      <span>{totalPage}</span>
      <button
        className={`${btnClassName}   border border-neutral-200  rounded-full flex items-center justify-center ${
          currentPage === totalPage
            ? "bg-gray-200"
            : "bg-white hover:border-neutral-300"
        }  ${twFocusClass()}`}
        onClick={onClickNext}
        disabled={currentPage === totalPage}
        title="Next"
        data-glide-dir=">"
      >
        <i className="las la-angle-right"></i>
      </button>
    </div>
  );
};

export default NextPrevPagination;
