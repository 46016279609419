import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { FC } from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import SearchIcon from "@mui/icons-material/Search";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ServiceTypesArray } from "reducers/serviceReducer";
import AutocompleteBServices from "components/AutocompleteBServices";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

interface SearchBusinessProps {
  handlePredictionClick: (prediction: any) => Promise<void>;
  handleInputChange: (event: any) => void;
  inputValue: string;
  resetData: () => void;
  handleGetBusiness: (shouldAppend: boolean) => void;
  options: { placeId: string; name: string }[];
  handleServiceType: (value: any) => void;
  serviceTypes: ServiceTypesArray[];
  handleOpen: () => void;
  handleClose: () => void;
  nameSearchValue: string;
  handleNameChange: (event: any) => void;
  setNameSearchValue: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  setAutocompleteValue: React.Dispatch<React.SetStateAction<any>>;
  autocompleteValue: any;
}
export const AddressBookSearch: FC<SearchBusinessProps> = ({
  handlePredictionClick,
  handleInputChange,
  inputValue,
  resetData,
  handleGetBusiness,
  options,
  handleServiceType,
  serviceTypes,
  handleOpen,
  handleClose,
  nameSearchValue,
  handleNameChange,
  setNameSearchValue,
  open,
  setAutocompleteValue,
  autocompleteValue,
}) => {
  const handleModalListClicked = (value: any) => {
    setAutocompleteValue(value);
    handleServiceType(value);
    handleClose();
  };

  function isMobileView() {
    return window.innerWidth <= 768;
  }
  const isMobile = isMobileView();

  const calculateGridSpan = (
    serviceTypes: ServiceTypesArray[],
    x: ServiceTypesArray
  ) => {
    const length = serviceTypes.filter((y) => y.service_ID === x.id).length;
    return length + 1;
  };

  return (
    <div className="flex flex-wrap justify-center  rounded-md items-center  my-3">
      <div className="p-1">
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <LocationInput
            handleRecentPredictionClick={(value) =>
              handlePredictionClick(value)
            }
            onInputDone={(value) => handleInputChange(value)}
            autoFocus={false}
            inputValue={inputValue}
          />
          <ClearDataButton onClick={resetData} />
        </div>
        {options && !!options?.length && (
          <ul className="border z-50 rounded-2xl  bg-white absolute w-60 ">
            {options.map((item) => (
              <span
                onClick={() => handlePredictionClick(item)}
                key={item.name}
                className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 cursor-pointer"
              >
                <span className="block text-neutral-400">
                  <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                </span>
                <span className="block text-neutral-700">{item.name}</span>
              </span>
            ))}
          </ul>
        )}
      </div>
      <div className="p-1">
        <div
          className={`flex flex-1 relative z-10 [ nc-hero-field-padding--small ] flex-shrink-0 min-w-72 items-center space-x-3 cursor-pointer focus:outline-none text-left ${"nc-hero-field-focused--2"}`}
        >
          <div className="flex-1">
            <input
              className={`block w-full bg-transparent border-none  min-w-[19rem] focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-semibold truncate`}
              placeholder={"Ime servisa"}
              value={nameSearchValue}
              onChange={(e) => handleNameChange(e)}
            />
            <ClearDataButton onClick={() => handleNameChange("")} />
          </div>
        </div>
      </div>
      {/* <div className="p-1 w-[21.5rem]">
        <div
          className={`flex flex-1 relative z-10 [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3  focus:outline-none text-left ${"nc-hero-field-focused--2"}`}
        >
          <input
            type="text"
            style={{ paddingLeft: "1px" }}
            className="block   bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 text-sm font-semibold truncate"
            onClick={handleOpen}
            value={autocompleteValue}
            readOnly
            placeholder="Odaberite uslugu"
          />
          <ClearDataButton onClick={() => handleServiceType(null)} />
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ alignSelf: "center" }}>Pronađite uslugu</span>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <AutocompleteBServices
                  handleModalListClicked={handleModalListClicked}
                  options={serviceTypes
                    .filter((x) => x.level === 3)
                    .map((x) => x.name)}
                />
              </div>
              <div className="grid py-2 ">
                <button className="justify-self-end text-blue-400 text-sm">
                  <Link to="/usluge">Detaljan pregled usluga</Link>
                </button>
              </div>
              <div
                className=" "
                style={{
                  display: "grid",
                  gridAutoRows: "24px",
                  gridGap: "7px",
                  gridTemplateColumns: isMobile
                    ? "1fr"
                    : "repeat(auto-fill, minmax(30%, 1fr))",
                }}
              >
                {serviceTypes.map(
                  (x: ServiceTypesArray) =>
                    x.level === 1 && (
                      <div
                        style={{
                          gridRow: `span ${calculateGridSpan(serviceTypes, x)}`,
                        }}
                        key={x.id}
                      >
                        <button
                          onClick={() => handleModalListClicked(x)}
                          className={`${
                            x.name === "Tehnički pregled"
                              ? "cursor-pointer"
                              : ""
                          }  text-sm font-semibold capitalize text-lg  `}
                        >
                          {x.name}
                        </button>
                        <div className="mt-1">
                          {serviceTypes
                            .filter(
                              (y: ServiceTypesArray) => y.service_ID === x.id
                            )
                            .map((y) => (
                              <div className="mt-1" key={y.id}>
                                <button
                                  onClick={() => handleModalListClicked(y)}
                                  className=" text-sm text-start cursor-pointer text-gray-400 "
                                >
                                  {y.name}
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>
                    )
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div> */}
      <div className={`p-1 ${isMobile ? "w-[21.5rem]" : ""}`}>
        <button
          style={{
            color: "white",
            background: "rgb(14 165 233 / var(--tw-bg-opacity))",
          }}
          className="border rounded-full min-w-40 w-full border-sky-400 p-3 py-2"
          onClick={() => handleGetBusiness(false)}
        >
          <SearchIcon className="mr-2" />
          Pretraži
        </button>
      </div>
    </div>
  );
};
