import { ServiceTypesArray } from "reducers/serviceReducer";

export const toggleService = () => {
  return {
    type: "TOGGLE_SERVICE",
  };
};

export const setServiceTypesArrayRedux = (serviceTypes: ServiceTypesArray[]) => {
  return {
    type: "SET_SERVICE_TYPES",
    serviceTypes: serviceTypes,
  };
};
