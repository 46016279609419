import HowItWorksHomeService from "components/HowItWorks/HowItWorksHomeService";

function HomeServicePage() {
  return (
    <div className="nc-PageHome bg-sky-100 relative overflow-hidden">
      <div className="container relative space-y-24 mb-12 lg:space-y-2">
        <HowItWorksHomeService />
      </div>
    </div>
  );
}

export default HomeServicePage;
