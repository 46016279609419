let I18nProviderRef;
const setProviderRef = (ref) => {
  I18nProviderRef = ref;
};

const getLangData = () => {
  return I18nProviderRef && I18nProviderRef.getLangData();
};

const getAllLanguages = () => {
  return I18nProviderRef && I18nProviderRef.getAllLanguages();
};

const setAllLanguages = (allLanguages) => {
  I18nProviderRef && I18nProviderRef.setAllLanguages(allLanguages);
};

const setSelectedLangId = (selectedLangId) => {
  I18nProviderRef && I18nProviderRef.setSelectedLangId(selectedLangId);
};

export default {
  setProviderRef,
  getLangData,
  getAllLanguages,
  setAllLanguages,
  setSelectedLangId,
};
