import AppConfig from "config/AppConfig";
import { FC } from "react";
import { downloadAppClick } from "utils/SmsClickedUtils";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
  smsLinkExpiryPeriodInDays: number;
}

const VehicleInviteDataAvailableInfo: FC<SectionBecomeAnAuthorProps> = ({
  className,
  smsLinkExpiryPeriodInDays,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor p-4 relative flex items-center ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 w-full">
        <h2 className="font-semibold text-3xl sm:text-4xl text-center">
          Podaci vašeg vozila u vašem džepu
        </h2>

        <span className="block mt-6 text-neutral-500 px-4 w-full break-words">
          {`Sadržaj ove stranice je dostupan ${smsLinkExpiryPeriodInDays} dana. Da biste obezbedili trajan
          pristup podacima vozila i servisa, instalirajte `}{" "}
          <a
            onClick={() => downloadAppClick(true)}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "blue",
              textDecoration: "underline",
            }}
          >
            mobilnu aplikaciju E-Knjižica{" "}
          </a>
          u kojoj imate i brojne dodatne opcije, kao što su samostalno vođenje
          servisne knjižice, zakazivanje servisa i tehničkog pregleda, obračun
          cene registracije -{" "}
          <a
            href="/mobilna-aplikacija"
            style={{
              color: "blue",
              textDecoration: "underline",
            }}
          >
            pogledajte sve opcije.
          </a>
        </span>
      </div>
    </div>
  );
};

export default VehicleInviteDataAvailableInfo;
