import I18nHelper from "I18n/I18nHelper";
import moment from "moment";

export function formatDate(date, formatTo = I18nHelper.getText("DATE_FORMAT")) {
  const momentDate = moment.utc(date).local();
  const result = momentDate.format(formatTo);
  return result;
}

export function getStartOfDay(dateTime) {
  const format = I18nHelper.getText("DATE_FORMAT");
  const localFormatted = moment(dateTime).local().format(format);
  const utc = moment(localFormatted, format).utc();
  const localStart = utc.local();
  return localStart;
}

export function getCurrentDay() {
  return new Date().getDay();
}

