import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
import isInViewport from "utils/isInViewport";
import HelpIcon from "@mui/icons-material/Help";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MenuBar from "shared/MenuBar/MenuBar";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
let WIN_PREV_POSITION = window.scrollY;

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
}

const NAV: NavItem[] = [
  {
    name: "Početna",
    link: "/",
    icon: HomeIcon,
  },
  {
    name: "Adresar",
    link: "/adresar",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Mob app",
    link: "/mobilna-aplikacija",
    icon: SmartphoneIcon,
  },
  {
    name: "Pitanja",
    link: "/pitanja",
    icon: HelpIcon,
  },
  {
    name: "Meni",
    icon: MenuBar,
  },
];

const NAV2: NavItem[] = [
  {
    name: "Početna",
    link: "/servis",
    icon: HomeIcon,
  },
  {
    name: "Adresar",
    link: "/adresar",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Kontakt",
    link: "/servis/kontakt",
    icon: EmailIcon,
  },
  {
    name: "Pitanja",
    link: "/servis/pitanja",
    icon: HelpIcon,
  },
  {
    name: "Meni",
    icon: MenuBar,
  },
];

const FooterNav = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isService = useSelector((state: RootState) => state.service.isService);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleEvent,{passive: true});
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.scrollY;
    if (!containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white  fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex items-center justify-around mx-auto text-sm text-center ">
        {/* MENU */}
        {isService
          ? NAV2.map((item, index) => {
              const active = location.pathname === item.link;
              return item.link ? (
                <Link
                  key={index}
                  to={item.link}
                  className={`flex flex-col items-center justify-between text-neutral-500 ${
                    active ? "text-sky-900" : ""
                  }`}
                >
                  <item.icon
                    className={`w-6 h-6 ${active ? "text-sky-400" : ""}`}
                  />
                  <span className="text-[11px] leading-none mt-1">
                    {item.name}
                  </span>
                </Link>
              ) : (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-between text-neutral-500 ${
                    active ? "text-neutral-900" : ""
                  }`}
                >
                  <item.icon iconClassName="w-6 h-6" className={``} />
                  <span className="text-[11px] leading-none mt-1">
                    {item.name}
                  </span>
                </div>
              );
            })
          : NAV.map((item, index) => {
              const active = location.pathname === item.link;
              return item.link ? (
                <Link
                  key={index}
                  to={item.link}
                  className={`flex flex-col items-center justify-between text-neutral-500 ${
                    active ? "text-sky-900" : ""
                  }`}
                >
                  <item.icon
                    className={`w-6 h-6 ${active ? "text-sky-400" : ""}`}
                  />
                  <span className="text-[11px] leading-none mt-1">
                    {item.name}
                  </span>
                </Link>
              ) : (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-between text-neutral-500 ${
                    active ? "text-neutral-900" : ""
                  }`}
                >
                  <item.icon iconClassName="w-6 h-6" className={``} />
                  <span className="text-[11px] leading-none mt-1">
                    {item.name}
                  </span>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default FooterNav;
