import { useEffect } from "react";
import { Location, useLocation } from "react-router-dom";

// Define an interface for the prop types to include scrollPositions
interface ScrollToTopProps {
  scrollPositions: { [key: string]: number }; // Dictionary of scroll positions by pathname
  location: Location;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({
  scrollPositions,
  location,
}) => {
  const locationPathName = location.pathname;
  useEffect(() => {
    const savedScrollPosition = scrollPositions[locationPathName];
    const timeout = setTimeout(() => {
      const pageHeight = document.body.scrollHeight;
      if (savedScrollPosition <= pageHeight) {
        window.scrollTo(0, savedScrollPosition);
      } else if (savedScrollPosition > pageHeight) {
        setTimeout(() => {
          window.scrollTo(0, savedScrollPosition);
        }, 150);
      }
    }, 150);

    return () => clearTimeout(timeout);
  }, [locationPathName]);

  return null;
};

export default ScrollToTop;
