import { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import LogoEK from "shared/Logo/E_logo_blue_1024x500.png";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleService } from "actions/serviceActions";
import { RootState } from "reducers";
export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const [isService, setIsService] = useState<boolean>(
    useSelector((state: RootState) => state.service.isService)
  );

  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  // Example usage:
  const isMobile = isMobileView();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    setIsService(!isService);
    dispatch(toggleService());
    if (!isService) {
      navigate("/servis");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!isService && location.pathname == "/servis") {
      setTimeout(() => {
        navigate("/");
      }, 100);
    }
  }, [isService]);

  return (
    <div className={`nc-MainNav1 relative z-10  ${className}`}>
      <div className="px-4 ml-3 lg:container   relative flex justify-between items-center">
        <div className={`flex justify-items-center  items-center space-x-4 sm:space-x-10`}>
          <Logo img={LogoEK} customClassName="w-56" />
          <Navigation />
        </div>
        {/* <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 text-neutral-700">
          <div className={`${isMobile ? "hidden" : ""} flex items-center space-x-0.5`}>
            <ButtonPrimary onClick={handleClick}>
              {isService ? "Za Vozace" : "Za Servise"}
            </ButtonPrimary>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MainNav1;
