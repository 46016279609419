import EServiceBookFetchService from "./EServiceBookFetchService";
import { AUTHORIZATION_HEADER_TYPE } from "services/FetchService";

const FetchService = EServiceBookFetchService;

const endpointPrefix = FetchService.apiVersion
  ? `/api/web/v${FetchService.apiVersion}/support`
  : "/api/support";

export const contactUsPost = (contactUsData) => {
  return FetchService.executePost({
    endpoint: `${endpointPrefix}/ContactUs`,
    data: contactUsData,
    headerOptions: {
      authorizationHeaderType: AUTHORIZATION_HEADER_TYPE.BEARER,
    },
  });
};
