import { combineReducers } from "redux";
import serviceReducer, { ServiceState, ServiceTypesArray } from "./serviceReducer";
import businessSearchReducer from "./BusinessSearchReducer";
import { Business } from "data/types";

 const BusinessState = {
  searchResults: [],
  allResultsCount: 0,
}

export interface BusinessState {
  allResultsCount: number;
  searchResults: Business[];
}

export type RootState = {
    service: ServiceState;
    business: BusinessState
    // Add more slices of state as needed
  };
  
  const rootReducer = combineReducers({
    service: serviceReducer,
    business: businessSearchReducer
  });
  
  export default rootReducer;
