import NewERecordSmsLink from "components/HowItWorks/NewERecordSmsLink";
import { useParams } from "react-router-dom";
import NewVehicleAccessInviteSmsLink from "components/HowItWorks/NewVehicleAccessInviteSmsLink";
import Page404 from "containers/Page404/Page404";

function LandingPage() {
  const params = useParams();
  const { id } = params;

  const LandingPageSectionVersion = (urlString: string) => {
    const version = urlString.split("-")[0];
    if (version === "r") return <NewERecordSmsLink id={id} />;
    if (version === "i") return <NewVehicleAccessInviteSmsLink id={id} />;
    else return <Page404 />;
  };
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-12 lg:space-y-2 !px-2">
        {id && LandingPageSectionVersion(id)}
      </div>
    </div>
  );
}

export default LandingPage;
