import BackgroundSection from "components/BackgroundSection";
import dowloadAppBGPng from "images/blue-gradient-background.png";
import appRightImg from "images/holding-phone.png";
import btnIosPng from "images/btn-ios-soon.png";
import btnAndroidPng from "images/btn-android.png";
import { downloadAppClick } from "utils/SmsClickedUtils";
const DowloadAppHome = () => {
  return (
    <div className="relative pb-0 lg:py-32 xl:py-40 2xl:py-48">
      <BackgroundSection className="bg-neutral-100 bg-opacity-80">
        <img
          className="absolute inset-0 w-full h-full object-cover rounded-3xl object-right"
          src={dowloadAppBGPng}
          alt="dowloadAppPng"
        />
        <div className="hidden lg:block absolute right-0 bottom-0 max-w-xl xl:max-w-2xl rounded-3xl overflow-hidden">
          <img src={appRightImg} alt="" />
        </div>
      </BackgroundSection>
      <div className="relative inline-block ">
        <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold text-neutral-800">
          Mobilna aplikacija
        </h2>
        <span className="block mt-7 max-w-md text-neutral-6000">
          Svi radovi na Vašem vozilu plus još mnogo toga.
          <br />
          <br />
          Prezmite aplikaciju za Android, a uskoro i za IPhone pametne telefone.
        </span>
        <div className="flex space-x-3 mt-10 sm:mt-14">
          <div className="cursor-pointer">
            <a
              onClick={() => downloadAppClick(true)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={btnAndroidPng} alt="" />
            </a>
          </div>
          <div>
            <a
              className="opacity-75 pointer-events-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={btnIosPng} alt="" />
            </a>
          </div>
        </div>
        <div className="block lg:hidden mt-10 max-w-2xl rounded-3xl overflow-hidden">
          <img src={appRightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DowloadAppHome;
