import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface FAQServicePageProps {
  className?: string;
}

const FAQServicePage: FC<FAQServicePageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const ids = ["1", "2", "3", "4", "5"];
  const initialState = Object.fromEntries(ids.map((id) => [id, 0]));
  const [open, setOpen] = useState<{ [key: string]: number }>(initialState);

  const toggleOpen = (id: string) => {
    setOpen((prevState) => ({
      ...prevState,
      [id]: prevState[id] ? 0 : 1,
    }));
  };

  const isDivToggled1 = open["1"] === 1;
  const isDivToggled2 = open["2"] === 1;
  const isDivToggled3 = open["3"] === 1;

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <div className="container relative">
        <header className="text-center max-w-2xl mx-auto my-10">
          <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900  justify-center">
            Često postavljana pitanja
          </h2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base ">
            Ovde možete pronaći pitanja koje nam serviseri najčešće postavljaju.
          </span>
        </header>
        <div
          className={`nc-SectionGridFilterCard mt-6`}
          data-nc-id="SectionGridFilterCard"
        >
          <div className="lg:p-10 lg:bg-neutral-50  grid grid-cols-1 gap-6  rounded-3xl">
            <div
              onClick={() => toggleOpen("1")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap w-4/5 mb-4 font-bold">
                    <span>Koje marke vozila mogu imati E-Knjižicu?</span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 rounded-full flex items-center justify-center cursor-pointer ${
                        isDivToggled1 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled1 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"1"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      E-Knjižica je nezavisna platforma i u njoj možete voditi
                      evidenciju za sve tipove i marke vozila. To je važna
                      prednost, jer za sva vozila možete na isti način upisivati
                      servisne zapise i podsetnike.
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* Pitanje i odgovor br. 2 */}
            <div
              onClick={() => toggleOpen("2")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap mb-4 w-4/5 font-bold">
                    <span>
                      Da li je E-Knjižica namenjena ovlašćenim ili nezavisnim
                      servisima?
                    </span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled2 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled2 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"2"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      E-Knjižica je dostupna i nezavisnim i ovlašćenim auto
                      servisima, jer je to u najboljem interesu vozača, koji
                      često dok je vozilo pod garancijom odlaze u ovlašćene
                      servise, a kako vozilo stari veća je verovatnoća da će ga
                      održavati u nezavisnim servisima.
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/*Pitanje i odgovor br. 3  */}
            <div
              onClick={() => toggleOpen("3")}
              className={`nc-FlightCardgroup cursor-pointer p-4 sm:p-6 relative bg-white  border border-neutral-100
                 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
              data-nc-id="FlightCard"
            >
              <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
              >
                <div className="flex flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="overflow-wrap mb-4 w-4/5 font-bold">
                    <span>Šta dobijam korišćenjem E-Knjižice?</span>
                    <span
                      className={`absolute right-0 top-1 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50  rounded-full flex items-center justify-center  cursor-pointer ${
                        isDivToggled3 ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              {isDivToggled3 && (
                <div className="px-2 md:px-4  rounded-2xl ">
                  <div id={"3"} className="my-3 md:my-3 space-y-5 ">
                    <span>
                      Uredne podatke o održavanju vozila. SMS podsetnike za
                      redovni servis (povećava zaradu). Transparentost usluge i
                      poverenje klijenta. Profil u adresaru E-knjižice i
                      mogućnost da vas postojeći i novi klijenti pronađu i
                      kontaktiraju preko platforme.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="my-10">
          <h3 className="text-xl font-semibold my-6 ">
            Da li smo odgovorili na vaša pitanja?
          </h3>
          <span>
            {" "}
            Ukoliko niste pronašli odgovore, rado ćemo pomoći. Pošaljite pitanje
            putem{" "}
            <a
              onClick={() => navigate("/kontakt")}
              style={{
                color: "blue",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              kontakt
            </a>{" "}
            stranice
          </span>
        </div>
      </div>
    </div>
  );
};

export default FAQServicePage;
