import { FC } from "react";
import rightImgPng from "images/repairing.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

export interface OurFuturesHomeProps {
    className?: string;
    rightImg?: string;
    type?: "type1" | "type2";
}

const OurFuturesHome: FC<OurFuturesHomeProps> = ({
    className,
    rightImg = rightImgPng,
    type = "type1",
}) => {
    const navigate = useNavigate();

    return (
        <div
            className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row lg:py-14" : "lg:flex-row-reverse lg:py-14"
                } ${className}`}
            data-nc-id="SectionOurFeatures"
        >
            <div className="flex-grow">
                <NcImage containerClassName={"h-full"} src={rightImg} />
            </div>
            <div
                className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
                    }`}
            >
                <span className="uppercase text-sm text-gray-400 tracking-widest">
                    ZAŠTO E-KNJIŽICA?
                </span>
                <h2 className="font-semibold text-4xl mt-5">Jedinstvena platforma za vozače </h2>

                <ul className="space-y-10 mt-16">
                    <li className="space-y-4">
                        <Badge name="Adresar" />
                        <span className="block text-xl font-semibold">Pronađite servis</span>
                        <span className="block mt-5 text-neutral-500 ">
                            Pretražite adresar sa velikim brojem auto servisa i tehničkih pregleda
                            u svakom mestu i jednostavno ih kontaktirajte i zakažite servis ili tehnički pregled.
                        </span>
                    </li>
                    <li className="space-y-4">
                        <Badge color="green" name="Servisna dokumentacija" />
                        <span className="block text-xl font-semibold">
                            Fiskalni račun u aplikaciji
                        </span>
                        <span className="block mt-5 text-neutral-500 ">
                            Fiskalni račun, radni nalog, fotografije vozila... sve na jednom mestu u E-Knjižica aplikaciji.
                        </span>
                    </li>
                    <li className="space-y-4">
                        <Badge color="red" name="Zajednica" />
                        <span className="block text-xl font-semibold">
                            Povežite se sa vozačima
                        </span>
                        <span className="block mt-5 text-neutral-500 ">
                            Pridružite se zajednici vozača, komentarišite, ocenjujte i delite iskustva
                            o vozilima javno ili privatno
                        </span>
                    </li>
                </ul>
                <ButtonPrimary
                    className="bg-sky-400 hover:bg-sky-400 mt-8"
                    onClick={() => navigate("/mobilna-aplikacija")}
                >
                    Saznajte više
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default OurFuturesHome;
