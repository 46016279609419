import { useState, Fragment, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const ImageGallery: FC<{
  isShowModal: boolean;
  handleClose: () => void;
  imageUrls: any[] | undefined;
}> = ({ isShowModal, handleClose, imageUrls }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  if (!imageUrls) {
    return null;
  }
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Transition appear show={isShowModal} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={handleClose}>
        <Dialog.Overlay
          key="backdrop"
          className="fixed inset-0  bg-black"
        ></Dialog.Overlay>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <ClearDataButton positionTop onClick={handleClose} />
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-5"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-5"
            >
              <Dialog.Panel className="relative w-full max-w-3xl mx-auto">
                <div className="relative flex items-center justify-center">
                  <button
                    className="absolute left-4 focus:outline-none"
                    onClick={handlePrevious}
                  >
                    <ArrowBackIosIcon className="w-8 h-8 text-white" />
                  </button>
                  <img
                    src={imageUrls[currentIndex]}
                    alt={`Image ${currentIndex + 1}`}
                    className="max-w-full max-h-screen"
                  />
                  <button
                    className="absolute right-4 focus:outline-none"
                    onClick={handleNext}
                  >
                    <ArrowForwardIosIcon className="w-8 h-8 text-white" />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImageGallery;
