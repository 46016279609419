import MoodBadSharpIcon from "@mui/icons-material/MoodBadSharp";
import MoodSharpIcon from "@mui/icons-material/MoodSharp";
import { Rating } from "data/types";
import { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import { green, red } from "@mui/material/colors";
import StartRating from "components/StartRating";

interface CommentListingDataType {
  name: string;
  avatar?: string;
  date: string;
  comment: string;
  starPoint: number;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
  rating?: Rating;
}

const CommentListing: FC<CommentListingProps> = ({ rating, className }) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };
  return (
    <div
      className={`nc-CommentListing flex space-x-4 ${className}`}
      data-nc-id="CommentListing"
    >
      <div className="pt-0.5">
        <Avatar
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          userName={rating?.driverDisplayName}
          imgUrl={""}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3 mb-2">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span className="text-sky-500">{rating?.title}</span>
            </div>
            <span className="text-sm text-neutral-500 mt-0.5">
              <span>{rating?.driverDisplayName}</span>
            </span>
          </div>
          <div className="flex flex-col space-y-1">
            <div className="self-end space-y-0.5">
              {formatDate(rating ? rating?.dateCreated : "")}
            </div>
            <div className="flex self-end text-yellow-500 ">
              <StartRating className="space-x-0.5" point={rating?.gradeAvg} />
            </div>
          </div>
        </div>
        {rating?.driverPosComment && (
          <div className="flex space-x-1 flex-row align-center ">
            <div className="basis-7">
              <MoodSharpIcon sx={{ color: green[500] }} />
            </div>
            <span className="block whitespace-pre-wrap text-neutral-6000  mb-3">
              {rating?.driverPosComment}
            </span>
          </div>
        )}
        {rating?.driverNegComment && (
          <div className="flex space-x-1 flex-row align-center">
            <div className="basis-7">
              <MoodBadSharpIcon sx={{ color: red[500] }} />
            </div>
            <span className="block whitespace-pre-wrap text-neutral-6000">
              {rating?.driverNegComment}
            </span>
          </div>
        )}
        {rating?.serviceComment && rating.serviceCommentDate && (
          <div className="p-1 m-3 pr-0 mr-0">
            <div
              style={{
                width: 0,
                height: 0,
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderBottom:
                  "10px solid rgb(209 213 219 / var(--tw-bg-opacity))",
                marginLeft: "25px",
              }}
            ></div>
            <div className="bg-gray-300 p-5 rounded-lg">
              <div className="flex flex-row mb-3">
                <div className="basis-1/2 font-bold">
                  {rating?.businessName}
                </div>
                <div className="basis-1/2 flex justify-end font-light">
                  {formatDate(rating ? rating?.serviceCommentDate : "")}
                </div>
              </div>
              <div className="whitespace-pre-wrap">
                <span>{rating?.serviceComment}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentListing;
