import I18nProvider from "I18n/I18nProvider";
import I18nProviderService from "I18n/I18nProviderService";
import { Helmet } from "react-helmet";

import MyRouter from "routers/index";

function App() {
  return (
    <I18nProvider ref={(ref) => I18nProviderService.setProviderRef(ref)}>
    <Helmet>
        <title>E-Knjižica</title>
        <meta property="og:title" content="E-Knjižica"></meta>
    </Helmet>
      <div className="bg-white text-base  text-neutral-900">
        <MyRouter />
      </div>
    </I18nProvider>
  );
}

export default App;
