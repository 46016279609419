import { FC, useEffect, useMemo, useState } from "react";
import BusinessProfileItem from "components/BusinessProfileItem";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Business, SearchBusinesses, ServiceTypesArray } from "data/types";
import { getServiceTypesGet } from "services/Endpoints/EserviceBook/BusinessesEndpoints";

import {
  getCityNameFromCoords,
  getGoogleApiAutocompletePlaces,
  getGoogleApiCoordinates,
} from "utils/GoogleMapsUtils";
import {
  BUSINESS_SEARCH_TYPE,
  BUSINESS_SORT_BY,
  MAX_NUMBER_OF_RECENT_SEARCHES,
} from "utils/BusinessConstants";
import Cookies from "js-cookie";
import FullScreenLoading from "routers/FullScreenLoading";
import { AddressBookSearch } from "./AddressBookSearch";
import { AddressBookMap } from "./AddressBookMap";
import { useDispatch, useSelector } from "react-redux";
import { setServiceTypesArrayRedux } from "../../actions/serviceActions";
import { RootState } from "reducers";
import I18nHelper from "I18n/I18nHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { getSearchBusinessesAction } from "actions/BusinessesActions";
import NextPrevPagination from "shared/NextPrev/NextPrevPagination";

function debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
  let timerId: NodeJS.Timeout;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
      timerId = null!;
    }, delay);
  };
}

type BusinessSearchType = keyof typeof BUSINESS_SEARCH_TYPE;

const ITEMS_PER_PAGE = 10;

const AddressBook: FC = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [data, setData] = useState<SearchBusinesses | null>(null);
  const [serviceTypes, setServiceTypes] = useState<ServiceTypesArray[]>(
    useSelector((state: RootState) => state.service.serviceTypes)
  );
  const [selectedMarker, setSelectedMarker] = useState<Business | null>();
  const [options, setOptions] = useState<{ placeId: string; name: string }[]>();
  const [inputValue, setInputValue] = useState<string>("");
  const [predictions, setPredictions] = useState<any[]>([]);
  const [nameSearchValue, setNameSearchValue] = useState<string>("");
  const [coords, setCoords] = useState<{
    latitude: string;
    longitude: string;
  }>();
  const [navigatorCoords, setNavigatorCoords] = useState<{
    latitude: string;
    longitude: string;
  }>();
  const [businessSearchType, setBusinessSearchType] =
    useState<BusinessSearchType>("BY_LOCATION");
  const [serviceTypeValue, setServiceTypeValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState("");
  const [loadingNavigation, setLoadingNavigation] = useState<boolean>(true); // waiting for user to accept or decline if website can use user's location
  const [open, setOpen] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [sortType, setSortType] = useState<number>(0);
  const [storedState, setStoredState] = useState<ServiceTypesArray | null>(
    null
  );
  const [loadedAllData, setLoadedAllData] = useState<boolean>(false);
  const [driverCoords, setDriverCoords] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const businessData = useSelector((state: RootState) => state.business);
  const serviceTypesRedux = useSelector(
    (state: RootState) => state.service.serviceTypes
  );
  const navigate = useNavigate();

  const handleSelectChange = (event: any) => {
    setSortType(Number(event.target.value));
    setCurrentPage(1);
    sessionStorage.setItem("sortType", event.target.value.toString());
    sessionStorage.setItem("currentPage", "1");

    handleGetBusiness(false, 1);
  };

  const handleNameChange = (event: any) => {
    if (event === "") {
      setNameSearchValue("");
      setBusinessSearchType("BY_LOCATION");
    } else {
      setServiceTypeValue(-1);
      setNameSearchValue(event.target.value);
      setBusinessSearchType("BY_NAME");
    }
  };

  const { state } = useLocation();

  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  // Example usage:
  const isMobile = isMobileView();

  useEffect(() => {
    if (businessData.searchResults.length === 0) {
      setCurrentPage(1);
    }
  }, [businessData]);

  //@ts-ignore
  const sortedData: Business[] = useMemo(() => {
    if (!data || !data.searchResults) return [];
    let sortedArray = [...data.searchResults];
    switch (sortType) {
      case BUSINESS_SORT_BY.DISTANCE.value:
        sortedArray.sort((a, b) => a.distance - b.distance);
        break;
      case BUSINESS_SORT_BY.AVG_GRADE.value:
        sortedArray.sort((a, b) => b.ratingAvg - a.ratingAvg);
        break;
      case BUSINESS_SORT_BY.MOST_REVIEWED.value:
        sortedArray.sort((a, b) => b.ratingCount - a.ratingCount);
        break;
      default:
        break;
    }
    return sortedArray;
  }, [sortType, data]);

  const totalPages = data
    ? Math.ceil(data.allResultsCount / ITEMS_PER_PAGE)
    : 0;
  const businessSortByArray = Object.keys(BUSINESS_SORT_BY).map((key) => ({
    name: key,
    ...BUSINESS_SORT_BY[key as keyof typeof BUSINESS_SORT_BY],
  }));

  const handlePageClick = (page: number) => {
    setCurrentPage(page);

    if (
      businessData.searchResults.length - 1 ===
      businessData.allResultsCount
    ) {
      setLoadedAllData(true);
    } else {
      handleGetBusiness(true, page);
    }
    sessionStorage.setItem("currentPage", page.toString());
  };

  useEffect(() => {
    const savedSortType = sessionStorage.getItem("sortType");
    if (savedSortType) {
      setSortType(Number(savedSortType));
    }
    if (state) {
      setStoredState(state);
      navigate(window.location.pathname, { replace: true, state: null });
    }
    if (!serviceTypesRedux.length) {
      fetchServiceTypes();
    }
  }, []);

  useEffect(() => {
    setData(businessData);
    if (businessData.searchResults.length < 10) {
      setCurrentPage(1);
      sessionStorage.setItem("currentPage", "1");
    }
  }, [businessData]);

  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getParams = (cookieObject: any) => {
    let latitude;
    let longitude;
    let businessSearchType;
    let searchServiceTypeId;
    if (cookieObject && cookieObject.latitude && cookieObject.longitude) {
      latitude = cookieObject.latitude;
      longitude = cookieObject.longitude;
      businessSearchType = cookieObject.businessSearchType;
      searchServiceTypeId = cookieObject.searchServiceTypeId;
    } else if (
      navigatorCoords &&
      navigatorCoords.latitude &&
      navigatorCoords.longitude
    ) {
      latitude = navigatorCoords.latitude;
      longitude = navigatorCoords.longitude;
      businessSearchType = BUSINESS_SEARCH_TYPE.BY_LOCATION;
    } else {
      businessSearchType = BUSINESS_SEARCH_TYPE.BY_LOCATION;
      latitude = 44.8099561;
      longitude = 20.3800881;
      searchServiceTypeId = null;
    }

    return {
      latitude: latitude,
      longitude: longitude,
      skip: 0,
      take: ITEMS_PER_PAGE,
      businessSearchType: businessSearchType,
      searchServiceTypeId: searchServiceTypeId,
      sortType: sortType,
      searchName: nameSearchValue,
    };
  };

  useEffect(() => {
    if (navigator.geolocation) {
      setLoadingNavigation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setNavigatorCoords({
            latitude: latitude.toString(),
            longitude: longitude.toString(),
          });
          setDriverCoords({
            latitude: latitude,
            longitude: longitude,
          });
          setError("");
          setLoadingNavigation(false);
        },
        (err) => {
          setError(`Error: ${err.message}`);
          setNavigatorCoords({
            latitude: "44.8099561",
            longitude: "20.3800881",
          });

          setLoadingNavigation(false);
        },
        {
          enableHighAccuracy: true, // Enable high accuracy mode
          timeout: 10000, // Timeout after 10 seconds
          maximumAge: Infinity, // Do not use a cached position
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setNavigatorCoords({
        latitude: "44.8099561",
        longitude: "20.3800881",
      });
      setLoadingNavigation(false);
    }
  }, []);

  const fetchServiceTypes = async () => {
    try {
      const response = await getServiceTypesGet();
      if (!response.ok) {
        throw new Error("response not ok");
      }

      const data = await response.json();
      //@ts-ignore

      setServiceTypes(data.items);
      dispatch(setServiceTypesArrayRedux(data.items));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async (
    coords: any,
    shouldAppend?: boolean,
    newSearch?: boolean
  ) => {
    setLoading(true);
    setNavigatorCoords(undefined);
    setSearchLoading(true);
    if (!shouldAppend) {
      setLoadedAllData(false);
    }

    if (businessSearchType === "BY_NAME" && nameSearchValue === "") {
      setBusinessSearchType("BY_LOCATION");
    }
    const requestParams = {
      latitude: coords.latitude,
      longitude: coords.longitude,
      skip: newSearch ? 0 : currentPage * 10 - 1,
      take: ITEMS_PER_PAGE,
      businessSearchType: businessSearchType
        ? BUSINESS_SEARCH_TYPE[businessSearchType]
        : BUSINESS_SEARCH_TYPE.BY_LOCATION,
      searchServiceTypeId: serviceTypeValue,
      sortType: sortType,
      searchName: nameSearchValue,
    };
    Cookies.set("params", JSON.stringify(requestParams));
    try {
      const response = await getSearchBusinessesAction(
        requestParams,
        shouldAppend ? true : false,
        dispatch
      );
      if (!response) {
        throw new Error("response not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setSearchLoading(false);
      if (newSearch) {
        setCurrentPage(1);
      }
    }
  };

  const fetchDataDefault = async () => {
    if (searchLoading) {
      return;
    }
    setLoading(true);

    const cookieParams = Cookies.get("params");
    const cookieRequestParams = cookieParams ? JSON.parse(cookieParams) : null;
    const requestParams = getParams(cookieRequestParams);
    if (requestParams) {
      const cityName = await getCityNameFromCoords(
        requestParams.latitude,
        requestParams.longitude
      );
      setInputValue(cityName ?? "");
    }
    if (
      requestParams.businessSearchType === BUSINESS_SEARCH_TYPE.BY_NAME &&
      requestParams.searchName === ""
    ) {
      requestParams.businessSearchType = BUSINESS_SEARCH_TYPE.BY_LOCATION;
    }

    setCoords({
      latitude: requestParams.latitude.toString(),
      longitude: requestParams.longitude.toString(),
    });

    if (requestParams) {
      const serviceType = serviceTypes.find(
        (x) => x.id === requestParams.searchServiceTypeId
      );

      handleServiceType(serviceType);
      const savedSortType = sessionStorage.getItem("sortType");
      if (businessData.searchResults.length === 0) {
        setCurrentPage(1);
      } else {
        const savedPage = sessionStorage.getItem("currentPage");
        if (savedPage) {
          setCurrentPage(Number(savedPage));
        }
      }
      if (storedState) {
        handleServiceType(storedState);
        setStoredState(null);
      }
      if (savedSortType) {
        setSortType(Number(savedSortType));
      }
    }
    try {
      if (
        businessData.searchResults.length === 0 ||
        data?.searchResults.length === 0
      ) {
        const response = await getSearchBusinessesAction(
          requestParams,
          false,
          dispatch
        );
        if (!response) {
          throw new Error("response not ok");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!loadingNavigation) {
      fetchDataDefault();
    }
  }, [navigatorCoords]);

  const fetchGoogleMapsPlaceId = async (value: string) => {
    try {
      if (!value || value.length < 3) {
        return;
      }
      const predictions = await getGoogleApiAutocompletePlaces(value);

      if (!predictions) {
        throw new Error("response not ok");
      }
      const cities = predictions.map(
        (prediction: { placeId: string; name: string }) => prediction
      );
      setOptions(cities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedFetchPlacesData = debounce(async (value: string) => {
    try {
      if (!value || value.length < 3) {
        setPredictions([]);
        return;
      }
      const predictions = await fetchGoogleMapsPlaceId(value);
      //@ts-ignore
      setPredictions(predictions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, 300);

  // Restore scroll position on component mount/update
  useEffect(() => {
    if (!loading) {
      const savedScrollPosition = parseInt(
        sessionStorage.getItem(location.pathname) || "0"
      );
      window.scrollTo(0, savedScrollPosition);
    }
  }, [loading, location.pathname]);

  const handleInputChange = (event: any) => {
    const value = event;
    setInputValue(value);
    setOptions([]);
    debouncedFetchPlacesData(value);
  };
  const addSearchToCookie = (newSearch: { placeId: string; name: string }) => {
    // Retrieve existing cookie data, if any
    let existingSearches: string | null = null;
    let searchArray: { placeId: string; name: string }[] = [];

    try {
      //@ts-ignore
      existingSearches = Cookies.get("recentSearches");
      if (existingSearches) {
        const parsedData = JSON.parse(existingSearches);

        if (Array.isArray(parsedData)) {
          searchArray = parsedData;
        }
      }
    } catch (error) {
      console.error("Error parsing existing searches:", error);
    }

    const exists = searchArray.some(
      (search) => search.placeId === newSearch.placeId
    );
    if (!exists) {
      if (searchArray.length === MAX_NUMBER_OF_RECENT_SEARCHES) {
        searchArray.shift();
      }
      searchArray.push(newSearch);
      Cookies.set("recentSearches", JSON.stringify(searchArray));
    }
  };

  const handlePredictionClick = async (prediction: any) => {
    addSearchToCookie({ placeId: prediction.placeId, name: prediction.name });
    setInputValue(prediction.name);
    setOptions([]);
    const coords = await getGoogleApiCoordinates(prediction.placeId);
    setCoords(coords);
    Cookies.set("myCookie", JSON.stringify(coords)); // Expires in 1 hour
  };

  const handleMarkerClick = (marker: any) => {
    setSelectedMarker(marker);
  };

  const handleInfoBoxClose = () => {
    setSelectedMarker(null);
  };

  const getServiceTypeNamesFromEnum = (numbers: Number[]) => {
    const matchedServiceTypes = serviceTypes.filter((type: any) =>
      numbers.includes(type.id)
    );

    // Extract names from the matched service types
    const enumNames: string[] = matchedServiceTypes.map(
      (type: any) => type.name
    );

    return enumNames;
  };

  const handleGetBusiness = (shouldAppend?: boolean, pageNumber?: number) => {
    if (!inputValue) {
      alert("Unesite lokaciju");
      return;
    }
    if (!shouldAppend) {
      fetchData(coords, shouldAppend, true);
      setCurrentPage(1);
      sessionStorage.setItem("currentPage", "1");
      setLoadedAllData(false);
    }

    if (loadedAllData) {
      if (pageNumber) {
        setCurrentPage(pageNumber);
      }
    } else if (
      //@ts-ignore
      pageNumber >
      businessData.searchResults.length / ITEMS_PER_PAGE
    ) {
      fetchData(coords, shouldAppend);
    }
    if (data?.searchResults.length === 0) {
      setCurrentPage(1);
      sessionStorage.setItem("currentPage", "1");
      setLoadedAllData(false);
      return;
    }
  };

  const handleServiceType = (value: any) => {
    if (value === null || value === undefined) {
      setAutocompleteValue("");
      setServiceTypeValue(-1);
      setBusinessSearchType("BY_LOCATION");
      return;
    } else {
      setAutocompleteValue(value.name);
      setServiceTypeValue(value.id);
    }

    if (value.name === "Tehnički pregled") {
      setBusinessSearchType("BY_MOT");
    } else {
      setBusinessSearchType("BY_SERVICE_TYPE");
    }
  };

  const resetData = () => {
    setOptions([]);
    setInputValue("");
  };

  if (loading || loadingNavigation) {
    return <FullScreenLoading />;
  }

  return (
    <div className="grid">
      <h2
        className={`mt-8 mb-4 ${
          isMobile ? "!ml-[3.75rem]" : "!ml-12"
        } text-4xl font-semibold justify-self-center`}
      >
        Adresar auto servisa
      </h2>
      <h2
        className={`text-lg ${
          isMobile ? "mr-2" : "ml-8"
        } !mb-4 justify-self-center`}
      >
        Pronađi servis ili tehnički pregled
      </h2>
      {isMobile && (
        <div className="w-[90%] mx-4 border-b border-neutral-200 my-6"></div>
      )}
      <AddressBookSearch
        handlePredictionClick={handlePredictionClick}
        handleInputChange={handleInputChange}
        inputValue={inputValue}
        nameSearchValue={nameSearchValue}
        setNameSearchValue={setNameSearchValue}
        resetData={resetData}
        handleGetBusiness={handleGetBusiness}
        handleNameChange={handleNameChange}
        options={options ? options : []}
        handleServiceType={handleServiceType}
        serviceTypes={serviceTypes}
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        autocompleteValue={autocompleteValue}
        setAutocompleteValue={setAutocompleteValue}
      />

      {isMobile && (
        <div className="w-[90%] mx-4 border-b border-neutral-200 my-4"></div>
      )}
      <div
        className={`flex flex-1 relative z-10 max-w-64 flex-shrink-0 items-center space-x-3 m-4 ml-4 mb-4 focus:outline-none text-left ${"nc-hero-field-focused--2"}`}
      >
        <select
          value={sortType}
          style={{ border: "none" }}
          onChange={handleSelectChange}
        >
          {businessSortByArray.map((sortOption) => (
            <option key={sortOption.name} value={sortOption.value}>
              {I18nHelper.getText(sortOption.i18n)}
            </option>
          ))}
        </select>
      </div>

      {sortedData && sortedData.length > 0 ? (
        <div className="relative flex min-h-screen mx-6">
          <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-4 ">
            <div className="grid grid-cols-1 gap-4">
              {sortedData &&
                sortedData
                  .slice(
                    (currentPage - 1) * ITEMS_PER_PAGE,
                    currentPage * ITEMS_PER_PAGE
                  )
                  .map((item: any) => (
                    <div
                      key={item.id}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <BusinessProfileItem
                        key={item.id}
                        data={item}
                        serviceTypes={serviceTypes
                          .filter((obj2) =>
                            item.bServices.some(
                              (obj1: any) =>
                                obj1.service_ID === obj2.id && obj2.level === 1
                            )
                          )
                          .map((z) => z.name)}
                      />
                    </div>
                  ))}
              <div className="flex justify-self-center">
                <NextPrevPagination
                  currentPage={currentPage}
                  totalPage={totalPages}
                  onClickNext={() => handlePageClick(currentPage + 1)}
                  onClickPrev={() => handlePageClick(currentPage - 1)}
                  className="w-full space-x-6"
                  btnClassName="w-12 h-12"
                />
              </div>
            </div>
          </div>
          {!showFullMapFixed && (
            <div
              className={`flex xl:hidden items-center justify-center fixed bottom-16 ${
                isMobile ? "left-[30%]" : "left-1/2"
              }   transform-translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer`}
              onClick={() => setShowFullMapFixed(true)}
            >
              <i className="text-lg las la-map"></i>
              <span>Prikaži mapu</span>
            </div>
          )}
          {/* MAPPPPP */}
          <div
            className={`xl:flex-grow xl:static xl:block  ${
              showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
            }`}
          >
            {showFullMapFixed && (
              <ButtonClose
                onClick={() => setShowFullMapFixed(false)}
                className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
              />
            )}
            <div
              className={`fixed xl:sticky top-0 xl:top-[110px] left-0 w-full h-full ${
                isMobile ? "xl:h-[100vh]" : "xl:h-[70vh]"
              }  rounded-md overflow-hidden`}
            >
              <AddressBookMap
                data={sortedData.slice(
                  (currentPage - 1) * ITEMS_PER_PAGE,
                  currentPage * ITEMS_PER_PAGE
                )}
                currentHoverID={currentHoverID}
                handleMarkerClick={handleMarkerClick}
                selectedMarker={selectedMarker}
                handleInfoBoxClose={handleInfoBoxClose}
                driversCoords={driverCoords}
                serviceTypeNames={
                  selectedMarker
                    ? getServiceTypeNamesFromEnum(
                        selectedMarker?.bServices.map((x) => x.service_ID)
                      )
                    : []
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="justify-self-center">
          {inputValue} Nema servis za odabrani tip usluge
        </div>
      )}
    </div>
  );
};

export default AddressBook;
