import { FC, useState } from "react";
import defaultProfile from "../../../src/shared/Logo/default-profile.png";
import ImageGallery from "components/ImageGallery/ImageGallery";

interface BusinessProfileHeaderProps {
  images: any[] | undefined;
}

export const BusinessProfileHeader: FC<BusinessProfileHeaderProps> = ({ images }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  if (!images) {
    return null;
  }

  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }
  const isMobile = isMobileView();
  const imagesWithoutLogo = images;
  return imagesWithoutLogo.length ? (
    <header className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 py-6">
      {imagesWithoutLogo.length < 2 ? (
        <div className="flex justify-center items-center w-full max-h-[650px] overflow-hidden rounded-xl">
          <img
            className="object-contain max-w-full max-h-full"
            src={
              !!imagesWithoutLogo?.length
                ? imagesWithoutLogo[0]
                : defaultProfile
            }
            alt=""
          />
        </div>
      ) : (
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            onClick={() => isMobile && setModalOpen(true)}
            className="col-span-2 row-span-3 sm:row-span-2 relative min-h-[150px] rounded-md sm:rounded-xl overflow-hidden"
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={imagesWithoutLogo[0]}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
          </div>
          {imagesWithoutLogo.slice(1).map((item, index) => (
            <div
              onClick={() => isMobile && setModalOpen(true)}
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 3 ? "hidden sm:block" : ""
              }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item || ""}
                  alt=""
                  sizes="400px"
                />
              </div>
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0  transition-opacity" />
            </div>
          ))}
          {!isMobile && (
            <button
              onClick={() => setModalOpen(true)}
              className="absolute  md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            >
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Prikaži sve slike
              </span>
            </button>
          )}
          <ImageGallery
            imageUrls={images}
            isShowModal={modalOpen}
            handleClose={() => setModalOpen(false)}
          />
        </div>
      )}
    </header>
  ) : (
    <></>
  );
};
