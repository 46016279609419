import AppConfig, { ENV_TYPES } from 'config/AppConfig'
import ReactGA from 'react-ga4'

let GAInitialized = false

function init() {
    if (!AppConfig.googleAnalyticsEnabled || !AppConfig.googleAnalyticsSiteID) {
        return
    }

    ReactGA.initialize(AppConfig.googleAnalyticsSiteID, { debug: AppConfig.environment === ENV_TYPES.Development })
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
    GAInitialized = true
}

function setUser(user) {
}

function sendEvent(event) {
    if (!GAInitialized) {
        return
    }

    ReactGA.event(event)
}

function stateChange(newState) {
}

export default {
    init,
    setUser,
    sendEvent,
    stateChange,
}