import { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import Cookies from "js-cookie";

export interface LocationInputProps {
  onInputDone: (value: any) => any;
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  inputValue?: string;
  handleRecentPredictionClick: (value: any) => any;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  onInputDone,
  placeHolder = "Odaberite mesto",
  inputValue,
  handleRecentPredictionClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPopover, setShowPopover] = useState(autoFocus);

  useEffect(() => {
    setShowPopover(autoFocus);
    if (autoFocus && !!inputRef.current) {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 200);
    }
  }, [autoFocus]);

  useOutsideAlerter(containerRef, () => {
    setShowPopover(false);
  });

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleSelectLocation = (item: any) => {
    onInputDone(item);
    setShowPopover(false);
  };

  const handleSelectRecentLocation = (item: any) => {
    handleRecentPredictionClick(item);
    setShowPopover(false);
  };

  const myCookie = Cookies.get("recentSearches"); // 'cookieValue'
  const cookieObject = myCookie ? JSON.parse(myCookie) : null;

  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  // Example usage:
  const isMobile = isMobileView();

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base text-neutral-800">
          Nedavne pretrage
        </h3>
        <div className="mt-2 w-60">
          {cookieObject &&
            cookieObject.map((item: { placeId: string; name: string }) => (
              <span
                onClick={() => handleSelectRecentLocation(item)}
                key={item.placeId}
                className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 cursor-pointer"
              >
                <span className="block text-neutral-400">
                  <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                </span>
                <span className=" block text-neutral-700">
                  {item.name}
                </span>
              </span>
            ))}
        </div>
      </>
    );
  };

  return (
    <div className={`relative flex `} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative z-10 ${isMobile ? "w-[21rem]" : ""} [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${"nc-hero-field-focused--2"}`}
      >
        <input
          className={`block bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 text-sm font-semibold truncate`}
          placeholder={placeHolder}
          value={inputValue}
          autoFocus={showPopover}
          ref={inputRef}
          onChange={(e) => handleSelectLocation(e.currentTarget.value)}
        />
      </div>
      {showPopover && !inputValue && (
        <div className="absolute left-0 z-40 w-full min-w-[300px]  bg-white top-full mt-3 py-3 sm:py-5 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {!inputValue && renderRecentSearches()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
