import { IconButton } from "@mui/material";
import { FC } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiveStartIconForRate from "components/FiveStartIconForRate";

interface ProsecnaOcenaProps {
  ratingAvg: number;
  ratingCount: number;
  ocenaSectionRef: any;
  open: boolean;
  toggleBody: () => void;
  ocene: string[];
  gradeAvg: any[];
}

const BusinessAverageRating: FC<ProsecnaOcenaProps> = ({
  ratingAvg,
  ratingCount,
  ocenaSectionRef,
  open,
  toggleBody,
  ocene,
  gradeAvg,
}) => {
  const isAverageEmpty = Object.keys(gradeAvg).length === 0;

  return (
    <div className="listingSection__wrap_column">
      <h2
        ref={ocenaSectionRef}
        className="text-2xl font-semibold"
        onClick={() => toggleBody()}
      >
        <span className=" block font-bold p-2 pl-3 rounded-lg   mt-2 flex items-center justify-between ">
          Prosečna ocena
          <IconButton>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </span>
      </h2>
      {open && <div className="w-20 ml-3 border-b border-neutral-300 "></div>}
      {/* Content */}
      {open && (
        <div className="grid mt-0 grid-cols-2 ">
          <div className="grid-cols-2 self-center">
            <div>
              <div className="flex ml-11 mb-4">
                <span className="border border-sky-400 bg-sky-400 text-4xl max-[450px]:!text-sm text-white rounded-full p-3">
                  {ratingAvg ? ratingAvg.toFixed(1) : "0"}
                </span>
              </div>
            </div>
            <div className="flex flex-row text-normal max-[450px]:!text-sm ml-2 sm:md:lg:ml-7">
              <FiveStartIconForRate
                iconClass="w-6 h-6"
                className="space-x-0.5"
                ratingAvg={ratingAvg}
              />
            </div>
            <div className="sm:md:lg:ml-7 ml-3 my-2">{`Broj ocena: ${ratingCount}`}</div>
          </div>
          {!isAverageEmpty && (
            <div className="flex justify-between space-x-1 mb-2 ">
              <div className="grid text-base justify-items-start  mr-2">
                {ocene.map((item) => (
                  <div key={item}>
                    <div className="">{item}</div>
                  </div>
                ))}
              </div>
              <div className="grid justify-items-end  md:mr-5 font-bold text-base ">
                {gradeAvg.map((item, index) => (
                  <div className=" " key={index}>
                    <div className="">{item.toFixed(1)}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BusinessAverageRating;
